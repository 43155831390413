import React, { useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { emailRegex, phoneRegex } from '../../constants/common';
// import { doCreateConsultion } from '../../../redux/actions/consultion';
// import {
//   getInfoFormRequest,
//   postRegSchoolRequest,
//   postUpdateFormRequest,
// } from '../../../redux/actions/formReg';
// import { RootState } from '../../../redux/reducers/rootReducers';
// import { useAppDispatch } from '../../../redux/store';
import { Button, InputPrimary, Modal } from '../../components/common';
import { useFormik } from 'formik';
import './ModalRegisterSupport.scss';
import { useAppDispatch, useAppSelector } from '../../redux';
import { RootState } from '../../redux';
import { apiAdmission } from '../../services/aixos/apiAdmission';

export const ModalRegisterSupport: React.FC<IModalRegisterSupport> = ({
  schoolId,
  ologyId,
  isOpen,
  setIsOpen,
  onNotify,
  info,
  setIsShow,
  onClickHeader,
}) => {
  const dispatch = useAppDispatch();
  const { name , email, phone} = useSelector((state: RootState) => state.storeUserInfo);
  // useEffect(() => {
  //   dispatch(getInfoFormRequest());
  // }, []);

  // const { namePersist, phonePersist, emailPersist } = useAppSelector((state) => state.historySearchSlice)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: info ? info?.fullname || '' : name || '',
      email: info ? info?.email || '' : email || '',
      phone: info ? info?.phone || '' :phone || '',
      content: '',
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.name) {
        errors.name = 'Vui lòng nhập họ và tên.';
      }

      if (!values.email) {
        errors.email = 'Vui lòng nhập email.';
      } else if (values.email && !emailRegex.test(values.email)) {
        errors.email = 'Định dạng email không hợp lệ.';
      }

      if (!values.phone) {
        errors.phone = 'Vui lòng nhập số điện thoại.';
      } else if (values.phone && !phoneRegex.test(values.phone)) {
        errors.phone = 'Số điện thoại không hợp lệ';
      } else if (values.phone && values.phone.length < 9) {
        errors.phone = 'Vui lòng nhập số điện thoại từ 9 số trở lên.';
      } else if (values.phone && values.phone.length > 11) {
        errors.phone = 'Số điện thoại phải nhỏ hơn 11 chữ số.';
      }

      if (!values.content) {
        errors.content = 'Vui lòng nhập câu hỏi cần tư vấn.';
      }

      return errors;
    },

    onSubmit: (values) => {
      const data = {
        fullname: values.name,
        phone: values.phone,
        email: values.email,
        content: values.content,
        highschoolid: schoolId,
      };
      apiAdmission.CreateConsultion(data);
      setIsOpen(false)
      // dispatch(postUpdateFormRequest(data));
      // dispatch(postRegSchoolRequest({ schoolid: schoolId, ologyid: ologyId }));
      // dispatch(
      //   doCreateConsultion({ schoolid: schoolId, content: values.content, username: values.name }),
      // );
      // onNotify('Success');
    },
  });

  const handleRemoveInput = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  useEffect(() => {
    if (!isOpen) {
      formik.values.content = ''
    }
  }, [isOpen])

  return (
    <Modal isShow={isOpen} setIsShow = {setIsShow}>
      <form className="register-support" onSubmit={formik.handleSubmit}>
        <div className="register-support__header">
          <div className="register-support__icon" onClick={onClickHeader}>
            <FiX color="white" />
          </div>
        </div>
        <p className="register-support__title">Cần tư vấn</p>
        <p className="register-support__content">
          Vui lòng kiểm tra và cập nhật lại thông tin cho chính xác, chuyên viên sẽ liên lạc với bạn
          trong thời gian sớm nhất.
        </p>
        {/* <p className="register-support__content">
          Thông tin này sẽ không ảnh hưởng đến tài khoản YOOT của bạn.
        </p> */}

        <InputPrimary
          classNameInput="register-support__input"
          isShowEge={true}
          subIcon={
            <div className="register-support__icon">
              <FiX color="white" />
            </div>
          }
          classNameIcon="register-support__position"
          placeholder="Họ và tên"
          handleClickIcon={() => handleRemoveInput('name', '')}
          onChange={formik.handleChange}
          name="name"
          onBlur={formik.handleBlur}
          value={formik.values.name}
          error={formik.errors?.name }
        />

        <InputPrimary
          classNameInput="register-support__input"
          isShowEge={true}
          subIcon={
            <div className="register-support__icon">
              <FiX color="white" />
            </div>
          }
          classNameIcon="register-support__position"
          placeholder="Email"
          handleClickIcon={() => handleRemoveInput('email', '')}
          onChange={formik.handleChange}
          name="email"
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.errors?.email}
        />

        <InputPrimary
          classNameInput="register-support__input"
          isShowEge={true}
          subIcon={
            <div className="register-support__icon">
              <FiX color="white" />
            </div>
          }
          classNameIcon="register-support__position"
          placeholder="Số điện thoại"
          handleClickIcon={() => handleRemoveInput('phone', '')}
          onChange={formik.handleChange}
          name="phone"
          onBlur={formik.handleBlur}
          value={formik.values.phone}
          error={formik.errors?.phone}
        />

        <textarea
          className="register-support__textarea"
          placeholder="Nhập câu hỏi của bạn..."
          rows={5}
          onChange={formik.handleChange}
          name="content"
          onBlur={formik.handleBlur}
          value={formik.values.content}
        />
        {formik.errors.content ? (
          <span className="input-primary__err">{formik.errors.content}</span>
        ) : null}

        <div className="register-support__action">
          <Button className="register-support__btn" type="submit">
            Gửi ngay
          </Button>
        </div>
      </form>
    </Modal>
  );
};
