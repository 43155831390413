import React, { MouseEvent as ReactMouseEvent, useEffect, useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { eventListener } from '../../../constants/common';
import { useDebounce } from '../../../hooks';
import { useDispatch } from 'react-redux';
import './DropdownTableSelect.scss';
import { RowData } from './RowData/RowData';
import { doCheckPriorityObject, doUnCheckPriorityObject } from '../../../redux';

export const DropdownTableSelect: React.FC<any> = ({
  zIndexHeader,
  zIndexBody,
  options,
  totalScore,
  backgroundColorIcon,
  className,
  isOver,
  indexOption,
  isConvertIdToIndex,
  hasErr,
  id,
  reset,
  isHaveArrowCursor,
  type,
  idActive,
  setIdActive,
  isChosenNoPriority
}) => {
  const [isDownMenu, setIsDownMenu] = useState(false);
  const [numberSelect, setNumberSelect] = useState(-1);
  const [currentCursor, setCurrentCursor] = useState(-1);
  const ref = useRef<HTMLDivElement>(null);
  const refBody: any = useRef<HTMLDivElement>(null);
  const [filter, setFilter] = useState<string>('');
  const dbValue = useDebounce(filter, 400);
  const refWrapper = useRef<HTMLDivElement>(null);
  const [listSeletedSubjects, setListSelectedSubjects] = useState<any>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const refOptions = options;
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (reset) {
      setNumberSelect(-1);
      setListSelectedSubjects([]);
    }
  }, [reset]);

  useEffect(() => {
    if (!reset && isConvertIdToIndex && indexOption !== undefined && options.length > 0 && indexOption !== -1) {
      const index = options.findIndex((o: any, i: any) => i === indexOption);
      setNumberSelect(index);
    } else if (type !== 4) {
      setNumberSelect(-1);
      setListSelectedSubjects([]);
    }
  }, [indexOption, options]);

  useEffect(() => {
    if (isDownMenu) {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.currentTarget as Node)) {
          setIdActive('default');
          setIsDownMenu(false);
        }
      };
      document.addEventListener(eventListener.CLICK, handleClickOutside);
      return () => {
        document.removeEventListener(eventListener.CLICK, handleClickOutside);
      };
    }
  }, [ref, isDownMenu]);

  useEffect(() => {
    if (!showError) {
      setErrorText('');
    }
  }, [showError]);

  useEffect(() => {
    if (
      numberSelect !== -1
      // && listSeletedSubjects.length === 0
    ) {
      var newList = [];
      newList.push(options[numberSelect]);
      setListSelectedSubjects(newList);

      // onChange(newList);
    }
  }, [numberSelect]);

  useEffect(() => {
    if (listSeletedSubjects?.length > 0) {
      setFilter(listSeletedSubjects[0]?.label);
    } else setFilter('');
  }, [listSeletedSubjects]);

  useEffect(() => {
    //Nếu idActive không giống id của Dropdown hiện tại (nghĩa là tắt dropdown khi ấn qua dropdown khác) thì tắt dropdown
    //Trạng thái idActive = 'default' là mặc định (khi chưa mở dropdown nào)
    if (isDownMenu && (idActive !== 'default' && idActive !== id)) setIsDownMenu(false);
  }, [idActive])



  const handleOnChecked = (id: number) => {
    dispatch(doCheckPriorityObject({id: id}))
  }

  const handleOnUnChecked = (id: number) => {
    dispatch(doUnCheckPriorityObject({id: id}))
  }

  const renderDownMenu = () => {
    if (isDownMenu) return (
      <div
        className={isOver ? 'dropdown-table__body dropdown-table__body--over' : 'dropdown-table__body'}
        style={{ zIndex: `${zIndexBody}` }}
        // ref={ref}
      >
        <table>
          <thead>
            <tr onClick={(e) => e.stopPropagation()}>
              <th>Chế độ ưu tiên</th>
              <th>Mã ưu tiên</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {options.length && options.map((item: any, i: any) => {
              return (
                  <RowData
                    key={item.id}
                    id={item.id}
                    index={i}
                    title={item.title}
                    code={item.code}
                    checked={item.checked}
                    onChecked={() => handleOnChecked(item.id)}
                    onUnChecked={() => handleOnUnChecked(item.id)}
                    isChosenNoPriority = {isChosenNoPriority}
                  />
              );
            })}
          </tbody>
        </table>
      </div>

    )
  }

  return (
    <div
      ref={ref}
      className={`dropdown-table ${className ? className : ''}`}
      style={{ zIndex: `${zIndexHeader}` }}
      onBlur={() => {
        setIdActive('default');
        setIsDownMenu(false);
      }}
      id={id}
    >
      <div
        className="dropdown-table__header"
        style={{
          zIndex: `${zIndexHeader}`,
          border: hasErr?.length > 0 ? `1px solid #BC3D3D` : '',
        }}
        onClick={(e: ReactMouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          if (e.currentTarget.tagName !== 'path') {
            if (isDownMenu) setIdActive('default'); else setIdActive(id);
            setIsDownMenu(!isDownMenu);
          }
        }}
      >
        <div className="dropdown-table__show">
          {isChosenNoPriority?<span>Không thuộc đối tượng ưu tiên</span>:<span className={totalScore ? 'dropdown-table__show__total-socre' : ''}>
            {totalScore ? ('Tổng: ' + totalScore + ' điểm') : 'Chọn đối tượng ưu tiên'}
          </span>}
        </div>
        <div className="dropdown-table__icon" style={{ backgroundColor: backgroundColorIcon }}>
          <FiChevronDown size={22} color="white" className="dropdown-table__icon-down" />
        </div>
      </div>

      {renderDownMenu()}
    </div>
  );
};

DropdownTableSelect.defaultProps = {
  isHasPowerGrade: false,
};
