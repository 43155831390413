import { deleteAllCookies, deteletAllCookie } from '.';
import { ETokenName } from '../constants';
import { readCookie, setCookie } from './cookie';
import { getJsonProfileUser, setTokenProfileUser } from '.';

export const logout = () => {
  const domain = process.env.COOKIE_DOMAIN || window.location.hostname;
  localStorage.clear();
  deteletAllCookie(domain);
  deleteAllCookies();
  window.location.replace(window.location.origin);
};

export const login = (content: any, pathname?: string, blockRedirect?: boolean) => {
  const domain = process.env.COOKIE_DOMAIN || window.location.hostname;
  deleteAllCookies();
  const {
    myToken
  } = content;

  window.localStorage.setItem(ETokenName.ACCESS_KEY, myToken);
  setCookie(365, myToken, ETokenName.ACCESS_KEY, domain);
  if (blockRedirect) {
    return;
  } else {
    window.location.replace(pathname || window.location.pathname);
  }
};

export const autoLogin = (userInfo: any) => {
  let prof = getJsonProfileUser();
  let z = JSON.parse(JSON.stringify(prof));
  // z.myToken = res.data.content.myToken;
  // z.isregisyet = res.data.content.isregisyet;
  // z.email = res.data.content.email; // vi trong luc OTP co update lai email
  z.userchatinfo.username = userInfo.email
  z.userchatinfo.password = userInfo.keychat
  z = {
    ...z,
    ...userInfo
  }
  setTokenProfileUser(JSON.stringify(z), userInfo?.myToken);
}

export const isLogined = () => {
  if (readCookie(ETokenName.COMUNITY_ACCESS_KEY) !== null) {
    return true;
  }
  return false;
};

export const getCookieTokenChatTool = () => {
  const tokennamechat = readCookie(process.env.TOKENNAME_CHAT);

  if (tokennamechat) {
    return tokennamechat;
  }

  const domain = process.env.COOKIE_DOMAIN || window.location.hostname;
  let tg = Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2);
  setCookie(365, tg, process.env.TOKENNAME_CHAT, domain);

  return tg;
};
