import axios from "axios";
import { readCookie } from "../../utils";
export const axiosCas10 = {
    post: (url: string, param?: any) => {
        let config = {
            headers: {
                Accept: 'application/json',
                'content-type': 'application/json',
                Authorization: readCookie("localStorage@ACCESS_KEY") || "",
                channel: 'WEB',
                component: 'TS10',
            },
        };
        return axios.post(process.env.URL_CAS10_API + 'api/' + url, param, config);
    },
    get: (url: string) => {
        let config = {
            headers: {
                Accept: 'application/json',
                'content-type': 'application/json',
                Authorization: readCookie("localStorage@ACCESS_KEY") || "",
                channel: 'WEB',
                component: 'TS10',
            },
        };
        return axios.get(process.env.URL_CAS10_API + 'api/' + url, config);
    }
}

export default axiosCas10;
