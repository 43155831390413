import { Modal } from '../../common';
import ButtonClose from '../../../assets/img/draft/btn-close.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { renderAdmissionPercentage } from '../../../utils';
import './ModalAspirationMobile.scss';

const Note = {
  1: 'Học sinh được đăng ký ba nguyện vọng vào các trường THPT (trừ chuyên Lê Hồng Phong, chuyên Trần Đại Nghĩa và Phổ thông Năng khiếu).',
  2: `Học sinh đăng kí nguyện vọng vào trường chuyên, ngoài được đăng ký 3 nguyện vọng ưu tiên vào lớp 10 THPT, học sinh được đăng ký thêm 4 nguyện vọng ưu tiên vào trường chuyên.
  Cụ thể nguyện vọng ưu tiên 1, 2 gồm các lớp chuyên của 1 trong 6 trường chuyên và trường có lớp chuyên. Nguyện vong ưu tiên 3, 4 gồm các lớp không chuyên của Trường THPT Chuyên Lê Hồng Phong và Trường THPT Chuyên Trần Đại Nghĩa.`,
  3: 'Học sinh được đăng kí 2 nguyện vọng'
}

export const ModalAspirationMobile: React.FC<any> = ({
  isOpen,
  onClose,
  isCl, 
  onClear,
  setIsShow
}) => {

  const { listChosenSchool } = useSelector((state: RootState) => state.storeListChosenChool);
  const { typeAdmission } = useSelector((state: RootState) => state.storeUserInfo)
  function AddressDistance(distance: number) {
    if (distance < 5) return <p style={{ color: '#000' }}>Gần</p>;
    else if (distance > 10) return <p style={{ color: '#FF0000' }}>Rất xa</p>;
    else return <p style={{ color: '#F18017' }}>Xa</p>;
  }

  const getNote = () => {
    if (typeAdmission === 1) return Note[1]
    else if (typeAdmission === 2) return Note[2]
    else if (typeAdmission === 3) return Note[3]
  }
  
  return (
    <Modal isShow={isOpen} setIsShow = {setIsShow}>
      <div className="modal-aspiration-mobile">
        <div className="header-title">
          <div className="header-close">
            <img src={ButtonClose} alt="" onClick={onClose} />
          </div>
          <h4>NGUYỆN VỌNG CỦA BẠN</h4>
          <p>*Thông tin này chỉ mang tính tham khảo, không mang tính quyết định</p>
        </div>
        <table className="aspiration-header">
          <thead>
            <tr>
                <td rowSpan={2} className="stt">STT</td>
                <td className='col-2 row-1'>Tên trường</td>
                <td className='col-3 row-1'>{isCl? "Khả năng trúng tuyển" :"Học phí"}</td>
            </tr>
            <tr>
              <td className='col-2'>Địa chỉ</td>
              <td className='col-3'>Khoảng cách từ nhà đến trường</td>
            </tr>
          </thead>
        </table>
        <div className='content-scroll'>
         <div className="listAspiration">
          <table>
              {listChosenSchool.map((aspiration: any, index: any) => {  
                return (
                  <tbody>
                     <tr>
                        <td rowSpan={2} className="stt"><p>{index + 1}</p></td>
                        <td className='col-2 row-1'>
                          <p>{aspiration.highschoolname}</p>
                          {aspiration.highschooladdresses[0]?.distance ? <p style={{color: "#2400FF"}}>({aspiration.highschooladdresses[0]?.distance} km)</p>:""}
                        </td>
                        <td className='col-3 row-1'>
                          { isCl ?<p className={aspiration.ratepercent <= 70 ? 'low-ratepercent' : ''}>{aspiration.ratepercent? (renderAdmissionPercentage(aspiration.ratepercent)):""}</p>:<p>{aspiration.highschoolfee}</p>}  
                        </td>
                    </tr>
                    <tr>
                      <td className='col-2'>
                        <p>
                         {aspiration.highschooladdresses[0]?.street +
                           ' ' +
                           aspiration.highschooladdresses[0]?.wardtext +
                           ', ' +
                           aspiration.highschooladdresses[0]?.districttext +
                           ', ' +
                           aspiration.highschooladdresses[0]?.provincetext}
                       </p>
                      </td>
                      <td className='col-3'>
                      {AddressDistance(aspiration.highschooladdresses[0]?.distance)}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
          </table>
        </div>

        {isCl? <p className='modal-aspiration-mobile__note'>*Lưu ý: {getNote()}</p> : <></>}
       </div>

        <div className="btn-options">
          <button
            className="btn-reselect"
            onClick={() => {
              onClose && onClose();
              // onClear && onClear();
            }}
          >
            Chọn Lại
          </button>
          {/* <button
            className="btn-save"
            onClick={() => {
              dispatch(
                doUpdateIsChooseHighSchool({
                  searchadmissionid: searchadmissionid,
                  publichighschoolids: publichighschoolids,
                  nonpublichighschoolids: mapIdSchoolToString(nonpublichighschoolids),
                  searchaddressid: searchaddressid,
                }),
              );
              onClose && onClose();
            }}
          >
            Lưu kết quả
          </button> */}
        </div>
      </div>
    </Modal>
  );
};
