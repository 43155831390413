export enum EventListener {
  click = 'click',
  touchend = 'touchend',
  scroll = 'scroll',
  input = 'input',
  pause = 'pause',
  play = 'play',
}

export enum eventListener {
  CLICK = 'click',
  INPUT = 'input',
  PLAY = 'play',
  PAUSE = 'pause',
  ONLINE = 'online',
  OFFLINE = 'offline',
  SCROLL = 'scroll',
  MESSAGE = 'message',
}

export enum ETokenName {
  COMUNITY_ACCESS_KEY = 'localStorage@COMUNITY_ACCESS_KEY',
  SKILL_ACCESS_KEY = 'localStorage@SKILL_ACCESS_KEY',
  // CAREER_GUIDANCE_ACCESS_KEY = 'localStorage@CAREER_GUIDANCE_ACCESS_KEY',
  USER_ACCOUNT = 'localStorage@USER_ACCOUNT',
  SOCKET_TOCKEN = 'localStorage@SOCKET_TOCKEN',
  STUDYABROAD_ACCESS_KEY = 'localStorage@STUDYABROAD_ACCESS_KEY',
  JOB_ACCESS_KEY = 'localStorage@JOB_ACCESS_KEY',
  EXAM_ACCESS_KEY = 'localStorage@EXAM_ACCESS_KEY',
  ADVERTISING_ACCESS_KEY = 'localStorage@ADVERTISING_ACCESS_KEY',
  EFORM_ACCESS_KEY = 'localStorage@EFORM_ACCESS_KEY',
  ACCESS_KEY = 'localStorage@ACCESS_KEY'
}

export const ALL_SCHOOL_OPTION = { schoolid: -1, schoolname: 'Tất cả' };
export const DEFAULT_NOT_FOUND_MESSAGE = 'Không tìm thấy kết quả phù hợp';
export const INDEX_ALL_DISTRICT_OPTION = -1;
export const INDEX_ALL_SCHOOL_OPTION = -1;
export const ORDER_BY_BEST_SCORE = 1;
export const ORDER_BY_NUMBER_STUDENT_DESC = 1;
export const ORDER_BY_SCORE_DESC = 2;
export const START_PAGE_NUMBER = 0;
export const MAX_ITEM_PER_PAGE = 1000;
export const emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
export const phoneRegex = /^[0-9]+$/;
