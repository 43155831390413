import { createSlice } from '@reduxjs/toolkit';

type TSearchPersist = {
  ologyID: number;
  typeID: number;
  subjects: Array<any>;
  pointsArr: Array<any>;
  namePersist: string;
  phonePersist: string;
  emailPersist: string;
  allPersist?: any;
};

const initialState = {
  ologyID: 0,
  typeID: 0,
  pointsArr: [],
  subjects: [],
  namePersist: '',
  phonePersist: '',
  emailPersist: '',
  allPersist: {}
} as TSearchPersist;

export const persistSearchSlice = createSlice({
  name: 'persistSearch',
  initialState: initialState,
  reducers: {
    doStoreAllPersist(state,action){
      state.allPersist = action.payload
    },
    doStoreSearchParam(state, action) {
      state.ologyID = action.payload.ology;
      state.typeID = action.payload.type;
      state.pointsArr = action.payload.points;
      state.subjects = action.payload.subjectIDs;
      state.namePersist = action.payload.name;
      state.phonePersist = action.payload.phone;
      state.emailPersist = action.payload.email;
    },

    doClearSearchParam(state) {
      state.ologyID = 0;
      state.typeID = 0;
      state.pointsArr = [];
      state.subjects = [];
      state.namePersist = '';
      state.phonePersist = '';
      state.emailPersist = '';
    },
  },
});

const { actions, reducer } = persistSearchSlice;
export const { doStoreSearchParam, doClearSearchParam,doStoreAllPersist } = actions;
export default reducer;
