import { Modal } from '../common';
import DeleteLargeIcon from '../../assets/img/draft/deleteLargeIcon.png';
import './ModalDelete.scss';


export const ModalDelete: React.FC<any> = ({
  isOpen,
  setIsShow,
  onClose,
  handleDelete
}) => {

  return (
    <Modal isShow={isOpen} setIsShow = {setIsShow}>
        <div className='modal-delete'>
            <img src={DeleteLargeIcon}/>
            <p>Bạn có chắc chắn muốn xóa kết quả chọn trường này?</p>
            <div className="options">
              <button className="btn-cancel btn" onClick={onClose}>
                Hủy
              </button>
              <button className="btn-submit btn" onClick={() => {
                handleDelete();
                onClose()
              }
              }>
                Đồng ý
              </button>
            </div>
        </div>
    </Modal>
  );
};
