import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiAdmission } from "../../../services/aixos/apiAdmission";
import { apiGetReport } from "../../../services/aixos/apiGetReport";

export const doGetReport = createAsyncThunk(
    'report@getReport',
    async (params: any) => {
        const result = await apiGetReport.getReport(params);
        return result.data;
    }
)

export const doGetReportDetail = createAsyncThunk(
    'report@getReportDetail',
    async (params: IParamsDetailConsultant) => {
        const result = await apiGetReport.getReportDetail(params);
        return result.data;
    }
)

const initialState = {
    dataReport: [],
    isLoadingReport: false,
    errorReport: {},
    reportDetail: {} as any,
    isLoadingReportDetail: false,
}

const getReport = createSlice({
    name: 'get@',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(doGetReport.pending, (state) => {
            state.isLoadingReport = true
        })
        builder.addCase(doGetReport.fulfilled, (state, action) => {
            state.dataReport = action.payload?.content || {};
            state.isLoadingReport = false;
        })
        builder.addCase(doGetReport.rejected, (state, action) => {
            state.errorReport = action.error;
            state.isLoadingReport = false;
        })

        // report detail
        builder.addCase(doGetReportDetail.pending, (state) => {
            state.isLoadingReportDetail = true
        })
        builder.addCase(doGetReportDetail.fulfilled, (state, action) => {
            state.reportDetail = action.payload?.content?.consultant || [];
            state.isLoadingReportDetail = false;
        })
        builder.addCase(doGetReportDetail.rejected, (state, action) => {
            state.errorReport = action.error;
            state.isLoadingReportDetail = false;
        })
    },
})

const { reducer: getReportReducer, actions } = getReport
export default getReportReducer;