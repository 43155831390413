import axios, { AxiosError, AxiosResponse } from 'axios';
import { createHeaderAPINonToken, responseAPI, responseErrorAPI } from './axiosBase';

const baseURL = process.env.URL_CAS10_API || '';

const axiosCas10NoToken = axios.create(createHeaderAPINonToken(baseURL));

axiosCas10NoToken.interceptors.response.use(
  (res: AxiosResponse) => responseAPI(res),
  (error: AxiosError) => responseErrorAPI(error),
);

export default axiosCas10NoToken;
