import axios from "axios";
import { getTokenProfileUser } from "../../utils";

export const axiosSNETAPI2 = {
    post: (url: string, param?: any) => {
        let config = {
            headers: {
                Accept: 'application/json',
                'content-type': 'application/json',
                Authorization: getTokenProfileUser(),
                channel: 'WEB',
                component: 'TS10',
            },
        };
        return axios.post(process.env.URL_SNET_API + 'api/' + url, param, config);
    },
    get: (url: string) => {
        let config = {
            headers: {
                Accept: 'application/json',
                'content-type': 'application/json',
                Authorization: getTokenProfileUser(),
                channel: 'WEB',
                component: 'TS10',
            },
        };
        return axios.get(process.env.URL_SNET_API + 'api/' + url, config);
    }
}