import { createSlice } from '@reduxjs/toolkit';

type TSearchPersist = {
  listSchool: Array<number>;
};

const initialState = {
  listSchool: [],
} as TSearchPersist;

export const listSchoolSlice = createSlice({
  name: 'persistSearch',
  initialState: initialState,
  reducers: {
    doSetListSchool(state, action) {
      state.listSchool = action.payload;
    },

    doClearListSchool(state) {
      state.listSchool = [];
    },
  },
});

const { actions, reducer } = listSchoolSlice;
export const { doSetListSchool, doClearListSchool } = actions;
export default reducer;
