import { createSlice } from '@reduxjs/toolkit';

type IVerifingSlice = {
  isCheckPhone?: boolean;
  isCheckEmail?: boolean;
};

const initialState = {
  isCheckPhone: false,
  isCheckEmail: false,
} as IVerifingSlice;

export const verifingSlice = createSlice({
  name: 'verifingSlice',
  initialState: initialState,
  reducers: {
    doUpdatingStatePhone(state, action) {
      state.isCheckEmail = action.payload.isCheckingEmail;
      state.isCheckPhone = action.payload.isCheckingPhone;
    },
  },
});

const { actions, reducer } = verifingSlice;
export const { doUpdatingStatePhone } = actions;
export default reducer;
