import { createSlice } from '@reduxjs/toolkit';



const initialState = {
    listChosenSchool: []
};

export const listChosenSchoolSlice = createSlice({
  name: 'listChosenSchool',
  initialState: initialState,
  reducers: {
    doStoreListChosen(state, action) {
        state.listChosenSchool = action.payload;
    },
  },
});

const { actions, reducer } = listChosenSchoolSlice;
export const {
    doStoreListChosen
} = actions;
export default reducer;
