import axiosSystemAPI from './axiosSystemAPI';
import axiosSNetAPI from './axiosSNetAPI';
import axiosSNETAPI from './axiosSNetAPI';
import axiosSocialAPI from './axiosSocialAPI';
import axiosChecking from './axiosChecking';
import { axiosSNETAPI2 } from './axiosSNetAPI2';
import axiosSNETNoToken from './axiosSNetNoToken';

export const apiLogin = {
  //============================NEW====================================
  sendOTP: (dataOTP: IParamsSendOTP) => {
    const url = 'Login/SendOTP';
    return axiosSNETNoToken.post(url, dataOTP);
  },
  RegisterAfterOTPAndLoginAHS: (params: IParamsRegisterAfterOTPAndLoginAHS) => {
    const url = 'Login/RegisterAfterOTPAndLoginAHS';
    return axiosSNETNoToken.post(url, params);
  },
  //====================================================================
  login: (dataUser: ILoginDataApi) => {
    const url = 'Login/Index';
    return axiosSNETAPI2.post(url, dataUser);
  },
  checkEmailVerify: (email: string, phone: string, component: string, cookie: string) => {
    const url = `AdmissionChange/CheckEmailVerifierByOauth2?email=${email}&phone=${phone}&component=${component}&cookie=${cookie}`;
    return axiosChecking.get(url);
  },
  googleLogin: (accessToken: string) => {
    const url = `SendMail/GetPersonalInfo?token=${accessToken}`;
    return axiosSocialAPI.get(url);
  },
  logIn: (params: IParamsLogin) => {
    const url = 'Login';
    return axiosSystemAPI.post(url, { ...params, isfromhsdt: 1 });
  },
  sendOTPHSDT: (params: any) => {
    const url = 'Login/SendOTPHSDT';
    return axiosSNETAPI2.post(url, params);
  },
  checkOTPHSDT: (params: any) => {
    const url = 'Login/CheckOTPHSDT';
    return axiosSNETAPI2.post(url, params);
  },
  registerAfterOTPAndLogin: (params: any) => {
    const url = 'Login/RegisterAfterOTPAndLogin';
    return axiosSystemAPI.post(url, params);
  },
  checkOTP: (dataOTP: ICheckOTP) => {
    const url = 'Login/CheckOTP';
    return axiosSNETAPI2.post(url, dataOTP);
  },
  renewPassword: (data: IParamRenewPassword) => {
    const url = 'Login/RenewPasswordHSDT';
    return axiosSystemAPI.post(url, data);
  },

  getInfoAccountChatTool: (param: any) => {
    //   "content": {
    //     "userchatinfo": {
    //         "id": 210252,
    //         "username": "YOOTER210252",
    //         "password": "N2M5N2U3NTE0MGM3NWZjMzU5MjNhNjM3ZDA3ZGEwZDM=",
    //         "isnew": 0
    //     },
    //     "myToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIyMTAyNTIiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9tb2JpbGVwaG9uZSI6IiIsImF1dGhtZXRob2QiOiI3Yzk3ZTc1MTQwYzc1ZmMzNTkyM2E2MzdkMDdkYTBkMyIsInJvbGUiOiIwIiwibmJmIjoxNjYyOTU4Mzk3LCJleHAiOjE2OTQ0OTQzOTcsImlhdCI6MTY2Mjk1ODM5N30._wJOa6A_AsHN5l1-qqhqDot9hXHkz-h9IceuJH7RGIc"
    // }
    return axiosSNETAPI2.post('Login/GetUserInfoChatYootWay', param);
  },
};
