import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiAdmission } from "../../../services/aixos/apiAdmission";


export const doGetOneNews = createAsyncThunk(
    'get@GetOneNews',
    async (params: any) => {
      const result = await apiAdmission.getOneNews(params);
      return result.data;
    }
  )

const initialState = { 
    news: {},
    isLoading: false,
    error: {}
}

const getOneNews = createSlice({
    name: 'GetFil@',
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(doGetOneNews.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(doGetOneNews.fulfilled, (state, action) => {
            state.news = action.payload.content?.news;
            state.isLoading = false;
        })
        builder.addCase(doGetOneNews.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        })
    },
})

const {reducer: getOneNewsReducer, actions } = getOneNews;
export default getOneNewsReducer;