

export const BtnClose = () => {
    return (
        <svg width="48" height="47" viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_2786_3742)">
        <rect x="4" width="40" height="40" rx="20" fill="url(#paint0_linear_2786_3742)"/>
        <rect x="4" width="40" height="40" rx="20" fill="url(#paint1_linear_2786_3742)"/>
        <rect x="4" width="40" height="40" rx="20" fill="url(#paint2_linear_2786_3742)"/>
        <rect x="4" width="40" height="40" rx="20" fill="url(#paint3_linear_2786_3742)"/>
        </g>
        <path d="M29.9971 26.0012L17.9971 14.0012M29.9971 14.0012L17.9971 26.0012" stroke="white" strokeWidth="1.57506" strokeLinecap="round"/>
        <defs>
        <filter id="filter0_d_2786_3742" x="0.849877" y="0" width="46.3002" height="46.3002" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="3.15012"/>
        <feGaussianBlur stdDeviation="1.57506"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2786_3742"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2786_3742" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_2786_3742" x1="24" y1="0" x2="24" y2="40" gradientUnits="userSpaceOnUse">
        <stop stopColor="#58C2FE"/>
        <stop offset="1" stopColor="#5058FE"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2786_3742" x1="24" y1="0" x2="24" y2="40" gradientUnits="userSpaceOnUse">
        <stop stopColor="#70D0C9"/>
        <stop offset="1" stopColor="#5DA9D6"/>
        </linearGradient>
        <linearGradient id="paint2_linear_2786_3742" x1="4" y1="-3.33333" x2="44.3532" y2="-2.96326" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FEA23D"/>
        <stop offset="1" stopColor="#FF3B4E"/>
        </linearGradient>
        <linearGradient id="paint3_linear_2786_3742" x1="42" y1="0.806642" x2="38.1633" y2="34.723" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FEA897"/>
        <stop offset="1" stopColor="#F5775F"/>
        </linearGradient>
        </defs>
        </svg>
    );
  };
  