export const SvgLogoYootFooter = () => {
    return (
        <svg width="247" height="82" viewBox="0 0 247 82" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2989_1932)">
        <path d="M109.565 8.85725C103.543 3.35834 96.2906 0.608887 87.8078 0.608887C79.2925 0.608887 72.04 3.35834 66.0178 8.85725C59.866 14.5179 56.7578 21.6341 56.7578 30.1736C56.7578 38.6807 59.8337 45.797 66.0178 51.49C72.0076 57.0212 79.2925 59.7707 87.8078 59.7707C96.2906 59.7707 103.543 57.0212 109.533 51.49C115.685 45.797 118.793 38.7131 118.793 30.1736C118.793 21.6341 115.717 14.5179 109.565 8.85725ZM99.0103 41.6243C96.2583 44.7619 92.5025 46.3469 87.8078 46.3469C83.0807 46.3469 79.3249 44.7619 76.5728 41.6243C73.9178 38.6807 72.5904 34.8315 72.5904 30.1413C72.5904 25.451 73.9178 21.6018 76.5728 18.6582C79.3249 15.553 83.0807 14.0003 87.8078 14.0003C92.5025 14.0003 96.2583 15.553 99.0103 18.6582C101.665 21.6018 102.993 25.451 102.993 30.1413C102.993 34.8639 101.665 38.6807 99.0103 41.6243Z" fill="#E52726"/>
        <path d="M181.769 8.85719C175.78 3.35828 168.527 0.608826 160.044 0.608826C151.529 0.608826 144.244 3.35828 138.254 8.85719C132.102 14.5178 128.994 21.6341 128.994 30.1735C128.994 38.6807 132.07 45.7969 138.254 51.4899C144.244 57.0212 151.529 59.7706 160.044 59.7706C168.527 59.7706 175.78 57.0212 181.769 51.4899C187.921 45.7969 191.029 38.713 191.029 30.1735C191.029 21.6341 187.953 14.5178 181.769 8.85719ZM171.247 41.6242C168.495 44.7618 164.739 46.3468 160.044 46.3468C155.317 46.3468 151.561 44.7618 148.809 41.6242C146.154 38.6807 144.827 34.8314 144.827 30.1412C144.827 25.451 146.154 21.6017 148.809 18.6582C151.561 15.5529 155.317 14.0003 160.044 14.0003C164.739 14.0003 168.495 15.5529 171.247 18.6582C173.902 21.6017 175.229 25.451 175.229 30.1412C175.229 34.8638 173.902 38.6807 171.247 41.6242Z" fill="#E52726"/>
        <path d="M243.999 1.38477H201.973C200.84 1.38477 199.934 2.29047 199.934 3.4226V12.3502C199.934 13.4824 200.84 14.3881 201.973 14.3881H213.208C214.342 14.3881 215.248 15.2938 215.248 16.4259V57.1178C215.248 58.2499 216.155 59.1557 217.288 59.1557H228.652C229.786 59.1557 230.692 58.2499 230.692 57.1178V16.4582C230.692 15.3261 231.599 14.4204 232.732 14.4204H243.967C245.1 14.4204 246.007 13.5147 246.007 12.3826V3.4226C246.039 2.29047 245.132 1.38477 243.999 1.38477Z" fill="#E52726"/>
        <path d="M7.51562 76.7523C7.51562 74.2616 9.45827 72.4502 11.8866 72.4502C14.3149 72.4502 16.2575 74.2939 16.2575 76.7523C16.2575 79.243 14.3149 81.0544 11.8866 81.0544C9.45827 81.0544 7.51562 79.243 7.51562 76.7523ZM14.4444 76.7523C14.4444 75.1673 13.4083 73.9705 11.8542 73.9705C10.3325 73.9705 9.26401 75.1673 9.26401 76.7523C9.26401 78.3373 10.3001 79.5341 11.8542 79.5341C13.4083 79.5341 14.4444 78.3373 14.4444 76.7523Z" fill="white"/>
        <path d="M17.6426 77.9168V72.5473H19.391V77.8198C19.391 79.049 19.909 79.5018 21.0746 79.5018C22.2078 79.5018 22.7258 79.0166 22.7258 77.8198V72.5473H24.4742V77.9168C24.4742 80.0517 22.9849 81.0544 21.0746 81.0544C19.1319 81.0544 17.6426 80.0517 17.6426 77.9168Z" fill="white"/>
        <path d="M28.6845 77.0435C28.2636 76.6554 27.6808 75.879 27.6808 75.879C27.6808 75.879 27.8103 76.8171 27.8103 77.3993V80.9251H26.1914V72.4503H26.3533L30.5947 76.4289C31.0156 76.8171 31.5984 77.5934 31.5984 77.5934C31.5984 77.5934 31.5013 76.6554 31.5013 76.0731V72.5474H33.1202V81.0221H32.9583L28.6845 77.0435Z" fill="white"/>
        <path d="M34.5703 76.7523C34.5703 74.2616 36.3187 72.4502 38.8765 72.4502C40.2364 72.4502 41.2401 72.806 41.92 73.6147L40.7544 74.7791C40.3335 74.3586 39.8478 74.0028 38.8765 74.0028C37.3548 74.0028 36.3511 75.1673 36.3511 76.7523C36.3511 78.3373 37.3548 79.5341 39.1355 79.5341C39.6859 79.5341 40.3335 79.437 40.7868 79.2106V77.9814H38.747V76.4612H42.3733V80.084C41.8876 80.4721 40.6896 81.0544 39.1355 81.0544C36.3834 81.0544 34.5703 79.243 34.5703 76.7523Z" fill="white"/>
        <path d="M46.2695 77.5284C46.2695 76.752 46.8847 76.2021 47.597 76.2021C48.3093 76.2021 48.9245 76.7844 48.9245 77.5284C48.9245 78.2723 48.3093 78.8546 47.597 78.8546C46.8847 78.8546 46.2695 78.2723 46.2695 77.5284Z" fill="white"/>
        <path d="M52.6152 76.7523C52.6152 74.2616 54.5579 72.4502 56.9862 72.4502C59.4145 72.4502 61.3571 74.2939 61.3571 76.7523C61.3571 79.243 59.4145 81.0544 56.9862 81.0544C54.5579 81.0544 52.6152 79.243 52.6152 76.7523ZM59.5764 76.7523C59.5764 75.1673 58.5403 73.9705 56.9862 73.9705C55.4644 73.9705 54.396 75.1673 54.396 76.7523C54.396 78.3373 55.4321 79.5341 56.9862 79.5341C58.5079 79.5341 59.5764 78.3373 59.5764 76.7523Z" fill="white"/>
        <path d="M69.0309 80.9575H67.0235L65.6313 78.8226C65.4694 78.8549 65.3075 78.8549 65.1456 78.8549H64.5628V80.9575H62.8145V72.5474H65.1456C67.5092 72.5474 68.9014 73.6795 68.9014 75.7173C68.9014 77.0435 68.3186 77.8522 67.2825 78.3374L69.0309 80.9575ZM65.0809 77.367C66.5055 77.367 67.1207 76.9141 67.1207 75.7497C67.1207 74.5852 66.4731 74.1 65.0809 74.1H64.5305V77.3993H65.0809V77.367Z" fill="white"/>
        <path d="M70.4551 72.5474H72.2035V80.9575H70.4551V72.5474Z" fill="white"/>
        <path d="M73.6875 76.7524C73.6875 74.2617 75.4359 72.4503 77.9937 72.4503C79.3535 72.4503 80.3572 72.8061 81.0372 73.6148L79.8716 74.7793C79.4507 74.3588 78.965 74.0029 77.9937 74.0029C76.472 74.0029 75.4683 75.1674 75.4683 76.7524C75.4683 78.3374 76.472 79.5342 78.2527 79.5342C78.8031 79.5342 79.4507 79.4372 79.904 79.2107V77.9816H77.8642V76.4613H81.4905V80.0841C81.0048 80.4723 79.8068 81.0545 78.2527 81.0545C75.5006 81.0545 73.6875 79.2431 73.6875 76.7524Z" fill="white"/>
        <path d="M83.1406 72.5474H84.889V80.9575H83.1406V72.5474Z" fill="white"/>
        <path d="M89.163 77.0434C88.7421 76.6552 88.1593 75.8789 88.1593 75.8789C88.1593 75.8789 88.2888 76.817 88.2888 77.3992V80.925H86.6699V72.4502H86.8318L91.0732 76.4288C91.4941 76.817 92.0769 77.5933 92.0769 77.5933C92.0769 77.5933 91.9798 76.6552 91.9798 76.073V72.5472H93.5987V81.022H93.4368L89.163 77.0434Z" fill="white"/>
        <path d="M98.4274 72.4824H98.5892L102.442 80.9572H100.629L100.24 80.0192H96.8085L96.4199 80.9572H94.7363L98.4274 72.4824ZM99.5929 78.5636L98.9778 77.108C98.7188 76.5257 98.4597 75.5877 98.4597 75.5877C98.4597 75.5877 98.2007 76.4934 97.9741 77.108L97.3589 78.5636H99.5929Z" fill="white"/>
        <path d="M103.541 72.5474H105.289V79.4372H109.11V80.9575H103.573V72.5474H103.541Z" fill="white"/>
        <path d="M112.738 77.5285C112.738 76.7522 113.353 76.2023 114.066 76.2023C114.778 76.2023 115.393 76.7846 115.393 77.5285C115.393 78.2725 114.778 78.8547 114.066 78.8547C113.353 78.8547 112.738 78.2725 112.738 77.5285Z" fill="white"/>
        <path d="M119.115 76.7523C119.115 74.2616 121.058 72.4502 123.486 72.4502C125.914 72.4502 127.857 74.2939 127.857 76.7523C127.857 79.243 125.914 81.0544 123.486 81.0544C121.058 81.0544 119.115 79.243 119.115 76.7523ZM126.044 76.7523C126.044 75.1673 125.008 73.9705 123.454 73.9705C121.932 73.9705 120.864 75.1673 120.864 76.7523C120.864 78.3373 121.9 79.5341 123.454 79.5341C125.008 79.5341 126.044 78.3373 126.044 76.7523Z" fill="white"/>
        <path d="M129.314 72.5473H131.646C134.009 72.5473 135.401 73.7765 135.401 75.8143C135.401 77.8521 133.977 79.049 131.646 79.049H131.063V80.9574H129.314V72.5473ZM131.581 77.5287C133.005 77.5287 133.621 76.9464 133.621 75.8143C133.621 74.6498 132.973 74.0676 131.581 74.0676H131.03V77.5287H131.581Z" fill="white"/>
        <path d="M138.539 74.0677H136.143V72.5474H142.683V74.0677H140.287V80.9575H138.539V74.0677Z" fill="white"/>
        <path d="M143.986 72.5474H145.735V80.9575H143.986V72.5474Z" fill="white"/>
        <path d="M147.509 72.4502H147.638L151.783 77.0111L155.862 72.4502H156.024V80.9573H154.341V77.8197C154.341 77.2375 154.405 76.3641 154.405 76.3641C154.405 76.3641 153.887 77.1081 153.499 77.5609L151.815 79.437H151.653L149.97 77.5609C149.581 77.1081 149.063 76.3641 149.063 76.3641C149.063 76.3641 149.128 77.2698 149.128 77.8197V80.9573H147.477V72.4502H147.509Z" fill="white"/>
        <path d="M157.807 72.5474H159.555V80.9575H157.807V72.5474Z" fill="white"/>
        <path d="M160.59 79.3077L162.014 78.499C162.371 79.1136 162.856 79.5664 163.73 79.5664C164.443 79.5664 164.896 79.2106 164.896 78.7254C164.896 78.1432 164.41 77.9168 163.633 77.5609L163.212 77.3669C161.95 76.8493 161.14 76.17 161.14 74.7468C161.14 73.4529 162.144 72.4502 163.666 72.4502C164.767 72.4502 165.544 72.8383 166.126 73.8411L164.767 74.7145C164.475 74.1969 164.151 73.9705 163.666 73.9705C163.148 73.9705 162.824 74.2939 162.824 74.7145C162.824 75.232 163.148 75.4584 163.892 75.7819L164.313 75.976C165.803 76.5905 166.612 77.2375 166.612 78.6931C166.612 80.2457 165.382 81.1191 163.73 81.1191C162.144 81.0544 161.108 80.3104 160.59 79.3077Z" fill="white"/>
        <path d="M169.624 74.0677H167.229V72.5474H173.769V74.0677H171.373V80.9575H169.624V74.0677Z" fill="white"/>
        <path d="M175.09 72.5474H176.838V80.9575H175.09V72.5474Z" fill="white"/>
        <path d="M178.305 76.7524C178.305 74.2617 180.215 72.4503 182.643 72.4503C184.165 72.4503 185.266 73.0002 186.011 74.1647L184.683 75.1027C184.295 74.4558 183.615 74.0029 182.643 74.0029C181.122 74.0029 180.085 75.1674 180.085 76.7524C180.085 78.3374 181.122 79.5342 182.643 79.5342C183.744 79.5342 184.327 79.0167 184.813 78.305L186.172 79.2431C185.428 80.3752 184.262 81.0868 182.643 81.0868C180.247 81.0545 178.305 79.2431 178.305 76.7524Z" fill="white"/>
        <path d="M189.695 77.5284C189.695 76.752 190.31 76.2021 191.023 76.2021C191.735 76.2021 192.35 76.7844 192.35 77.5284C192.35 78.2723 191.735 78.8546 191.023 78.8546C190.31 78.8546 189.695 78.2723 189.695 77.5284Z" fill="white"/>
        <path d="M198.279 74.0677H195.883V72.5474H202.423V74.0677H200.027V80.9575H198.279V74.0677Z" fill="white"/>
        <path d="M205.589 72.4824H205.751L209.604 80.9572H207.791L207.403 80.0192H203.971L203.582 80.9572H201.898L205.589 72.4824ZM206.755 78.5636L206.14 77.108C205.881 76.5257 205.622 75.5877 205.622 75.5877C205.622 75.5877 205.363 76.4934 205.136 77.108L204.521 78.5636H206.755Z" fill="white"/>
        <path d="M210.711 72.5473H212.459V79.4371H216.28V80.9574H210.743V72.5473H210.711Z" fill="white"/>
        <path d="M217.676 72.5474H223.245V74.0677H219.392V76.7524H222.759V78.2404H219.392V79.4372H223.407V80.9575H217.676V72.5474Z" fill="white"/>
        <path d="M227.515 77.0435C227.094 76.6554 226.511 75.879 226.511 75.879C226.511 75.879 226.64 76.8171 226.64 77.3993V80.9251H225.021V72.4503H225.183L229.425 76.4289C229.846 76.8171 230.429 77.5934 230.429 77.5934C230.429 77.5934 230.331 76.6554 230.331 76.0731V72.5474H231.95V81.0221H231.788L227.515 77.0435Z" fill="white"/>
        <path d="M235.615 74.0676H233.219V72.5473H239.759V74.0676H237.363V80.9574H235.615V74.0676Z" fill="white"/>
        <path d="M240.236 79.3077L241.661 78.499C242.017 79.1136 242.503 79.5664 243.377 79.5664C244.089 79.5664 244.543 79.2106 244.543 78.7254C244.543 78.1432 244.057 77.9168 243.28 77.5609L242.859 77.3669C241.596 76.8493 240.787 76.17 240.787 74.7468C240.787 73.4529 241.79 72.4502 243.312 72.4502C244.413 72.4502 245.19 72.8383 245.773 73.8411L244.413 74.7145C244.122 74.1969 243.798 73.9705 243.312 73.9705C242.794 73.9705 242.47 74.2939 242.47 74.7145C242.47 75.232 242.794 75.4584 243.539 75.7819L243.96 75.976C245.417 76.5905 246.259 77.2375 246.259 78.6931C246.259 80.2457 245.028 81.1191 243.377 81.1191C241.758 81.0544 240.722 80.3104 240.236 79.3077Z" fill="white"/>
        <path d="M0.484177 72.5473C0.19278 72.5473 0.0632702 72.7737 0.19278 73.0325L2.55633 76.7523L0.19278 80.4722C0.0632702 80.6986 0.225157 80.9574 0.484177 80.9574H2.26494L4.79037 76.9464C4.85513 76.8494 4.85513 76.72 4.79037 76.5906L2.26494 72.5473H0.484177Z" fill="white"/>
        <path d="M4.20846 74.2615L5.14741 72.806C5.24454 72.6766 5.40643 72.5795 5.56831 72.5795H7.28431C7.4462 72.5795 7.54333 72.7413 7.4462 72.8706L5.34167 76.1053C5.27692 76.2347 5.08265 76.2347 5.0179 76.1053L4.20846 74.8114C4.07895 74.6174 4.07895 74.4233 4.20846 74.2615Z" fill="white"/>
        <path d="M23.0502 1.38483H33.8967C34.6737 1.38483 35.3537 1.77298 35.7746 2.41992L42.25 12.7708C42.7033 13.4824 42.7033 14.3881 42.25 15.0997L36.9401 23.5745C36.066 24.9654 34.0586 24.9654 33.1844 23.5745L21.2047 4.74886C20.2658 3.29327 21.3342 1.38483 23.0502 1.38483Z" fill="#27AAE1"/>
        <path d="M42.1526 1.38464H48.4338C49.4699 1.38464 50.1174 2.51677 49.567 3.42247L46.4911 8.33915C45.9731 9.18015 44.7751 9.18015 44.2571 8.33915L41.0517 3.42247C40.3394 2.29035 40.8575 1.38464 42.1526 1.38464Z" fill="#F7DA00"/>
        <path d="M20.3311 13.5794C20.1369 12.5443 19.8131 11.7033 19.3274 11.0564C15.9278 5.78389 14.2118 2.9374 14.2118 2.9374C13.5966 1.967 12.4958 1.35242 11.3302 1.35242H2.32928C0.257122 1.35242 -0.584691 2.84036 0.386631 4.61942C0.386631 4.61942 1.26082 6.10736 9.93797 19.7576C10.6179 20.825 13.5642 25.4182 15.6688 28.7176C17.3848 26.0975 21.3996 19.1107 20.3311 13.5794Z" fill="#BB281D"/>
        <path d="M0.418504 55.9856C-0.390932 57.4735 0.677523 59.2526 2.36115 59.2526H13.4018C14.1789 59.2526 14.8588 58.8644 15.2797 58.2175L27.5832 37.8068C28.8135 35.8014 32.375 31.2728 29.882 27.4236C26.1262 21.6336 22.2085 15.326 22.2409 15.5524C23.1475 21.6659 16.8339 30.6583 16.8339 30.6583L0.418504 55.9856Z" fill="#E52726"/>
        </g>
        <defs>
        <clipPath id="clip0_2989_1932">
        <rect width="246.263" height="80.4458" fill="white" transform="translate(0 0.608887)"/>
        </clipPath>
        </defs>
        </svg>
        
    );
  };