import './HomePage.scss';
import HeaderSearch from '../../components/HomePage/HeaderSearch/HeaderSearch';
import { TableSchoolDesk } from '../../components/HomePage/TableSchool/TableSchoolDesk';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { useEffect, useState } from 'react';
import Contact from '../../components/HomePage/Contact/Contact';
import { HeaderLayoutDesktop } from '../../layouts/HeaderLayout/HeaderLayoutDesktop';
import { NewsList } from '../../components/HomePage/NewsList';
// import { BannerAds } from '../../components/common/BannerAds/BannerAds';
// import { WrapWithBanner } from '../../components/common/WrapWithBanner/WrapWithBanner';
import { doGetBannerSchool } from '../../redux/slice/appSlice/getBannerSlice';
import { useDispatch } from 'react-redux';

export const HomePageDesk = () => {
  const [isShowTable, setIsShowTable] = useState(false);
  const [isPuclicSchool, setIsPuclicSchool] = useState(true);
  const dispatch = useDispatch();
  const {
    listSchool,
    listSchoolNonPublic,
    isLoadingListSchool,
  } = useSelector((state: RootState) => state.searchSlice);

  const {
    banners, 
    isLoadingBanner
  } = useSelector((state: RootState) => state.getBannerSliceReducer);

  useEffect(() => {
    document.getElementById("table-school")?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }, [isShowTable])


  useEffect(() => {
    dispatch(doGetBannerSchool({
        typeschool: 50,
        bannerplace: "TopBanner",
        typeproject: 30
    }))
    if((listSchool && listSchool.length) || (listSchoolNonPublic && listSchoolNonPublic.length) > 0) {
      setIsShowTable(true);
    }
  }, [])
  console.log("banners", banners[0])
  return (
    <>
      <div id='header-search' style={{height: "80px"}}></div>
      <div className="home-page">
      <HeaderLayoutDesktop/>
      {/* <BannerAds listBanner={[]} className={'boderRadius29'} height = {'222px'}/> */}
      {/* { WrapWithBanner(HeaderSearch,setIsShowTable) } */}
      <div className='home-page__banner-top'>
        <img src = {banners[0]?.link || ''} onClick = {() => {
          window.open(`${banners[0]?.linkclick}`);
        }}/>
      </div>
      <HeaderSearch setIsShowTable = {setIsShowTable}/>
      {/* <BannerAds listBanner={[]} className={'margin40 width1208'} height = {'228px'}  isMultipleItem = {true}  itemPerSlices = {2}/> */}
      {isShowTable && <TableSchoolDesk listSchool={isPuclicSchool ? listSchool : listSchoolNonPublic} setIsPuclicSchool = {setIsPuclicSchool} ologyName={'ologyName'} searchid={0} isGetInfo={true} preventSellect={false} isLoadingListSchool = {isLoadingListSchool}/>}
      {/* <div id='news-desk' style = {{height: "100px"}}></div> */}
      {/* <BannerAds listBanner={[]} className={'margin40 width1208'} height = {'228px'}  isMultipleItem = {true} itemPerSlices = {2}/> */}
      {/* <NewsList />
      <BannerAds listBanner={[]} className={'margin40 width1208'} height = {'228px'}  isMultipleItem = {true} itemPerSlices = {2}/> */}
      <div id='news-desk' style = {{height: "100px"}}></div>
      {/* <p className="label">TIN TỨC</p> */}
      <NewsList isRelative={false}/>
      <Contact/>
      {/* <BannerAds listBanner={[]} className={'margin40 width1208'} height = {'228px'}  isMultipleItem = {true} itemPerSlices = {2}/> */}
    </div>
    </>
  );
};
