import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiAdmission } from "../../../services/aixos/apiAdmission";
import { apiGoogleMap } from "../../../services/aixos/apiGoogleMap";

export const doGetHistorySearchSlice = createAsyncThunk(
    'getHistorySearch@post/getHistorySearch',
    async (param:any) => {
        const result = await apiAdmission.GetHistorySearchAdmission(param);
        return result.data;
    }
)

const initialState = { 
    nearesttypeadmissionid: 0,
    publicHighSchoolsHistory : [],
    nonPublicHighSchoolsHistory : [],
    totalscoreselections: [],
    nonsubjectspecializeds: [],
    subjectspecializeds: [],
    isLoading: false,
    error: {}
}

const getHistorySearchSlice = createSlice({
    name: 'getHistorySearch@',
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(doGetHistorySearchSlice.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(doGetHistorySearchSlice.fulfilled, (state, action) => {
            state.publicHighSchoolsHistory = action.payload.content?.publichighschools?.highschools || [];
            state.nonPublicHighSchoolsHistory = action.payload.content?.nonpublichighschools.nonhighschools || [];
            state.totalscoreselections = action.payload.content?.publichighschools?.totalscoreselections || [];
            state.nonsubjectspecializeds = action.payload.content?.nonpublichighschools.nonsubjectspecializeds || [];
            state.subjectspecializeds = action.payload.content?.publichighschools.subjectspecializeds || [];
            state.nearesttypeadmissionid = action.payload.content?.nearesttypeadmissionid || 0;
            state.isLoading = false;
        })
        builder.addCase(doGetHistorySearchSlice.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        })
    },
})

const {reducer: getHistorySearchReducer, actions } = getHistorySearchSlice
export default getHistorySearchReducer;