import axios, { AxiosError, AxiosResponse } from 'axios';
import { createHeaderAPINonToken, responseAPI, responseErrorAPI } from './axiosBase';

const baseURL = process.env.URL_EXTERNAL_APP || '';
const axiosExApp = axios.create(createHeaderAPINonToken(baseURL));

axiosExApp.interceptors.response.use(
  (res: AxiosResponse) => responseAPI(res),
  (error: AxiosError) => responseErrorAPI(error),
);

export default axiosExApp;
