import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import Youngboiunderthehood from '../../assets/img/draft/youngman2.png';
import unPinIcon from '../../assets/img/draft/unpinIcon.png';
import PinIcon from '../../assets/img/draft/pinIcon.png';
import DeleteIcon from '../../assets/img/draft/deleteIcon.png';
import './TableSelectedSchool.scss';
import { useDispatch } from 'react-redux';
import { Input } from '../common/Input/Input';
import { SvgSearch } from '../../assets/svg/SvgSearch';
import { DropdownTickSelect } from '../RegisterUniversity/DropdownTickSelect/DropdownTickSelect';
import { TableSchoolCheckBox } from '../HomePage/TableSchool/TableSchoolDesk';
import { useAppSelector } from '../../redux';
import { doGetTypeAdmission } from '../../redux';
import { removeVietnameseTones, stringToSlug, renderAdmissionPercentage } from '../../utils/common';
import { InfoDetail } from '../HomePage/TableSchool/InfoDetail';
import {Loader } from "../common/Loader/Loader"
import Pagination from '../../components/common/Pagination/Pagination';
import { ModalDelete } from './ModalDelete';
import {apiAdmission} from "../../services/aixos/apiAdmission"
import { doGetHistorySearchSlice } from '../../redux/slice/appSlice/getHistorySearchSlice';
import { url } from 'inspector';

interface SelectedSchool {
  listSchool: IHighSchool[];
  setIsPuclicSchool: any;
  totalscores: any;
  isLoading: boolean;
  handleListpointByTypeAdmission?:any
  nearesttypeadmissionid?:any
  nonsubjectspecializeds?:any
  subjectspecializeds?:any
}

export const TableSelectedSchoolDesk = ({ listSchool, setIsPuclicSchool, totalscores, isLoading, handleListpointByTypeAdmission, nearesttypeadmissionid, nonsubjectspecializeds, subjectspecializeds }: SelectedSchool) => {
  const [isCL, setIsCL] = useState(true);
  const [isShownPopup, setIsShownPopup] = useState<boolean>(false);
  const [isShownModal, setIsShownModal] = useState<boolean>(false);
  const [idActive, setIdActive] = useState('default');
  const [typeAdmission, setTypeAdmission] = useState(nearesttypeadmissionid); 
  const [specialSubjectId, setspecialSubjectId] = useState(0); 

  let popupRef = useRef<HTMLDivElement>(null);
  let modalRef = useRef<HTMLDivElement>(null);
  // console.log("listSchool", listSchool);
  // console.log("totalscores", totalscores);
  const ListStar = process.env.PATH_CDN_ASSET_CHANCE + 'general/' + 'images/ListStar.png';
  const dispatch = useDispatch();
  const kindSchoolIdList = [
    {
      kindschoolid: 20,
      kindschool: 'Tư thục',
    },
    {
      kindschoolid: 30,
      kindschool: 'GDTX',
    },
    {
      kindschoolid: 40,
      kindschool: 'CĐ - Trung cấp',
    },
    {
      kindschoolid: 50,
      kindschool: 'Quốc tế',
    },
  ];
  
  useEffect(() => {
    setTypeAdmission(nearesttypeadmissionid);
  }, [nearesttypeadmissionid])
  useEffect(() => {
    dispatch(doGetTypeAdmission());
    //handleListpointByTypeAdmission(typeAdmission);
  }, []);

  //Click outside to close option popup
  useEffect(() => {
    let handle = (e:any) => {
      if(!popupRef.current?.contains(e.target)) {
        setIsShownPopup(false)
      }
    }
    document.addEventListener("mousedown", handle);
    return () => {
      document.removeEventListener("mousedown", handle)
    }
  },[])

  //Click outside to close modal delete
  useEffect(() => {
    // let handle = (e:any) => {
    //   if(!modalRef.current?.contains(e.target)) {
    //     setIsShownModal(false)
    //   }
    // }
    // document.addEventListener("mousedown", handle);
    // return () => {
    //   document.removeEventListener("mousedown", handle)
    // }
  },[])

  const { listTypeAdmission } = useAppSelector(state => state.searchSlice);
  const [listSchoolNew, setListSchoolNew] = useState<any[]>(listSchool);
  // const [listSchoolAllPoint, setListSchoolAllPoint] = useState<any[]>(listSchool);
  // const [listSchoolAllPercent, setListSchoolAllPercent] = useState<any[]>(listSchool);
  // const [listSchoolPointPercent, setListSchoolPointPercent] = useState<any[]>(listSchool);
  const [listKindId, setListKindId] = useState([20, 30, 40, 50]);
  const [point, setPoint] = useState('Tất cả');
  const [percent, setPercent] = useState('Tất cả');
  const [listPoint, setListPoint] = useState<any[]>(['Tất cả', ...totalscores]);
  // console.log("percent", percent);
  // console.log("listPoint",listPoint);
  //const [listTypeAdmission, setListTypeAdmission] = useState<any[]>(["TS10 thường","TS10 chuyên"]);
  const [schoolId, setSchoolId] = useState(0);
  const [listPercent, setListPercent] = useState<any[]>(['Tất cả',">80%",80,70,60,"<50%"]);
  // const [listSchoolByKind, setListSchoolByKind] = useState<any[]>(listSchool);
  // const [listSchoolByName, setListSchoolByName] = useState<any[]>(listSchool);
  const [schoolName, setSchoolName] = useState('');
  const [paginationNumber, setPaginationNumber] = useState(0);
  function AddressDistance(distance: number) {
    if (distance < 5) return <p style={{ color: '#000' }}>Gần</p>;
    else if (distance > 10) return <p style={{ color: '#FF0000' }}>Rất xa</p>;
    else return <p style={{ color: '#F18017' }}>Xa</p>;
  }
  const handlePrevPagnum = (isPrev:boolean) => {
    if(isPrev) {
      if(paginationNumber == 0) return;
      setPaginationNumber((prev:any) => prev - 1);
    }
    else {
      if(paginationNumber == Math.ceil(listSchoolNew.length/8) - 1) return;
      setPaginationNumber((prev:any) => prev + 1);
    }
  }
  const handleFilterByPagiNumber = (listSchool:any, pagiNumber:any) => {
    return [...listSchool].slice(pagiNumber*8, pagiNumber*8+8);
  }

  const handleDelete = () => {
    apiAdmission.DeleteHistorySearchHighSchool({historysearchadmissionid: schoolId}).then((result) => {
      dispatch(doGetHistorySearchSlice({
      currentpage: 0,
      limit: 0
      }))
    })
  }

  const handlePinResult = () => {
    apiAdmission.CreateOrUpdatePinHistorySearch({historysearchadmissionid: schoolId}).then((result) => {
      dispatch(doGetHistorySearchSlice({
      currentpage: 0,
      limit: 0
      }))
    })
  }

  const handleunPinResult = () => {
    apiAdmission.UnPinHistorySearch({historysearchadmissionid: schoolId}).then((result) => {
      dispatch(doGetHistorySearchSlice({
      currentpage: 0,
      limit: 0
      }))
    })
  }


  function _ComponentListSchool() {
    if (listSchoolNew?.length === 0 || listSchoolNew[0].highschoolid === -1)
      return (
        <div className="list-school-blank">
          <p>Không tìm thấy kết quả phù hợp</p>
          <p>Vui lòng thử lại</p>
          {/* <button className="btn-primary">Thử lại</button> */}
        </div>
      );
    return (
      <table className={!isCL ? 'table-no-cl' : '' }>
        <tbody>
          
          {handleFilterByPagiNumber([...listSchoolNew], paginationNumber)?.map((school: any, index: any) => {
            //console.log(school?.highschooladdresses[0]?.distance);
            return (
              <tr key={`table-${index}`} style={ school.ispin == 1 ? {background: '#f0f0f0'}:{background: '#ffffff'}}>
                <td>{paginationNumber*8 + index + 1}</td>
                <td>
                  <p
                    className="school-name"
                    onClick={() => {
                      school.linkhighschool && window.open(school.linkhighschool);
                    }}
                  >
                    {school.highschoolname}
                  </p>
                  <p style={{ color: '#2501FF' }}>
                    {school.highschooladdresses && school?.highschooladdresses[0]?.distance && school?.highschooladdresses[0]?.distance != '0'
                      ? `(${school.highschooladdresses[0]?.distance} km)`
                      : ''}
                  </p>
                </td>
                <td>
                  {school.highschooladdresses &&
                    school.highschooladdresses.length > 0 &&
                    school.highschooladdresses[0]?.street +
                      ', ' +
                      school.highschooladdresses[0]?.wardtext +
                      ', ' +
                      school.highschooladdresses[0]?.districttext +
                      ', ' +
                      school.highschooladdresses[0]?.provincetext}
                </td>
                {isCL ? (
                  <>
                    <td>{school.totalscore}</td>
                    <td>{renderAdmissionPercentage(school.ratepercent)}</td>
                  </>
                ) : (
                  <>
                    <td>
                      <InfoDetail
                        school={school}
                        info={school.admissioncondition}
                        id="condition"
                        title="Điều kiện tuyển sinh"
                      />
                    </td>
                    <td>
                      <InfoDetail school={school} info={school.fee} id="fee" title="Học phí" />
                    </td>
                    <td>
                      <p
                        data-tip=""
                        data-for={
                          school.highschooladdresses && 
                          (school.highschoolid?.toString() +
                          school.highschooladdresses[0]?.highschooladdressid.toString())
                        }
                        className="school-info school-highlight"
                      >
                        Xem ngay
                      </p>
                      <ReactTooltip
                        id={
                          school.highschooladdresses && (school.highschoolid.toString() + school.highschooladdresses[0]?.highschooladdressid.toString())
                        }
                        place="bottom"
                        type="dark"
                        effect="float"
                        multiline={true}
                      >
                        <div className={`table-school-desk__error-modal`}>
                          <h3 className="table-school-desk__error-modal__title">{school.titletag}</h3>
                          {school.highlightpoint?.split(/\r?\n/).map((value: string, index: any) => (
                            <div key={index} className="table-school-desk__error-modal__detail">
                              <img src={ListStar} className="table-school-desk__error-modal__list-item"></img>
                              <p>{value}</p>
                            </div>
                          ))}
                        </div>
                      </ReactTooltip>
                    </td>
                  </>
                )}
                {school.highschooladdresses && school.highschooladdresses.length > 0 ? (
                  <td className='distance' style={{ fontSize: '22px', padding: '10px' }}>
                      {AddressDistance(school.highschooladdresses[0]?.distance)}
                      {
                        school.ispin == 0 ? 
                        (
                        <div className='dot' 
                          onClick={() => {
                          setIsShownPopup(!isShownPopup)
                          setSchoolId(school.historysearchadmissionid);
                        }}>
                        </div>
                        ):(
                          <div className='pin' 
                          onClick={() => {
                          setIsShownPopup(!isShownPopup)
                          setSchoolId(school.historysearchadmissionid);
                        }}>
                        </div>
                        )
                      }
                          
                      {
                        (schoolId == school.historysearchadmissionid && isShownPopup) && (
                          <div className='option-popup' ref={popupRef}>
                            {school.ispin == 1 ?
                            (
                              <div className='option-item' 
                                onClick={() =>{
                                  setIsShownPopup(!isShownPopup); 
                                  handleunPinResult()
                                }}>
                                <img src={unPinIcon} alt="" />
                                <p>Bỏ ghim kết quả</p>
                              </div>
                            ):
                            (
                              <div className='option-item' 
                                onClick={() =>{
                                  setIsShownPopup(!isShownPopup); 
                                  handlePinResult()
                                }}>
                              <img src={PinIcon} alt="" />
                              <p>Ghim kết quả</p>
                            </div>
                            )}
                            <hr style={{margin:" 0 2px", borderTop:"0.6px solid #000"}} />
                            <div className='option-item'  
                              onClick={() => {
                                setIsShownPopup(!isShownPopup); 
                                setIsShownModal(!isShownModal)}}
                            >
                              <img src={DeleteIcon} alt="" />
                              <p>Xóa kết quả</p>
                            </div>
                          </div>
                        )
                      }
                  </td>
                ) : (
                  <td></td>
                )}
              </tr>
            );
          })}
        </tbody>
        <ModalDelete
          //ref={modalRef}
          isOpen={isShownModal}
          setIsOpen = {setIsShownModal}
          onClose={() => setIsShownModal(false)}
          handleDelete = {handleDelete}
        />
      </table>
    );
  }

  // const handleKindSchoolCheck = (e: ChangeEvent<HTMLInputElement>) => {
  //   let listSchoolByKind = [...listSchoolNew];
  //   listSchoolByKind = listSchoolByKind.filter((school: any) => school.kindschoolid === e.target.value);
  //   if (e.target.checked) {
  //     setListSchoolNew(prev => [...prev, ...listSchool.filter((school: any) => school.kindschoolid == e.target.value)]);
  //   } else setListSchoolNew(prev => prev.filter((school: any) => school.kindschoolid != e.target.value));
  // };

  // const handleKindSchoolCheck = (kindSchoolid: number) => {
  //   setListSchoolNew(prev => [
  //     ...prev,
  //     ...listSchoolByName.filter((school: any) => school.kindschoolid == kindSchoolid),
  //   ]);
  //   setListSchoolByKind(prev => [...prev, ...listSchool.filter((school: any) => school.kindschoolid == kindSchoolid)]);
  // };

  // const handleKindSchoolUnCheck = (kindSchoolid: number) => {
  //   setListSchoolNew(prev => prev.filter((school: any) => school.kindschoolid != kindSchoolid));
  //   setListSchoolByKind(prev => prev.filter((school: any) => school.kindschoolid != kindSchoolid));
  // };

  // const filterByPercent = (percent: any) => {
  //   if (percent === 'Tất cả') {
  //     setListSchoolNew(listSchoolAllPercent);
  //     setListSchoolPointPercent(listSchoolAllPercent);
  //     setListSchoolAllPoint(listSchoolByName);
  //   } else {
  //     setListSchoolNew(
  //       listSchoolAllPercent.filter((school: any) => school.ratepercent <= percent && school.ratepercent > percent - 5),
  //     );
  //     setListSchoolPointPercent(
  //       listSchool.filter((school: any) => school.ratepercent <= percent && school.ratepercent > percent - 5),
  //     );
  //     setListSchoolAllPoint(
  //       listSchoolByName.filter((school: any) => school.ratepercent <= percent && school.ratepercent > percent - 5),
  //     );
  //   }
  // };

  // const filterByPoint = (point: any) => {
  //   if (point === 'Tất cả') {
  //     setListSchoolNew(listSchoolAllPoint);
  //     setListSchoolPointPercent(listSchoolAllPoint);
  //     setListSchoolAllPercent(listSchoolByName);
  //   } else {
  //     setListSchoolNew(listSchoolAllPoint.filter((school: any) => school.totalscore === point));
  //     setListSchoolPointPercent(listSchool.filter((school: any) => school.totalscore === point));
  //     setListSchoolAllPercent(listSchoolByName.filter((school: any) => school.totalscore === point));
  //   }
  // };

  const filterSchoolByName = (name: any, listSchool: any) => {
    return listSchool.filter((school: any) => {
      return stringToSlug(school.highschoolname.toLowerCase()).indexOf(stringToSlug(name.toLowerCase())) !== -1;
    });
  };

  const handleKindSchoolCheck = (kindSchoolid: number) => {
    setListKindId([...listKindId, kindSchoolid]);
    //setListKindId([...listKindId, ...listKindId.filter((id: any) => kindSchoolid == id)]);
  };

  const handleKindSchoolUnCheck = (kindSchoolid: number) => {
    return setListKindId(listKindId.filter((id: any) => id != kindSchoolid));
  };

  const handleFilterByChexBox = (listSchool: any, listKindId: any) => {
    let listSchookBk = [...listSchool];
    if (isCL) return listSchookBk;
    return listSchookBk.filter((school: any) => {
      return listKindId.indexOf(school.kindschoolid) != -1;
    });
  };

  const handleFilterByPoint = (point: any, listSchool: any) => {
    if (point == 'Tất cả') return listSchool;
    return listSchool.filter((school: any) => school.totalscore == point);
  };

  const handleFilterByPercent = (percent: any, listSchool: any) => {
    if (percent == 'Tất cả') return listSchool;
    if(percent == ">80%") {
      return listSchool.filter((school: any) => {
        return school.ratepercent > 80;
      });
    }
    if(percent == "<50%")  return listSchool.filter((school: any) => {
      return school.ratepercent < 50;
    });
    return listSchool.filter((school: any) => {
      return school.ratepercent >= parseInt(percent) - 10 && school.ratepercent <= parseInt(percent);
    });
  }

  const handleFilterByTypeAddmission = (typeAdmission: any, listSchool: any) => {
    return listSchool.filter((item: any) => {
      return item.typeadmissionid == typeAdmission;
    });
  };
  const handleFilterBySpecialSchoolId = (specialSubjectId:any, listSchool: any) => {
      return [...listSchool].filter((item:any) => {
        return item.subjectid == specialSubjectId || specialSubjectId == 0;
      })
  }


  const handleFilterTotal = () => {
    const schoolByName = filterSchoolByName(schoolName, listSchool);
    //const schoolByKind = handleFilterByChexBox(schoolByName, listKindId);
    const schoolByTypeAmision = isCL ? handleFilterByTypeAddmission(typeAdmission, schoolByName) : [...schoolByName]; // Neu la truong ngoai cong lap xoa bo loc type addmission
    if (isCL) {
      const schoolByPoint = handleFilterByPoint(point, schoolByTypeAmision);
      if(typeAdmission == 2) {
        return handleFilterBySpecialSchoolId(specialSubjectId,handleFilterByPercent(percent, schoolByPoint))
      }
      return handleFilterByPercent(percent, schoolByPoint);
    } else {
      // if(typeAdmission == 2) {
      //   return handleFilterBySpecialSchoolId(specialSubjectId,handleFilterByChexBox(schoolByTypeAmision, listKindId));
      // }
      return handleFilterByChexBox(schoolByTypeAmision, listKindId)  
    }
  };

  const handleSearchSchool = () => {
    setListSchoolNew(handleFilterTotal());
  };

  const renderPercentage = (percent: any) => {
    if (percent == 'Tất cả' || percent == '>80%' || percent == '<50%') return percent;
    return percent - 10 + ' - ' + percent + '%';
  };

  const renderFooterTable = () => {
    if(listSchoolNew && !isLoading) return <div className='footer-table'>
       {listSchoolNew.length > 8?<Pagination numbers = {Math.ceil(listSchoolNew.length/8)} onClick = {(number:any) => {
          setPaginationNumber(number)
      }}  paginationNumber = { paginationNumber} handlePrevPagnum = {handlePrevPagnum }/>:""}
    </div>

  }
  
  const renderListSpecialSubjecrId = () => {
    if(isCL) return [{id: 0, title:"Tất cả môn chuyên"},...subjectspecializeds]
    else return [{id: 0, title:"Tất cả môn chuyên"},...nonsubjectspecializeds]
  } 
  useEffect(() => {
    // if (listPercent.length < 2) {
    //   setListPercent(prev => {
    //     const newLp = prev;
    //     for (let i = 100; i >= 5; ) {
    //       newLp.push(i);
    //       i = i - 5;
    //     }
    //     return newLp;
    //   });
    // }
    handleSearchSchool();
    setPaginationNumber(0);
    setListPoint(['Tất cả', ...totalscores]);
  }, [percent, point, schoolName, listKindId.length, typeAdmission, listSchool, totalscores,specialSubjectId]);

  const handleClear = () => {
    setPoint('Tất cả');
    setPercent('Tất cả');
  };

  return (
    <div className={`table-selected-school-desk`}>
      <div className={isCL ? 'header-wrapper' : 'header-wrapper header-wrapper-nocl'}>
        <div className="type-school">
          <div
            className={isCL ? 'type-school__option type-school__option-selected' : 'type-school__option'}
            onClick={() => {
              setIsCL(true);
              setIsPuclicSchool(true);
              setspecialSubjectId(0);
            }}
          >
            TRƯỜNG CÔNG LẬP
          </div>
          <div
            className={!isCL ? 'type-school__option type-school__option-selected' : 'type-school__option'}
            onClick={() => {
              setIsCL(false);
              setIsPuclicSchool(false);
              setspecialSubjectId(0);
            }}
          >
            TRƯỜNG NGOÀI CÔNG LẬP
          </div>
        </div>
        <div className="header-filter">
          <div className="header-title">
            <img className="header-title__image" src={Youngboiunderthehood} />
            <div className="header-title__content">
              {isCL ? <div className='filter-list'>
                <DropdownTickSelect
                  error={'error'}
                  idActive={idActive}
                  setIdActive={setIdActive}
                  zIndexHeader={100}
                  zIndexBody={99}
                  typeSelect={true}
                  options={listTypeAdmission.map((item: any) => {
                    return { value: item.id, label: item.title };
                  })}
                  handleSelect={(type:any) => {
                    setTypeAdmission(type);
                    handleListpointByTypeAdmission(type);
                  }}
                  onChange={(e: any) => {
                    handleClear();
                  }}
                  indexOption={listTypeAdmission.findIndex((item: { [x: string]: number }) => {
                    return item['id'] === typeAdmission;
                  })}
                  isConvertIdToIndex={true}
                  type={0}
                  id={'point'}
                  className={'height44'}
                />

                {(typeAdmission == 2 && isCL)&& <DropdownTickSelect
                  error={'error'}
                  idActive={idActive}
                  setIdActive={setIdActive}
                  zIndexHeader={100}
                  zIndexBody={99}
                  typeSelect={true}
                  options={renderListSpecialSubjecrId().map((item: any) => {
                    return { value: item.id, label: item.title };
                  })}
                  handleSelect={(type:any) => {
                    setspecialSubjectId(type);
                    //handleListpointByTypeAdmission(type);
                  }}
                  onChange={(e: any) => {
                    handleClear();
                  }}
                  indexOption={renderListSpecialSubjecrId().findIndex((item: { [x: string]: number }) => {
                    return item['id'] === specialSubjectId;
                  })}
                  isConvertIdToIndex={true}
                  type={0}
                  id={'point'}
                  className={'height44'}
                />}
              </div>:<div style={{width: "200px"}}></div>}
             
              <h4>NGUYỆN VỌNG CỦA BẠN</h4>
              <div className='input-search'>
                <Input
                  placeholder="Nhập tên trường"
                  onChange={(e: any) => {
                    setSchoolName(e.target.value);
                  }}
                  className={'height44'}
                />
                <div className="search-btn">
                  <SvgSearch />
                </div>
              </div>
              
            </div>
          </div>
          {!isCL && (
            <div className="school-filter" style={{ gap: '20px' }}>
              <p style={{ fontSize: '16px' }}>*Loại trường</p>

              <div style={{ display: 'flex', gap: '20px' }}>
                {kindSchoolIdList.map((kindschool: any) => (
                  <div key={kindschool.kindschoolid} style={{ display: 'flex' }}>
                    {/* <input
                      type="checkbox"
                      id={kindschool.kindschoolid}
                      name={kindschool.kindschoolid}
                      value={kindschool.kindschoolid}
                      defaultChecked={true}
                      onChange={handleKindSchoolCheck}
                    />
                    <p className="kindschool">{kindschool.kindschool}</p> */}
                    <TableSchoolCheckBox
                      kindschool={kindschool.kindschool}
                      kindschoolid={kindschool.kindschoolid}
                      onCheck={() => handleKindSchoolCheck(kindschool.kindschoolid)}
                      onUnCheck={() => handleKindSchoolUnCheck(kindschool.kindschoolid)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <table className={!isCL ? 'table-no-cl' : ''} >
        <thead>
          <tr style={{ height: '71.2px' }}>
            <th>STT</th>
            <th>Tên trường</th>
            <th>Địa chỉ</th>
            {isCL ? (
              <>
                <th>
                  <div className="drop-select">
                    Tổng điểm
                    <DropdownTickSelect
                      error={'error'}
                      idActive={idActive}
                      setIdActive={setIdActive}
                      zIndexHeader={100}
                      zIndexBody={99}
                      typeSelect={true}
                      options={listPoint.map((item: any) => {
                        return { value: item, label: item };
                      })}
                      handleSelect={setPoint}
                      indexOption={listPoint.findIndex((item: string) => {
                        return item === point;
                      })}
                      isConvertIdToIndex={true}
                      type={0}
                      id={'point'}
                      className={'height44'}
                    />
                  </div>
                </th>
                <th>
                  <div className="drop-select">
                    Khả năng
                    <br />
                    trúng tuyển
                    <DropdownTickSelect
                      error={'error'}
                      idActive={idActive}
                      setIdActive={setIdActive}
                      zIndexHeader={100}
                      zIndexBody={99}
                      typeSelect={true}
                      options={listPercent.map((item: any) => {
                        return { value: item, label: renderPercentage(item) };
                      })}
                      handleSelect={setPercent}
                      onChange={(e: any) => {
                        setPercent(e[0]?.value);
                      }}
                      indexOption={listPercent.findIndex((item: string) => {
                        return item === percent;
                      })}
                      isConvertIdToIndex={true}
                      type={0}
                      id={'percent'}
                      className={'height44'}
                    />
                  </div>
                </th>
              </>
            ) : (
              <>
                <th>Điều kiện tuyển sinh</th>
                <th>Học phí</th>
                <th>Điểm nổi bật</th>
              </>
            )}
            <th>
              Khoảng cách
              <br />
              từ nhà đến trường
            </th>
          </tr>
        </thead>
      </table>
      <div className={`${(listSchoolNew?.length === 0) ? "" : "no-item"}` + `${(listSchoolNew?.length <= 8 ) ? " noscroll" : ""}` + " list-school" } >{isLoading?<Loader />:<_ComponentListSchool></_ComponentListSchool>}</div>
      {renderFooterTable()}
    </div>
  );
};
