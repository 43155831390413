import axios, { AxiosError, AxiosResponse } from 'axios';
import { createHeaderAPI, responseAPI, responseErrorAPI } from './axiosBase';

const axiosSystemAPI = axios.create(
  createHeaderAPI(process.env.URL_SNET_API || '', process.env.ADMIN_SYSTEM_TOKEN || ''),
);

axiosSystemAPI.interceptors.response.use(
  (res: AxiosResponse) => responseAPI(res),
  (error: AxiosError) => responseErrorAPI(error),
);

export default axiosSystemAPI;
