import './ContactMobile.scss';
import HeaderImage from '../../../assets/img/draft/image-title-3.png';
import Youngman3 from '../../../assets/img/draft/youngman3.png';
import { SvgCompany } from '../../../assets/svg/SvgCompany';
import { SvgFB } from '../../../assets/svg/SvgFB';
import { SvgPhone } from '../../../assets/svg/SvgPhone';
export default function ContactMobile() {
    return <div className="contact-mobile" id='contact-mobile'>
        <div className="contact-mobile__wrap">
            <div className='contact-mobile-header'>
                <img src={HeaderImage} className='contact-mobile-header__image' />
            </div>

            <div className='body'>
                <div className='body__content'>
                    <div className='body__content--wrap'>
                        <img src={Youngman3} className='body__content--wrap__image' />
                        <p className='body__content--wrap__title'>Sở Giáo dục và Đào tạo<br />Thành phố Hồ Chí Minh<br />Phòng Khảo thí và Kiểm định<br />chất lượng giáo dục</p>
                    </div>
                    <div className='body__content__divide'></div>
                    <div style={{paddingLeft:"5px", paddingBottom:"15px"}}>
                        <p className='body__content__body body__content__body__bold'>Hỗ trợ kỹ thuật những vấn đề liên quan tới website liên hệ:</p>
                        <div className='phone-yoot'>
                            <p ><SvgPhone width='4.3478vw' height='4.3478vw' /></p>
                            <a>Số đường dây  nóng: 1800 888 887 - Ms. Mỹ Linh (YOOT): 077 512 4801</a>
                        </div>
                        <p style={{marginTop:"10px", marginBottom:"5px"}}className='body__content__body body__content__body__bold'>Mọi thắc mắc về chuyên môn liên hệ:</p>
                        <div className='phone-yoot'>
                            <p ><SvgPhone width='4.3478vw' height='4.3478vw' /></p>
                            <a>Số đường dây  nóng: 028 38 226 796</a>
                        </div>
                        <div className='phone-yoot'>
                            <p><SvgCompany width='4.3478vw' height='4.3478vw' /> </p>
                            <a style={{fontWeight:"600"}} href='https://khaothi.hcm.edu.vn/homehcmmb' target="_blank" className='link-contact'>https://khaothi.hcm.edu.vn/homehcmmb</a>
                        </div>
                        <div className='phone-yoot' >
                            <p ><SvgFB width='4.3478vw' height='4.3478vw' /></p>
                            <a style={{fontWeight:"600"}} href='https://www.facebook.com/profile.php?id=100083162059136&mibextid=ZbWKwL' target="_blank" className='phone-2 link-contact'>Fanpage: Phòng Khảo thí và Kiểm định<br />chất lượng giáo dục TPHCM</a>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
}