import { useDispatch, useSelector } from 'react-redux';
import { TableSelectedSchoolDesk } from '../../components/SelectedResultPage/TableSelectedSchoolDesk';
import Logo from '../../assets/img/draft/logo-yoot.png';
import './SelectedResultPage.scss';
import { ArrowLeft } from '../../assets/svg/ArrowLeft';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { doGetHistorySearchSlice } from '../../redux/slice/appSlice/getHistorySearchSlice';

export const SelectedResultPageDesk = () => {
  window.scrollTo(0, 0);
  const { publicHighSchoolsHistory, nonPublicHighSchoolsHistory, totalscoreselections, isLoading, nearesttypeadmissionid, nonsubjectspecializeds, subjectspecializeds } = useSelector(
    (state: any) => state.getHistorySearchSlice,
  );
  const [totalscores, setTotalScore] = useState([]);
  
  const handleListpointByTypeAdmission = (type:any) => {
    for(let item of totalscoreselections) {
      if(item.typeadmissionid == type) {
        return setTotalScore(item.totalscores)
      }
    }
    return setTotalScore([])
  }
  
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(doGetHistorySearchSlice({
      currentpage: 0,
      limit: 0
    }));
  }, []);

  useEffect(() => {
    if(!isLoading) {
     handleListpointByTypeAdmission(nearesttypeadmissionid);
    }
  },[isLoading])

  const navigate = useNavigate();
  const [isPuclicSchool, setIsPuclicSchool] = useState(true);

  const HandleBackClick = () => {
    navigate('/');
  };

  return (
    <div className="selected-result-page">
      <div className="header">
        <div className="header__logo" onClick={() => navigate('/')}>
          <img src={Logo} />
        </div>
        <p>
          KẾT QUẢ
          <br />
          CHỌN TRƯỜNG
        </p>
        <div className="header__back" onClick={HandleBackClick}>
          <div className="back-logo">
            <ArrowLeft color="black" />
          </div>
          QUAY LẠI
        </div>
      </div>
      <TableSelectedSchoolDesk
        listSchool={isPuclicSchool ? publicHighSchoolsHistory : nonPublicHighSchoolsHistory}
        nearesttypeadmissionid = {nearesttypeadmissionid}
        setIsPuclicSchool={setIsPuclicSchool}
        totalscores= {totalscores}
        isLoading = {isLoading}
        handleListpointByTypeAdmission = {(type:any) => {
          handleListpointByTypeAdmission(type)
        }}
        nonsubjectspecializeds = {nonsubjectspecializeds}
        subjectspecializeds = {subjectspecializeds}
      />
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* <ModalAspiration/> */}
      {/* <HeaderSearchSample/> */}
      {/* <TableSchoolDesk />        */}
    </div>
  );
};
