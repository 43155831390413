import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiAdmission } from "../../../services/aixos/apiAdmission";

export const doGetListHistorySearch = createAsyncThunk(
    'history@post/historySearch',
    async () => {
        const result = await apiAdmission.GetListHistorySearch();
        return result.data;
    }
)


interface IHistorySearch {
    searchadmissionid: number;
    createdate: string;
    typeadmissionid: number;
    typeadmissiontext: string;
    priorityobjectid: number;
    priorityobjecttext: string;
    priorityobjectpoint: number;
    useranonymousid: number;
    fullname: string;
    phone: string;
    email: string;
    phoneparent: string;
    secondaryschoolid: number;
    secondaryschoolname: string;
    scoremath: number;
    scoreliterature: number;
    scorelanguage: number;
    subjectid: number;
    subjecttext: string;
    scoresubject: number;
    street: string;
    wardid: number;
    wardtext: string;
    districtid: number;
    districttext: string;
    provinceid: number;
    provincetext: string;
    highschools: IHighSchool[];
}

const initialState = { 
    listHistorySearch: { } as IHistorySearch,
    isLoading: false,
    error: {}
}

const historySlice = createSlice({
    name: 'history@',
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(doGetListHistorySearch.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(doGetListHistorySearch.fulfilled, (state, action) => {
            state.listHistorySearch = action.payload.content.historysearchs;
            state.isLoading = false;
        })
        builder.addCase(doGetListHistorySearch.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        })
    },
})

const {reducer: historyReducer, actions } = historySlice
export default historyReducer;