import React from "react";
import { XIcon } from "../../../assets/svg/XIcon";
import './ObjectTag.scss'

interface IObjectTag {
    id: number;
    label: string;
    onDelete: (id: number) => void; 
}

export const ObjecTag: React.FC<IObjectTag> = ({id, label, onDelete}) => {
    return (
        <div key={id} className="object-tag">
            <p className="object-tag__label">{label}</p>
            <div 
                className="object-tag__delete"
                onClick={() => onDelete(id)}
            >
                <XIcon/>
            </div>
        </div>
    )
}