import axiosCas10NoToken from './axiosCas10NoToken';
import axiosCas10 from './axiosCas10';

export const apiAdmission = {
  CreateSearchAdmission: (params: IParamsCreateSearchAdmission) => {
    const url = 'Admission/CreateSearchAdmission';
    return axiosCas10.post(url, params);
  },
  GetResultSearch: (params: IParamsCreateSearchAdmission) => {
    const url = 'Admission/GetResultSearch';
    return axiosCas10.post(url, params);
  },
  CreateHistorySearchAdmission: (params: IParamsCreateHistorySearchAdmission) => {
    const url = 'Admission/CreateHistorySearchAdmission';
    return axiosCas10.post(url, params);
  },
  GetHistorySearchAdmission: (param:any) => {
    const url = 'Admission/GetHistorySearch';
    return axiosCas10.post(url, param);
  },
  GetListHistorySearch : () => {
    const url = 'Admission/GetListHistorySearch';
    return axiosCas10NoToken.post(url);
  },
  GetFillSearch: (params: IParamsGetFillSearch) => {
    const url = 'Admission/GetFillSearch';
    return axiosCas10NoToken.post(url, params);
  },
  CreateConsultion: (params: IParamsCreateConsultion) => {
    const url = 'Admission/CreateConsultion';
    return axiosCas10NoToken.post(url, params);
  },
  CheckUserWithPhone: (params: IParamsCheckUserWithPhone) => {
    const url = 'Admission/CheckUserWithPhone';
    return axiosCas10NoToken.post(url, params);
  },
  UpdateIsChooseHighSchool: (params: any) => {
    const url = 'Admission/UpdateIsChooseHighSchool';
    return axiosCas10.post(url, params);
  },

  DeleteHistorySearchHighSchool: (params: any) => {
    const url = 'Admission/DeleteHistorySearchHighSchool';
    return axiosCas10.post(url, params);
  },

  CreateOrUpdatePinHistorySearch: (params: any) => {
    const url = 'Admission/CreateOrUpdatePinHistorySearch';
    return axiosCas10.post(url, params);
  }, 
  UnPinHistorySearch: (params: any) => {
    const url = 'Admission/UnPinHistorySearch';
    return axiosCas10.post(url, params);
  },
  getNumUserSearch: () => {
    const url = 'Admission/GetNumUserSearch';
    return axiosCas10NoToken.get(url);
  },
  getListNews: (params: any) => {
    const url = 'News/GetNewsList';
    return axiosCas10NoToken.post(url, params);
  },
  getOneNews: (params: any) => {
    const url = 'News/GetOneNews';
    return axiosCas10NoToken.post(url, params);
  }
  
};
