
import { SelectedResultPageDesk } from './SelectedResultPageDesk';
import { SelectedResultPageMobile } from './SelectedResultPageMobile';
import { useResponsive } from '../../hooks';
export const SelectedResultPage = () => {
  const { isFromMobile } = useResponsive()
  return (
    <>
      {isFromMobile ? <SelectedResultPageDesk />: <SelectedResultPageMobile />}
    </>
  );
};
