import React from 'react';
import { MdPlayArrow, MdPause } from 'react-icons/md';
import { BsFillVolumeDownFill, BsFillVolumeMuteFill, BsFillVolumeUpFill, BsPip } from 'react-icons/bs';
import { FiMaximize } from 'react-icons/fi';
import './ProgressBarVideo.scss';

export const ProgressBarVideo: React.FC<IProgressBarVideo> = ({ id }) => {
  return (
    <div className="progress-video">
      <div className="progress-video__bar" id={`progress-video-${id}`}>
        <div className="progress-video__time progress-video__time--buffer" id={`buffer-${id}`} />
        <div className="progress-video__timimg" id={`timimg-${id}`} />

        <input id={`seek-${id}`} type="range" max="1" min="0" step="0.01" className="progress-video__time" />
      </div>
      <div className="progress-video__main">
        <div className="progress-video__orther">
          <div id={`icon-play-${id}`}>
            <div id={`pause-${id}`}>
              <MdPause size={24} color={'white'} className="progress-video__icon" />
            </div>
            <div id={`play-${id}`}>
              <MdPlayArrow size={24} color={'white'} className="progress-video__icon" />
            </div>
          </div>
          <div className="progress-video__vol">
            {/* <div id={`volumedown-fill-${id}`}>
              <BsFillVolumeDownFill size={24} color={'white'} className="progress-video__icon" />
            </div>

            <div id={`volumemute-fill-${id}`}>
              <BsFillVolumeMuteFill size={24} color={'white'} className="progress-video__icon" />
            </div> */}
            <div id={`volumeup-fill-${id}`}>
              <BsFillVolumeUpFill size={24} color={'white'} className="progress-video__icon" />
            </div>

            <input id={`volume-${id}`} type="range" max="1" min="0" step="0.01" className="volume" />
          </div>
          <div className="progress-video__timing" id={`time-video-${id}`}>
            00:00 / 00:00
          </div>
        </div>

        <div className="progress-video__aciton">
          <div id={`pip-${id}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <BsPip size={20} color={'white'} className="progress-video__icon progress-video__icon--pip" />
          </div>
          <div id={`maximize-${id}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FiMaximize size={22} color={'white'} className="progress-video__icon" />
          </div>
        </div>
      </div>
    </div>
  );
};
