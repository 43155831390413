import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { doGetReportDetail } from "../../../redux/slice/apiSlice/getReport";
import './TableReportDetail.scss'
import { Calendar } from "../../common";
import { useState } from 'react';
import { Button } from "../../common";
import { DropdownTotalTime } from "../../common/DropdownTotalTime/DropdownTotalTime";
import moment from 'moment';


export const TableReportDetail: React.FC<any> = () => {

    const dispatch = useAppDispatch();
    const params = useParams();
    const reportDetail = useAppSelector((state) => state.getReportReducer.reportDetail)

    const [isShowCalendar, setIsShowCalendar] = useState<boolean>(false);
    //========== Hard code start Date - end Date ===========
    const valueStart = new Date();
    valueStart.setDate(1);
    valueStart.setMonth(valueStart.getMonth());
    valueStart.setHours(0o0, 0o0, 0o0);
    const valueEnd = new Date();
    valueEnd.setHours(23, 59, 59);
    const [valueCalendarStart, setValueCalendarStart] = useState<Date>(valueStart);
    const [valueCalendarEnd, setValueCalendarEnd] = useState<Date>(valueEnd);

    //================================================

    const handleSelectCalendarStart = (time: Date) => {
        time.setHours(0o0, 0o0, 0o0);
        setValueCalendarStart(time);
        // setValueCalendarStartSelected(time);
      };
      const handleSelectCalendarEnd = (time: Date) => {
        time.setHours(23, 59, 59);
        setValueCalendarEnd(time);
        // setValueCalendarEndSelected(time);
      };
      const compare_date = (date1: Date, date2: Date) => {
        if (date1.getTime() <= date2.getTime()) {
          return true;
        } else if (date1.getTime() > date2.getTime()) {
          return false;
        }
      };
      const handleClickShowCalendar = (e: any) => {
        setIsShowCalendar(isShowCalendar => !isShowCalendar);
      };
      let validateTime = compare_date(valueCalendarStart, valueCalendarEnd);

      //============================================
    useEffect(() => {
        if (!params.id) return
        dispatch(doGetReportDetail({
            userconsultantid: parseInt(params.id),
            fromdate: moment(valueStart).format('YYYY-MM-DD') + " " + "00:00:00",
            todate:moment(valueEnd).format('YYYY-MM-DD') + " " + "23:59:59"
        }))
    }, [])

    const renderRow = (id: number, index: number, name: string, totalnumstudent: number, totalusersearch: number, percentsearchsecondaryschool: number) => {
        return (
            <tr key={id}>
                <td> {index + 1} </td>
                <td> {name} </td>
                <td> {totalnumstudent} </td>
                <td> {totalusersearch} </td>
                <td> {percentsearchsecondaryschool}% </td>
            </tr>
        )
    }

    const renderData = () => {
        return reportDetail?.reportsecondaryschools?.map((item: any, index: number) => renderRow(item.id, index, item.name, item.totalnumstudent, item.totalusersearch, item.percentsearchsecondaryschool))
    }


    return (
        <div className="tb-report-detail">
            <p className="tb-report-detail__title">BÁO CÁO CHI TIẾT</p>
            <div className="tb-report-detail__consulant">Thầy/Cô tư vấn: {reportDetail?.nameuserconsultant}</div>
            <div style={{ position: 'relative' }}>
          <div onClick={e => handleClickShowCalendar(e)}>
            <DropdownTotalTime
              id="wishtimeOption"
              onChange={(value: any) => {
                // setMessErrorWishTime('');
                // handleClickShowCalendar(value);
              }}
              // onClick = {(e) => handleClickShowCalendar(e)}
              // onClick = {() => {}}
              className="total-information__select"
              classNameHeader="time-select"
              classNameBody="time-body"
              valueCalendarStart={valueCalendarStart}
              valueCalendarEnd={valueCalendarEnd}
              options={[]}
            />
          </div>

          {isShowCalendar ? (
            <div className="container-calendar">
              <div className="calendar-section">
                <Calendar
                  isBeforeNow={true}
                  value={valueCalendarStart}
                  // constraintDateBefore={new Date()}
                  constraintDateAfter={new Date()}
                  isShow={true}
                  onSelect={handleSelectCalendarStart}
                  titleStart={true}
                  validateTime={validateTime}
                />

                <Calendar
                  isBeforeNow={true}
                  value={valueCalendarEnd}
                  // constraintDateBefore={undefined}
                  constraintDateAfter={new Date()}
                  isShow={true}
                  onSelect={handleSelectCalendarEnd}
                  titleStart={false}
                  validateTime={validateTime}
                />
              </div>

              <p className="error-message" style={{ display: validateTime ? 'none' : 'block' }}>
                *Ngày bắt đầu phải trước ngày kết thúc!
              </p>

              <div className="container-calendar__button">
                <Button
                  type="button"
                  onClick={(e: any) => {
                    if (validateTime) {
                      handleClickShowCalendar(e);
                      if(params.id) dispatch(doGetReportDetail({
                        userconsultantid: parseInt(params.id),
                        fromdate: moment(valueCalendarStart).format('YYYY-MM-DD') + " " + "00:00:00",
                        todate:moment(valueCalendarEnd).format('YYYY-MM-DD') + " " + "23:59:59"
                    }))
                    }
                  }}
                >
                  Xem
                </Button>
              </div>
            </div>
          ) : null}
        </div>
            <table className="tb-report-detail__table">
                <thead>
                    <tr className=''>
                        <th>STT</th>
                        <th>Tên trường</th>
                        <th>Tổng số học sinh</th>
                        <th>Tổng số học sinh dùng ứng dụng</th>
                        <th>Tỉ lệ (%)</th>
                    </tr>
                </thead>
                <tbody>
                    {renderData()}
                    <tr className="tb-report-detail__table__sum-row" key='sum'>
                        <td colSpan={2}>Tổng cộng</td>
                        <td>{reportDetail.sumnumstudent}</td>
                        <td>{reportDetail.sumusersearch}</td>
                        <td>{reportDetail.sumpercentsearch}%</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}