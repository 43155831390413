import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { CalendarIcon } from '../../../assets/svg/calendarIcon';
import { eventListener } from '../../../constants';
import './DropdownTotalTime.scss';

export const DropdownTotalTime: React.FC<any> = ({
  id,
  options,
  onChange,
  placeholder,
  className,
  isOver,
  classNameHeader,
  classNameBody,
  valueCalendarStart,
  valueCalendarEnd,
  value,
  // onClick,
}) => {
  const [isDownMenu, setIsDownMenu] = useState(false);
  const [numberSelect, setNumberSelect] = useState(-1);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isDownMenu) {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsDownMenu(false);
        }
      };
      document.addEventListener(eventListener.CLICK, handleClickOutside);
      return () => {
        document.removeEventListener(eventListener.CLICK, handleClickOutside);
      };
    }
  }, [ref, isDownMenu]);

  const handleSelectItem = (i: number, value: any) => {
    setNumberSelect(i);
    if (i === 1) setIsDownMenu(false);
    return onChange(value);
  };
  useEffect(() => {
    if (value === 1) {
      setNumberSelect(1);
    }
  }, [value]);

  useEffect(() => {
    if (valueCalendarStart && valueCalendarEnd && value !== 1) {
      setNumberSelect(0);
    }
  }, [valueCalendarStart, valueCalendarEnd]);

  return (
    <div className={`total-dropdown-time ${className ? className : ''}`}>
      <div
        id={id}
        className={`total-dropdown-time__header ${classNameHeader}`}
        onClick={() => setIsDownMenu(true)}
        style={{ zIndex: isDownMenu ? 50 : 'unset' }}
      >
        <div className="total-dropdown-time__show">
          {numberSelect === -1 ? (
            <span className="total-dropdown-time__placeholder">{""}</span>
          ) : (
            <span>
              {numberSelect === 1
                ? options[1].label
                : (valueCalendarStart || valueCalendarEnd)
                ? moment(valueCalendarStart).format('DD/MM/YYYY') +
                  ' - ' +
                  moment(valueCalendarEnd).format('DD/MM/YYYY')
                : ''}
            </span>
          )}
          
        </div>
        <div className="total-dropdown-time__icon">
          {/* <FiChevronDown size={22} color="white" /> */}
          <CalendarIcon/>
        </div>
      </div>
      {isDownMenu && (
        <div
          className={
            isOver
              ? 'total-dropdown-time__body total-dropdown-time__body--over'
              : `total-dropdown-time__body ${classNameBody}`
          }
          style={{ zIndex: isDownMenu ? 49 : 'unset' }}
          ref={ref}
        >
          {options.length > 0
            ? options.map((item: any, i: number) => {
                return (
                  <div
                    className={`total-dropdown-time__item ${
                      numberSelect === 1 && i === 1 ? 'total-dropdown-time__item--red' : ''
                    }`}
                    key={i}
                    onClick={() => handleSelectItem(i, item.value)}
                  >
                    {item.label}
                  </div>
                );
              })
            : ''}
        </div>
      )}
    </div>
  );
};
