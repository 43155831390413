import React from "react";
import { CheckedBox } from "../../../../assets/svg/CheckedBox";
import { UnCheckedBox } from "../../../../assets/svg/UnCheckedBox";
import { CheckInput } from "../../CheckInput/CheckInput";
import { doClearWhenChooseNopriority } from "../../../../redux";
import { useDispatch } from "react-redux";
import "./RowData.scss"

interface IRowData {
    id: number, 
    index: number, 
    title: string, 
    code: string, 
    checked: boolean, 
    onChecked: () => void, 
    onUnChecked: () => void
    isChosenNoPriority?: boolean;
}

export const RowData: React.FC<IRowData> = ({id, index, title, code, checked, onChecked, onUnChecked, isChosenNoPriority}) => {
    const dispatch = useDispatch();
    const renderCheckedBox = () => {
        return (
            <div 
                className="checkedkbox"
                onClick={(e) => {
                    e.stopPropagation()
                    if(id == 6) {
                        dispatch(doClearWhenChooseNopriority({}));
                        if (checked) onUnChecked()
                        else onChecked()
                    }
                    else {
                        if(!isChosenNoPriority) {
                            if (checked) onUnChecked()
                            else onChecked()
                        }
                    }
                }}
            >
                {checked ? <CheckedBox/> : <UnCheckedBox/>}
            </div>
        )
    }

    return (
        <tr
            key={id}
            onClick={(e) => e.stopPropagation()}
        >
            <td>{index + 1}. {title}</td>
            <td>{code}</td>
            <td>{renderCheckedBox()}</td>
        </tr>
    )
}