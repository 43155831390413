import React from 'react';

export const SvgFB = ({width, height}:any) => {
  return (
   

<svg width={width}
      height={height}  viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.0443 6.51629C13.0443 2.9193 10.125 0 6.52801 0C2.93102 0 0.0117188 2.9193 0.0117188 6.51629C0.0117188 9.67017 2.25332 12.2962 5.22475 12.9023V8.47118H3.92149V6.51629H5.22475V4.88722C5.22475 3.62957 6.24781 2.60652 7.50545 2.60652H9.13453V4.5614H7.83127C7.47287 4.5614 7.17964 4.85464 7.17964 5.21303V6.51629H9.13453V8.47118H7.17964V13C10.4704 12.6742 13.0443 9.89824 13.0443 6.51629Z" fill="#401E96"/>
</svg>


  );
};
