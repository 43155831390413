import axiosCas10NoToken from './axiosCas10NoToken';

export const apiGoogleMap = {
  GetGoogleMap: (params: any) => {
    const url = 'GoogleMap/GetGoogleMap';
    return axiosCas10NoToken.post(url, params);
  },
  GetCoordinates: (params: {text: string}) => {
    const url = 'GoogleMap/GetCoordinates';
    return axiosCas10NoToken.post(url, params);
  },
};
