import React from "react";
import './NotiOverload.scss'
import OverloadImg from '../../../assets/img/draft/objects.png';
export const NotiOverload: React.FC<any> = ({}) => {
    return (
        <div  className="noti-overload">
            <div className="noti-overload__img">
                <img src = {OverloadImg}/>
            </div>
            <div style={{height:"80px"}}></div>
            <div className="noti-overload__content">
                Hệ thống đang bị quá tải do nhiều người truy cập cùng lúc. Các bạn vui lòng quay lại sau nhé. <br /><br />YOOT xin lỗi vì sự bất tiện này.
            </div>
        </div>
    )
}