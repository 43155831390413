export const CalendarIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3628 2.72736H14.4537V0.90918H12.6356V2.72736H5.36284V0.90918H3.54466V2.72736H2.63556C1.62647 2.72736 0.826474 3.54554 0.826474 4.54554L0.817383 17.2728C0.817383 17.755 1.00894 18.2175 1.34992 18.5585C1.69089 18.8994 2.15335 19.091 2.63556 19.091H15.3628C16.3628 19.091 17.181 18.2728 17.181 17.2728V4.54554C17.181 3.54554 16.3628 2.72736 15.3628 2.72736ZM15.3628 17.2728H2.63556V8.18191H15.3628V17.2728ZM15.3628 6.36372H2.63556V4.54554H15.3628V6.36372ZM6.27193 11.8183H4.45375V10.0001H6.27193V11.8183ZM9.90829 11.8183H8.09011V10.0001H9.90829V11.8183ZM13.5447 11.8183H11.7265V10.0001H13.5447V11.8183ZM6.27193 15.4546H4.45375V13.6365H6.27193V15.4546ZM9.90829 15.4546H8.09011V13.6365H9.90829V15.4546ZM13.5447 15.4546H11.7265V13.6365H13.5447V15.4546Z"
        fill="white"
      />
    </svg>
  );
};
