import './ListNews.scss';
import { ArrowLeft } from '../../assets/svg/ArrowLeft';
import Logo from '../../assets/img/draft/logo-yoot.png';
import { useNavigate } from 'react-router-dom';
import { ItemNews } from './ItemNews';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doGetListNews } from '../../redux/slice/apiSlice/getListNews';
import { ModalPopupNews } from '../../components/common/ModalPopupNews/ModalPopupNews';
import { useResponsive } from '../../hooks';
import { doGetOneNews } from '../../redux/slice/apiSlice/getOneNews';
export const ListNews = () => {
  window.scrollTo(0,0);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newsid, setNewsid] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { isFromMobile }  = useResponsive();
  
  useEffect(() => {
    dispatch(
      doGetListNews({
        typenewsid: 0,
        ishot: 0,
        isremarkable: 0,
        issave: 0,
        findtext: '',
        currentpage: '',
        currentdate: '',
        limit: '',
      }),
    );
  }, []);
  const { dataFull, isLoading } = useSelector((state: any) => state.getListNewsReducer);

  const handleSelectNewsItem = (newsid:number) => {
    dispatch(doGetOneNews({
      newsid: newsid
    }))
    navigate(`/news-detail/${newsid}`)
  }

  return (
    <div className="list-news-page">
      <div className="header">
        {isFromMobile ? <div className="header__logo" onClick={() => navigate('/')}>
          <img src={Logo} />
        </div>:<div className="header__back" onClick={() => navigate('/')}>
          <div className="back-logo">
            <ArrowLeft color="black" />
          </div>
          QUAY LẠI
        </div>}
        {isFromMobile && <p>
          TIN TỨC
        </p>}
        {isFromMobile ? <div className="header__back" onClick={() => navigate('/')}>
          <div className="back-logo">
            <ArrowLeft color="black" />
          </div>
          QUAY LẠI
        </div>:<div className="header__logo" onClick={() => navigate('/')}>
          <img src={Logo} />
        </div>}
      </div>
      {!isFromMobile && <p>TIN TỨC</p>}
      <div className='list-news'>
        {
          dataFull?.map((item:any, index:any) => {
            return  <ItemNews img  = {item.banner} title = {item.title} shortcontent = {item.shortcontent} index = {index} onClick={() => {
              setNewsid(item.id);
              handleSelectNewsItem(item.id)
            }}/>
          })
        }
        {/* <ItemNews />
        <ItemNews />
        <ItemNews />
        <ItemNews />
        <ItemNews />
        <ItemNews /> */}
      </div>

      {isOpen && <ModalPopupNews
        newsid={newsid}
        isOpen={isOpen}
        /* setIsShow = {setIsOpen} */ onClose={() => {
          setIsOpen(false);
        }}
      />}
    </div>
  );
};
