import axiosCas10NoToken from './axiosCas10NoToken';

export const apiGeneral = {
  GetTypeAdmission: () => {
    const url = 'General/GetTypeAdmission';
    return axiosCas10NoToken.get(url);
  },
  GetListPriorityObject: (params: IParamsNormalApi) => {
    const url = 'General/GetListPriorityObject';
    return axiosCas10NoToken.post(url, params);
  },
  GetListSubject: (params: IParamsGetListSubject) => {
    const url = 'General/GetListSubject';
    return axiosCas10NoToken.post(url, params);
  },
  GetListSecondarySchool: (params: IParamsGetListSecondarySchool) => {
    const url = 'General/GetListSecondarySchool';
    return axiosCas10NoToken.post(url, params);
  },
  GetListProvince: (params: IParamsNormalApi) => {
    const url = 'General/GetListProvince';
    return axiosCas10NoToken.post(url, params);
  },
  GetListDistrict: (params: IParamsGetListDistrict) => {
    const url = 'General/GetListDistrict';
    return axiosCas10NoToken.post(url, params);
  },
  GetListWard: (params: IParamsGetListWard) => {
    const url = 'General/GetListWard';
    return axiosCas10NoToken.post(url, params);
  },
};
