import { NewsItem } from './NewsItem/NewsItem';
import './NewsList.scss';
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { doGetListNews } from '../../../redux/slice/apiSlice/getListNews';
import { ModalPopupNews } from '../../common/ModalPopupNews/ModalPopupNews';
import { useResponsive } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { doGetOneNews } from '../../../redux/slice/apiSlice/getOneNews';

interface propNewsList {
  relativeNews?:any,
  isRelative?: boolean
}

export const NewsList:React.FC<propNewsList> = ({relativeNews, isRelative}) => {
  const dispatch = useDispatch();
  const [newsid, setNewsid] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { isFromMobile } = useResponsive();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(
      doGetListNews({
        typenewsid: 0,
        ishot: 0,
        isremarkable: 0,
        issave: 0,
        findtext: '',
        currentpage: '',
        currentdate: '',
        limit: '',
      }),
    );
  }, []);
  const { data, isLoading, dataFull} = useSelector((state: any) => state.getListNewsReducer);
  const [settings, setSetting] = useState({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    rows: 2,
    autoplay: true,
    arrows: false
  })

  const [widthList, setWidthList] = useState("300px")
  const settings_mobile = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    autoplay: true,
    arrows: false
  };
  const settings_1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    autoplay: true,
    arrows: false
  };

  const settings_2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    rows: 1,
    autoplay: true,
    arrows: false
  };
  const settings_3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    rows: 1,
    autoplay: true,
    arrows: false
  };

  const settings_4 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    rows: 1,
    autoplay: true,
    arrows: false
  };
  const settings_5 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    rows: 2,
    autoplay: true,
    arrows: false
  };
  const settings_7 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    rows: 2,
    autoplay: false,
    arrows: false
  };

  const settings_default = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    rows: 2,
    autoplay: true,
    arrows: false
  };
  const NewsListFull = relativeNews ? relativeNews : dataFull
  // const NewsList = relativeNews ? relativeNews : data

  useEffect(() => {
    if(isFromMobile) {
        if(NewsListFull.length == 1) {
            setSetting({...settings_1})
            setWidthList("300px");
        }
        else if(NewsListFull.length == 2) {
            setSetting({...settings_2})
            setWidthList("600px");
        }
        else if(NewsListFull.length == 3) {
            setSetting({...settings_3})
            setWidthList("900px");
        }
        else if(NewsListFull.length == 4) {
            setSetting({...settings_4})
            setWidthList("1100px");
        }
        else if(NewsListFull.length == 5){
            setSetting({...settings_5})
            setWidthList("900px");
        }
        else if(NewsListFull.length == 6) {
            setSetting({...settings_5})
            setWidthList("900px");
        }
    
        else if(NewsListFull.length > 6) {
            setSetting({...settings_7})
            setWidthList("1100px");
        }
    }
    else {
        setSetting({...settings_mobile, dots: false, arrows: true})
        setWidthList("unset");
    }
   
  },[NewsListFull.length])
  
//   useEffect(() => {

//   },[data.length])

  const handleSelectNewsItem = (newsid:number) => {
    dispatch(doGetOneNews({
      newsid: newsid
    }))
    navigate(`/news-detail/${newsid}`)
  }

  return (
    <div className="news-list" id="news-list" style={{"width":widthList}}>
      <p className="label">TIN TỨC {isRelative && "LIÊN QUAN"}</p>
      {isFromMobile ? <Slider {...settings} >
        {NewsListFull?.map((item: any, index: any) => {
          return (
            <NewsItem
              img={item.banner}
              title={item.title}
              shortContent ={item.shortcontent}
              key={index}
              onClick={() => {
                setNewsid(item.id);
                handleSelectNewsItem(item.id);
              }}
              isFull = {true}
            />
          );
        })}
      </Slider>:<div className='news-list_content'>
      {NewsListFull?.map((item: any, index: any) => {
          return (
            <NewsItem
              img={item.banner}
              title={item.title}
              shortContent ={item.shortcontent}
              key={index}
              onClick={() => {
                setNewsid(item.id);
                // setIsOpen(true);
                handleSelectNewsItem(item.id);
              }}
              isFull = {NewsListFull.length === 1}
            />
          );
        })} 
        </div>}
      <p className='see-all' onClick={() => {
        navigate('/list-news')
      }}>Xem tất cả</p>
      {isOpen && <ModalPopupNews
        newsid={newsid}
        isOpen={isOpen}
        /* setIsShow = {setIsOpen} */ onClose={() => {
          setIsOpen(false);
        }}
      />}
    </div>
  );
};
