import { createSlice } from '@reduxjs/toolkit';

type TRecommendation = {
  listResultSchool: Array<ISchoolInfo>;
};

const initialState = {
  listResultSchool: [],
} as TRecommendation;

export const recommendationSlice = createSlice({
  name: 'recommendationPersist',
  initialState: initialState,
  reducers: {
    doSetListRecommendations(state, action) {
      state.listResultSchool = action.payload.listResultsSchool;

      // console.log(action.payload.listResultsSchool);
    },

    doClearListRecommendations(state) {
      state.listResultSchool = [];
    },
  },
});

const { actions, reducer } = recommendationSlice;
export const { doSetListRecommendations, doClearListRecommendations } = actions;
export default reducer;
