export const cumulativeOffsetElement = (element: any): { top: number; left: number } => {
  let top = 0,
    left = 0;
  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while (element);

  return {
    top: top,
    left: left,
  };
};

export function debounce<Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number,
): (...args: Params) => void {
  let timer: NodeJS.Timeout;
  return (...args: Params) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}

export const detectTouchScreen = () => {
  return 'ontouchstart' in window;
};

export const formatTimeVideo = (timeInSeconds: number): { minutes: string; seconds: string } => {
  const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);

  return {
    minutes: result.substr(3, 2),
    seconds: result.substr(6, 2),
  };
};

export const pauseViseo = () => {
  const videoTags = document.getElementsByTagName('video');
  if(videoTags && videoTags.length) {
    for(let i = 0;i<videoTags.length;i++) {
      videoTags[i].pause();
    }
  }
}

export const formatNumber = (num:any) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


export const groupList = (listData: Array<any>, quantity: number) => {
  const grouplist = [];
  let start = 0;
  let end = quantity;

  if (listData.length > 0) {
    let page =
      listData.length % quantity === 0
        ? Math.floor(listData.length / quantity)
        : Math.floor(listData.length / quantity) + 1;

    for (let i = 0; i < page; i++) {
      const result = listData.slice(start, end);
      start = start + quantity;
      end = end + quantity;
      grouplist.push(result);
    }
  }

  return grouplist;
};