import { Modal } from '../common';
import DeleteLargeIcon from '../../assets/img/draft/deleteLargeIcon.png';
import './ModalDeleteMobile.scss';


export const ModalDeleteMobile: React.FC<any> = ({
  isOpen,
  setIsShow,
  onClose,
  handleDelete
}) => {

  return (
    <Modal isShow={isOpen} setIsShow = {setIsShow}>
        <div className='modal-delete-mobile'>
            <img src={DeleteLargeIcon}/>
            <p>Bạn có chắc chắn muốn xóa kết quả chọn trường này?</p>
            <div className="options-mobile">
              <button className="btn-cancel-mobile btn" onClick={onClose}>
                Hủy
              </button>
              <button className="btn-submit-mobile btn" onClick={() => {
                handleDelete();
                onClose()
              }
              }>
                Đồng ý
              </button>
            </div>
        </div>
    </Modal>
  );
};
