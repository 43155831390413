import { createSlice } from '@reduxjs/toolkit';

type ICurrentFormSlice = {
  schoolid: Array<number>;
  editable?: number;
  userProfile: {
    userid: number;
    fullname: string;
    gender: number;
    birth: string;
    phone: string;
    data: {
      trainingLevelId: number;
      city: number;
      race: string;
      identity: string;
      identityDate: any;
      identityLocation: number;
      household: string;
      religion: string;
      receiveLocation: string;
      receiveEmail: string
      parentPhone: string;
      schoolReportList: [];
      highSchoolDiplomaList: [];
      universityForm: {
        academicLevelGrade12: number;
        conductLevelGrade12: number;
        nameGrade12: 'string';
        schoolInformationList: [
        ];
        priorityType: number;
        priorityArea: number;
        isGradeLevelSubmitOption: number;
        isGradePointSubmitOption: number;
        gradeLevelSubmitOption: {
          ologyInformationList: [];
        };
        gradePointSubmitOption: {
          firstSemesterGrade10: number;
          secondSemesterGrade10: number;
          firstSemesterGrade11: number;
          secondSemesterGrade11: number;
          firstSemesterGrade12: number;
          secondSemesterGrade12: number;
        };
        householdNum: string;
        healtInsurance: string;
        schoolName: string;
        trainingLevelSubmit: number;
        graduated: number;
        graduatedText: string;
        firstOlogy: number;
        secondOlogy: number;
        schoolId: number;
      };
    };
  };
};

const initialState = {
    schoolid: [],
    userProfile: {
      userid: 0,
      fullname: '',
      gender: 0,
      birth: '',
      phone: '',
      data: {
        trainingLevelId: 0,
        city: 0,
        race: '',
        identity: '',
        identityDate: '2022-04-12T06:28:07.815Z',
        identityLocation: 0,
        household: '',
        religion: '',
        receiveLocation: '',
        receiveEmail: '',
        parentPhone: '',
        schoolReportList: [],
        highSchoolDiplomaList: [],
        universityForm: {
          academicLevelGrade12: 0,
          conductLevelGrade12: 0,
          nameGrade12: 'string',
          schoolInformationList: [
          ],
          priorityType: 0,
          priorityArea: 0,
          isGradeLevelSubmitOption: 0,
          isGradePointSubmitOption: 0,
          gradeLevelSubmitOption: {
            ologyInformationList: [],
          },
          gradePointSubmitOption: {
            firstSemesterGrade10: 0,
            secondSemesterGrade10: 0,
            firstSemesterGrade11: 0,
            secondSemesterGrade11: 0,
            firstSemesterGrade12: 0,
            secondSemesterGrade12: 0,
          },
          householdNum: '',
          healtInsurance: '',
          schoolName: '',
          trainingLevelSubmit: 0,
          graduated: 0,
          graduatedText: '',
          firstOlogy: 0,
          secondOlogy: 0,
          schoolId: 0,
        },
      },
    },
} as ICurrentFormSlice;

export const currentFormSlice = createSlice({
  name: 'persistSearch',
  initialState: initialState,
  reducers: {
    doSaveCurrentForm(state, action) {
      state.schoolid = action.payload.schoolid;
      state.userProfile = action.payload.userProfile
    },

    doClearCurrentForm(state) {
        state.schoolid = [];
        state.userProfile = initialState.userProfile
    },
  },
});

const { actions, reducer } = currentFormSlice;
export const { doSaveCurrentForm, doClearCurrentForm } = actions;
export default reducer;
