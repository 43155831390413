import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiAdmission } from "../../../services/aixos/apiAdmission";


export const doGetFillSearch = createAsyncThunk(
    'search@GetFillSearch',
    async (params: IParamsGetFillSearch) => {
      const result = await apiAdmission.GetFillSearch(params);
      return result.data;
    }
  )

const initialState = { 
    data: {},
    isLoading: false,
    error: {}
}

const getFillSearch = createSlice({
    name: 'GetFillSearch@',
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(doGetFillSearch.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(doGetFillSearch.fulfilled, (state, action) => {
            state.data = action.payload.content;
            state.isLoading = false;
        })
        builder.addCase(doGetFillSearch.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        })
    },
})

const {reducer: getFillSearchReducer, actions } = getFillSearch
export default getFillSearchReducer;