import './HomePage.scss';
import HeaderSearchMobile from '../../components/HomePage/HeaderSearch/HeaderSearchMobile';
import { TableSchoolDesk } from '../../components/HomePage/TableSchool/TableSchoolDesk';
import { TableSchoolMobile } from '../../components/HomePage/TableSchool/TableSchoolMobile';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { useEffect, useState } from 'react';
import ContactMobile from '../../components/HomePage/Contact/ContactMobile';
import { HeaderLayoutMobile } from '../../layouts/HeaderLayout/HeaderLayoutMobile';
import { NewsList } from '../../components/HomePage/NewsList';
import { BannerAds } from '../../components/common/BannerAds/BannerAds';
import { doGetBannerSchool } from '../../redux/slice/appSlice/getBannerSlice';
import { useDispatch } from 'react-redux';

export const HomePageMobile = () => {
  const [isShowTable, setIsShowTable] = useState(false);
  const [isPuclicSchool, setIsPuclicSchool] = useState(true);
  const dispatch = useDispatch();
  const {
    listSchool,
    listSchoolNonPublic,
    isLoadingListSchool,
  } = useSelector((state: RootState) => state.searchSlice);

  const {
    banners, 
    isLoadingBanner
  } = useSelector((state: RootState) => state.getBannerSliceReducer);


  useEffect(() => {
    document.getElementById("table-school")?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }, [isShowTable])
  useEffect(() => {
    dispatch(doGetBannerSchool({
      typeschool: 50,
      bannerplace: "MobileTopBanner",
      typeproject: 30
  }))
    if((listSchool && listSchool.length) || (listSchoolNonPublic && listSchoolNonPublic.length) > 0) {
      setIsShowTable(true);
    }
  }, [])
  return (
    <>
      <div id='header-search-mobile' style={{height: "96px"}}></div>
      <div className="home-page-mobile">
      <HeaderLayoutMobile/>
      {/* <BannerAds listBanner={[]} className={'marginLeftRight-1rem'} height = {'222px'}/> */}
      <div className='home-page-mobile__banner-top'>
        <img src = {banners[0]?.link || ''} onClick = {() => {
          window.open(`${banners[0]?.linkclick}`);
        }}/>
      </div>
      <HeaderSearchMobile setIsShowTable = {setIsShowTable}/>
      {/* <BannerAds listBanner={[]} className={'borderRadius28 marginTop24'} height = {'432px'}/> */}
      {isShowTable && <TableSchoolMobile listSchool={isPuclicSchool ? listSchool : listSchoolNonPublic} setIsPuclicSchool = {setIsPuclicSchool} ologyName={'ologyName'} searchid={0} isGetInfo={true} preventSellect={false} isLoadingListSchool = {isLoadingListSchool}/>}
      {/* <BannerAds listBanner={[]} className={'borderRadius28 marginTop24'} height = {'432px'}/> */}
      {/* <ModalAspiration/> */}
      {/* <HeaderSearchSample/> */}
      {/* <TableSchoolDesk />        */}
      {/* <div id='news-mobile' style={{height: "90px"}}></div>  */}
      {/* <NewsList /> */}
      {/* <BannerAds listBanner={[]} className={'borderRadius28 marginTop24'} height = {'432px'}/> */}
      {/* <p className="label">TIN TỨC</p> */}
      <NewsList isRelative={false}/>
      {/* <div id='contact-mobile' style={{height: "120px"}}></div>  */}
      <ContactMobile/>
      {/* <BannerAds listBanner={[]} className={'marginLeftRight-1rem'} height = {'222px'}/> */}
    </div>
    </>
  );
};
