import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { SvgLogoYoot } from '../../../assets/svg/SvgLogoYoot'
import backNewsIcon from '../../../assets/img/draft//backNewsIcon.png'
import './NewsDetail.scss'
import { NewsList } from '../../../components/HomePage/NewsList'
import { doGetOneNews } from '../../../redux/slice/apiSlice/getOneNews'
import { useResponsive } from '../../../hooks'


export const NewsDetail= () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { isFromMobile }  = useResponsive();
  const {newsid} = useParams();

  const {news} = useSelector((state:any) => {return state.getOneNewsReducer})
  const { data, dataFull} = useSelector((state: any) => state.getListNewsReducer);
  
  useEffect(() => {
    window.scrollTo(0,0) // auto scroll to Top
    dispatch(doGetOneNews({
      newsid: newsid,
    }))
  }, [newsid])

  //Filter relative News
  const relativeNews = dataFull.filter((newsItem:any)=>{
      return  news.id != newsItem.id;
  })

  return (
    <>
      <div className="newsDetail">
        <div className="newsDetail-container">
          <div className="newsDetail-header">
            {!isFromMobile ? <div className="back-icon"  onClick={() => navigate('/list-news')}>
              <img src={backNewsIcon}/>
              <p>TIN TỨC</p>
            </div> : <div className="header-logo" onClick={() => navigate('/')}>
              <SvgLogoYoot/>
            </div>}
            {isFromMobile && <div className="header-title">TIN TỨC</div>}
            {!isFromMobile ? <div className="header-logo" onClick={() => navigate('/')}>
              <SvgLogoYoot/>
            </div> : <div className="back-icon"  onClick={() => navigate('/list-news')}>
              <img src={backNewsIcon}/>
              <p>TIN TỨC</p>
            </div>}
          </div>
          <div className="newsDetail-body">
            <div className="newsDetail-document">
              <h1>{news.title}</h1>

              <div className='newsDetail-content'
                style={{ whiteSpace: 'pre-wrap'}}
                dangerouslySetInnerHTML={{ __html: news.content }}>
              </div>
            </div>
          </div>
          <NewsList relativeNews={relativeNews} isRelative={true}/>
        </div>
      </div>   
    </>
  );
};
