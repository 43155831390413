import React, { useEffect } from 'react';
import { useResponsive } from '../../hooks';
import { HomePageDesk } from './HomePageDesk';
import { HomePageMobile } from './HomePageMobile';

export const HomePage = () => {
  const { isFromMobile } = useResponsive();

  return <>{isFromMobile ? <HomePageDesk /> : <HomePageMobile />}</>;
};
