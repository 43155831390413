import './Contact.scss';
import HeaderImage from '../../../assets/img/draft/image-title.png';
import Youngman3 from '../../../assets/img/draft/youngman3.png';
import { SvgCompany } from '../../../assets/svg/SvgCompany';
import { SvgFB } from '../../../assets/svg/SvgFB';
import { SvgPhone } from '../../../assets/svg/SvgPhone';
export default function Contact() {
    return <div className="contact" id='contact'>
        <div className="contact__wrap">
            <div className='contact-header'>
                <img src={HeaderImage} className='contact-header__image'/>
            </div>
            
            <div className='body'>
               <img src={Youngman3} className='body__image'/>
                <div className='body__content'>
                    <p className='body__content__title'>Sở Giáo dục và Đào tạo Thành phố Hồ Chí Minh<br/>Phòng Khảo thí và Kiểm định chất lượng giáo dục</p>
                    <div className='body__content__divide'></div>
                    <p className='body__content__body body__content__body__bold'>Hỗ trợ kĩ thuật những vấn đề liên quan đến website liên hệ:</p>
                    <p style={{paddingTop: "6px",fontWeight:"700", color:"#401E96", fontFamily:"Montserrat !important", display:"flex", gap:"2px", alignItems:"center", lineHeight:"20px"}} className='body__content__body'><SvgPhone width='1.09375vw' height= '1.09375vw'/> Số đường dây nóng - 1800 888 887 - Ms. Mỹ Linh (YOOT): 077 512 4801</p>
                    <p className='body__content__body body__content__body__bold' style={{marginBottom:"6px", marginTop:"6px"}}>Mọi thắc mắc về chuyên môn liên hệ:</p>
                    <p  style={{fontWeight:"700", color:"#401E96", fontFamily:"Montserrat !important", display:"flex",gap:"2px",alignItems:"center",lineHeight:"20px"}}className='body__content__body'><SvgPhone width='1.09375vw' height= '1.09375vw'/> Số đường dây nóng - 02838226796</p>
                    <p style={{paddingTop: "6px"}} className='body__content__body'><SvgCompany width='1.09375vw' height= '1.09375vw'/> <a href='https://khaothi.hcm.edu.vn/homehcmmb' target="_blank" className='link-contact'>https://khaothi.hcm.edu.vn/homehcmmb</a></p>
                    <p className='body__content__body'><SvgFB width='1.09375vw' height= '1.09375vw'/> Fanpage: <a href='https://www.facebook.com/profile.php?id=100083162059136&mibextid=ZbWKwL' target="_blank"  className='link-contact'>Phòng Khảo thí và Kiểm định chất lượng giáo dục TPHCM</a></p>
                </div>
            </div>
        </div>
    </div>
}