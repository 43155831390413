import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGoogleMap } from "../../../services/aixos/apiGoogleMap";

export const doGetGoogleMap = createAsyncThunk(
    'getGoogleMap@post/getGoogleMap',
    async (param:any) => {
        const result = await apiGoogleMap.GetGoogleMap(param);
        return result.data;
    }
   
)

const initialState = { 
    street: "",
    ward: "",
    district: "",
    province: "",
    lat: 0,
    lng: 0,
    searchaddressid:5,
    isLoading: false,
    error: {}
}

const getGoogleMapSlice = createSlice({
    name: 'googlemap@',
    initialState: initialState,
    reducers: {
        doSetAddressId(state, action) {
            state.searchaddressid = action.payload;
        },
        doSetInforAddress(state, action) {
            state.street =  action.payload.street;
            state.ward = action.payload.ward;
            state.district = action.payload.district;
            state.province = action.payload.province;
            state.lat = action.payload.lat;
            state.lng = action.payload.lng
        },
    },
    extraReducers: (builder) => {
        builder.addCase(doGetGoogleMap.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(doGetGoogleMap.fulfilled, (state, action) => {
            state.searchaddressid = action.payload?.content.id;
            state.isLoading = false;
        })
        builder.addCase(doGetGoogleMap.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        })
    },
})

const {reducer: getGoogleMapReducer, actions } = getGoogleMapSlice
export const { doSetAddressId, doSetInforAddress } = actions;
export default getGoogleMapReducer;