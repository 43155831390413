import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.scss';
import GA from './components/GA/GA';
import { HomePage } from './containers';
import { Report } from './containers/Report/Report';
import { ReportDetail } from './containers/ReportDetail/ReportDetail';
import { SelectedResultPage } from './containers/SelectedResultPage/SelectedResultPage';
import { ListNews } from './containers/ListNews/ListNews';
import { SidebarLayout } from './layouts';
import { PublicRouter } from './routers/PublicRouter';
import { NewsDetail } from './containers/ListNews/NewsDetail/NewsDetail';


export default function App() {
  function AppRouter() {

    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <BrowserRouter>
            <GA />
            <Routes>
              {/* <ErrorBoundary fallback={<div>Oh no</div>}></ErrorBoundary> */}
              <Route
                path={process.env.PUBLIC_URL + '/'}
                element={<PublicRouter component={HomePage} layout={SidebarLayout}/>}
              ></Route>
              <Route
                path={process.env.PUBLIC_URL + '/selected-result'}
                element={<PublicRouter component={SelectedResultPage} layout={SidebarLayout} isHideHeader = {true}/>}
              ></Route>

              <Route
                path={process.env.PUBLIC_URL + '/report'}
                element={<PublicRouter component={Report} layout={SidebarLayout}  isHideMenu = {true}/>}
              />

              <Route
                path={process.env.PUBLIC_URL + '/report/:id'}
                element={<PublicRouter component={ReportDetail} layout={SidebarLayout} isHideMenu = {true}/>}
              />

<Route
                path={process.env.PUBLIC_URL + '/list-news'}
                element={<PublicRouter component={ListNews} layout={SidebarLayout} isHideMenu = {true}/>}
              />
              <Route
                path={process.env.PUBLIC_URL + '/news-detail/:newsid'}
                element={<PublicRouter component={NewsDetail} layout={SidebarLayout} isHideMenu = {true}/>}
              />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </div>
    );
  }



  useEffect(() => {

  }, []);

  return <AppRouter />;
}
