import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

export const InfoDetail = ({ school, info, id, title }: any) => {
  const ListStar = process.env.PATH_CDN_ASSET_CHANCE + 'general/' + 'images/ListStar.png';

  const getStyle = (id: string) => {
    if (document.getElementById(id)) return window.getComputedStyle(document.getElementById(id) as Element);
    return {} as CSSStyleDeclaration;
  };

  const convertHeightpx = (height: string) => {
    return parseInt(height.slice(0, height.length - 2));
  };

  const [isToolTip, setIsToolTip] = useState(false);

  useEffect(() => {
    convertHeightpx(getStyle(school.highschoolid.toString() + id + '2')?.height) >
      convertHeightpx(getStyle(school.highschoolid.toString() + id)?.height) && setIsToolTip(true);
  }, [school.highschoolid]);

  return (
    <>
      <span id={school.highschoolid.toString() + id + '2'} className="info-detail-2">
        {info}
      </span>
      <span
        id={school.highschoolid.toString() + id}
        data-tip=""
        data-for={school.highschooladdresses && (school.highschoolid.toString() + school.highschooladdresses[0]?.highschooladdressid.toString() + id)}
        className="info-detail"
        style={isToolTip ? { cursor: 'pointer' } : {}}
      >
        {info}
      </span>
      {isToolTip && (
        <ReactTooltip
          id={school.highschooladdresses && (school.highschoolid.toString() + school.highschooladdresses[0]?.highschooladdressid.toString() + id)}
          place="bottom"
          type="dark"
          effect="float"
          multiline={true}
        >
          <div className={`table-school-desk__error-modal`}>
            {/* @ts-ignore */}
            <h3 className="table-school-desk__error-modal__title">{title}</h3>
            {info.split(/\r?\n/).map((value: string, index: any) => (
              <>
                <div key={index} className="table-school-desk__error-modal__detail">
                  <img src={ListStar} className="table-school-desk__error-modal__list-item"></img>
                  <p>{value}</p>
                </div>
              </>
            ))}
          </div>
        </ReactTooltip>
      )}
    </>
  );
};
