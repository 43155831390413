import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "../../assets/svg/ArrowLeft";
import { TableReportDetail } from "../../components/ReportDetail/TableReportDetail/TableReportDetail";
import './ReportDetail.scss'
import { HeaderLayout } from "../../layouts/HeaderLayout/HeaderLayout";
export const ReportDetail = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const header = document.querySelector('.header-layout') as HTMLElement;
        const table = document.querySelector('.tb-report-detail__table') as HTMLElement;
        const origOffsetY = table.offsetTop;
        setTimeout(() => {
            document.addEventListener('scroll', () => {
                if (window.scrollY > origOffsetY + 50) header.style.top = '-80px'
                else header.style.top = '0'
            })
        }, 1000)

    }, [])

    return (
        <>
        <HeaderLayout isHideMenu = {true}/>
        <div className="report-detail">
            <div className="header__back" onClick={() => navigate(-1)}>
                <div className="back-logo">
                    <ArrowLeft color="black" />
                </div>
                QUAY LẠI
            </div>
            <TableReportDetail />
        </div></>
        
    )
}