const basicUrl = process.env.URL_IMG_PATH + 'images/';

export const ImageLogoYoot = process.env.PATH_CDN_ASSET_CHANCE + 'general/images/iconYoot.png';
export const AccordionBg = basicUrl + 'accordion-bg.png';
export const ArrowRight = basicUrl + 'arrowRight.png';
export const BackIcon= basicUrl + 'back-icon.png';
export const bannerInstruction = basicUrl + 'bannerInstruction.png';
export const bgCareer = basicUrl + 'bgCareer.png';
export const BgHomePage = basicUrl + 'BgHomePage.png';
export const bgStar = basicUrl + 'bgStar.png';
export const bgVocational = basicUrl + 'bgVocational.png';
export const bgVocationalMobile = basicUrl + 'bgVocationalMobile.png';
export const BrownTick = basicUrl + 'BrownTick.png'; //-
export const CareerCardImg = basicUrl + 'CareerCard.png';//-
export const clickSchool = basicUrl + 'click-school.png'; //-
export const closeIcon = basicUrl + 'close-icon.png';
export const decorationHome = basicUrl + 'decorationHome.png';
export const decorationVocational = basicUrl + 'decorationVocational.png';
export const decorationVocationalMobile = basicUrl + 'decorationVocationalMobile.png';
export const decorationVocationalMobileV2 = basicUrl + 'decorationVocationalMobileV2.png';
export const deleteButton = basicUrl + 'deteteButton.png'; //-haha
export const disc1 = basicUrl + 'disc1.png';
export const disc2 = basicUrl + 'disc2.png';
export const disc3 = basicUrl + 'disc3.png';
export const gcnImage = basicUrl + 'gcn.png';
export const GreenTick = basicUrl + 'GreenTick.png';
export const hot = basicUrl + 'hot.png';
export const iconChat = basicUrl + 'icon-chat.png';
export const logoYW = basicUrl + 'logoYW.png';
export const newsTitle = basicUrl + 'news_title.png';
export const ontableschedule = basicUrl + 'ontableschedule.png';
export const OrangeTick = basicUrl + 'OrangeTick.png';
export const starConner = basicUrl + 'starConner.png';
export const starDefault = basicUrl + 'starDefault.png';
export const starYellow = basicUrl + 'starYellow.png';
export const TickGCN = basicUrl + 'TickGCN.png';
export const trainingSchedule = basicUrl + 'trainingSchedule.png';
export const underChatIcon = basicUrl + 'under-chat-icon.png';
export const videoImg = basicUrl + 'video-img.png';

export const CloseButton = process.env.PATH_CDN_ASSET_CHANCE + 'general/' + 'images/CloseButton.png';
export const ModalSuccessIcon = process.env.PATH_CDN_ASSET_CHANCE + 'general/images/' + 'ModalSuccessIcon.png';
export const IconCancelBlue = process.env.PATH_CDN_ASSET_CHANCE + 'general/images/' + 'IconCancelBlue.png';
export const IconTick = process.env.PATH_CDN_ASSET_CHANCE + 'general/' + 'images/Tick.png';
export const ModalCautionIcon = process.env.PATH_CDN_ASSET_CHANCE + 'general/' + 'images/Caution.png';
export const CheckChoiceWhiteImg = process.env.PATH_CDN_ASSET_CHANCE + 'general/' + 'icons/checkChoiceWhite.png';
export const InstructionButton = process.env.PATH_CDN_ASSET_CHANCE + 'general/' + 'images/InstructionButton.png';
export const InstructionButton2 = process.env.PATH_CDN_ASSET_CHANCE + 'general/' + 'images/InstructionButton2.png';
export const checkChoiceImg = process.env.PATH_CDN_ASSET_CHANCE + 'general/' + 'icons/checkChoiceWhite.png';