import { useState } from 'react';
import BtnDuhoc from '../../assets/img/draft/btnDuhoc.png';
import LogoYoot from '../../assets/img/draft/logo-yoot.png';
import { SvgArrow } from '../../assets/svg/SvgArrow';
import { SvgBarMenu } from '../../assets/svg/SvgBarMenu';
import { SvgLogoYoot } from '../../assets/svg/SvgLogoYoot';

import './HeaderLayout.scss';
export const HeaderLayoutMobile = ({isHideMenu}:any) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const handleToggleMenu = () => {
    setIsOpenMenu(isOpenMenu => !isOpenMenu);
  };

  const handleScrollIntoView = (id: any) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    document.getElementById(id)?.focus();
  };

  return (
    <div className="header-layout-mobile">
      <div className="header-layout-mobile__content">
        <div className="header-layout-mobile__content-logo">
          <SvgLogoYoot/>
        </div>
        {!isHideMenu &&<div style={{display:"flex", alignItems:"center", gap:"1rem"}}>
          <a className="header-layout-mobile__content-btnyootway" href="https://yootway.vn/" target="_blank">
            <img src={BtnDuhoc} alt="" />
          </a>
          <div onClick={handleToggleMenu} className="header-layout-mobile__content-menubar">
            <SvgBarMenu />
        </div>
        </div>}
        {!isHideMenu && <div className="header-layout-mobile__content-menu" style={{ display: isOpenMenu ? 'block' : 'none' }}>
          <div className="header-layout-mobile__content-menu-close" onClick={handleToggleMenu}>
            <p>
              ĐÓNG
              <span>
                <SvgArrow />
              </span>
            </p>
          </div>
          <ul>
            <li
              className="header-layout-mobile__content-menu-item"
              onClick={() => {
                handleScrollIntoView('header-search-mobile');
                handleToggleMenu();
              }}
            >
              Tra cứu
            </li>
            <li
              className="header-layout-mobile__content-menu-item"
              onClick={() => {
                handleScrollIntoView('news-list');
                handleToggleMenu();
              }}
            >
              Tin tức
            </li>
            <li
              className="header-layout-mobile__content-menu-item"
              onClick={() => {
                handleScrollIntoView('contact-mobile');
                handleToggleMenu();
              }}
            >
              Liên hệ
            </li>
          </ul>
        </div>}
      </div>
    </div>
  );
};