import axios, { AxiosError, AxiosResponse } from 'axios';
import { ETokenName } from '../../constants';
import { readCookie } from '../../utils';
import { responseAPI, responseErrorAPI } from './axiosBase';

const token = readCookie(ETokenName.COMUNITY_ACCESS_KEY);

const baseURL = process.env.URL_USER_ONLINE_API; //URL_SOCIAL_API

const axiosSocialAPI = axios.create({
  baseURL: baseURL + 'api/',
  headers: {
    'content-type': 'application/json',
    AuthorizationSwagger: '!-12M@AAiL_-&3C@aAmPPP@1Gn!!$^%',
    // Authorization: token,
    // channel: 'WEB',
    // component: 'YOOTLINK',
    // httpsAgent: new https.Agent({
    //   rejectUnauthorized: false,
    // }),
  },
});
axiosSocialAPI.interceptors.response.use(
  (res: AxiosResponse) => responseAPI(res),
  (error: AxiosError) => responseErrorAPI(error),
);

export default axiosSocialAPI;
