import "./ItemNews.scss"

export const ItemNews = ({img,title, shortcontent, index, onClick}:any) => {
    return <div className="item-news" key = {index} onClick = {onClick}>
        <div className="img"><img src={img}/></div>
        <div className="content">
            <div className="title">{title}</div>
            <div className="short-content">
           {shortcontent}
            </div>
        </div>
    </div>
}