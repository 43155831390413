import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  totalScore: 0,
  name: '',
  email: '',
  phone: '',
  typeAdmission: -1,
  mathScore: -1,
  literScore: -1,
  englishScore: -1,
  classtext: '',
  phoneParent: '',
  secondarySchool: '',
  subject: '',
  priorityObjects: [],
  address: {},
  specialScore: -1,
  pointPriority: -1,
  searchaddressid: -1,
  subjectSpecialId: 0,
};

export const storeInfoUserSlice = createSlice({
  name: 'storeInfoUser',
  initialState: initialState,
  reducers: {
    doStoreUserInfo(state, action) {
      state.totalScore = action.payload.totalScore;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.typeAdmission = action.payload.typeAdmission;
      state.mathScore = action.payload.mathScore;
      state.literScore = action.payload.literScore;
      state.englishScore = action.payload.englishScore;
      state.classtext = action.payload.classtext;
      state.phoneParent = action.payload.phoneParent;
      state.secondarySchool = action.payload.secondarySchool;
      state.subject = action.payload.subject;
      state.priorityObjects = action.payload.priorityObjects;
      state.address = action.payload.address;
      state.specialScore = action.payload.specialScore;
      state.pointPriority = action.payload.pointPriority;
      state.searchaddressid = action.payload.searchaddressid;
      state.subjectSpecialId = action.payload.subjectSpecialId;
    },
  },
});

const { actions, reducer } = storeInfoUserSlice;
export const { doStoreUserInfo } = actions;
export default reducer;
