import axiosCas10NoToken from './axiosCas10NoToken';
import axiosCas10 from './axiosCas10';

export const apiGetReport = {
  getReport: (params: any) => {
    const url = 'Report/GetListConsultant';
    return axiosCas10NoToken.post(url, params);
  },
  getReportDetail: (params: IParamsDetailConsultant) => {
    const url = 'Report/GetDetailConsultant';
    return axiosCas10NoToken.post(url, params);
  },
};
