import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoadingFirstToken: false,
    token: '',
};

export const StoreTokenTemUser = createSlice({
  name: 'storeToken',
  initialState: initialState,
  reducers: {
    doStoreTokenTemUser(state, action: any) {
      state.isLoadingFirstToken = true;
      state.token = action.payload.token;
    },
  },
});

const { actions, reducer } = StoreTokenTemUser;
export const { doStoreTokenTemUser } = actions;
export default reducer;
