import axios, { AxiosError, AxiosResponse } from 'axios';
import { createHeaderAPINonToken, responseAPI, responseErrorAPI } from './axiosBase';

const baseURL = process.env.URL_BUILDYS_API || '';

const axiosChecking = axios.create(createHeaderAPINonToken(baseURL));

axiosChecking.interceptors.response.use(
  (res: AxiosResponse) => responseAPI(res),
  (error: AxiosError) => responseErrorAPI(error),
);

export default axiosChecking;
