import React, { ChangeEvent, useEffect, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import { ModalRegisterSupport } from '../../ModalRegisterSupport/ModalRegisterSupport';
import { ModalAspiration } from '../ModalAspiration/ModalAspiration';
import { doStoreListChosen } from '../../../redux/slice/appSlice/listChosenSchoolSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux';
import { doCreateHistorySearchAdmission } from '../../../redux';
import { useAppSelector } from '../../../redux';
import './TableSchool.scss';
import { useNavigate } from 'react-router-dom';
import { apiAdmission } from '../../../services/aixos/apiAdmission';
import { doUpdateIsChooseHighSchool } from '../../../redux';
import { Loader } from '../../common';
import { InfoDetail } from './InfoDetail';
import { renderAdmissionPercentage } from '../../../utils';
import { doGetHistorySearchSlice } from '../../../redux/slice/appSlice/getHistorySearchSlice';
import Pagination from '../../common/Pagination/Pagination';
import { NotiOverload } from '../../common/NotiOverload/NotiOverload';

export const TableSchoolCheckBox = ({ kindschool, kindschoolid, onCheck, onUnCheck }: any) => {
  const [isChooseSchool, setIsChooseSchool] = useState(true);

  return (
    <>
      {isChooseSchool ? (
        <div
          className="kindschool-checked"
          onClick={() => {
            setIsChooseSchool(!isChooseSchool);
            onUnCheck();
          }}
          id={kindschoolid}
        >
          <FiCheck size={15} color="white"></FiCheck>
        </div>
      ) : (
        <div
          className="kindschool-uncheck"
          onClick={() => {
            setIsChooseSchool(!isChooseSchool);
            onCheck();
          }}
        ></div>
      )}
      <p className="kindschool">{kindschool}</p>
    </>
  );
};

export const TableSchoolDesk: React.FC<ITableSchool> = ({
  listSchool,
  searchid,
  isGetInfo,
  preventSellect,
  setIsPuclicSchool,
  isLoadingListSchool
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [highschoolid, setHighschoolid] = useState(0);
  const { searchaddressid } = useAppSelector(state => state.getGoogleMapReducer);
  const { totalScore, typeAdmission, subjectSpecialId } = useSelector((state: RootState) => state.storeUserInfo);
  const { searchadmissionid, listSchool: publichighschools, numhistorysearch } = useSelector((state: RootState) => state.searchSlice);
  const { mytotalscore } = useSelector((state: RootState) => state.searchSlice);
  const ListStar = process.env.PATH_CDN_ASSET_CHANCE + 'general/' + 'images/ListStar.png';
  const [listChecked, setListChecked] = useState<Array<any>>([]);
  const [listCheckedCL, setListCheckedCL] = useState<Array<any>>([]);
  const [listCheckedNotCL, setListCheckedNotCL] = useState<Array<any>>([]);

  const [listChosenSchool, setListChosenSchool] = useState<Array<any>>([]);

  const [isShowResult, setIsShowResult] = useState<boolean>(false);
  const [isShowSupport, setIsShowSupport] = useState<boolean>(false);
  const [isCL, setIsCL] = useState(true);

  const [listSchoolNew, setListSchoolNew] = useState(listSchool);
  const [listKindId, setListKindId] = useState([20, 30, 40, 50]);
  
  const [paginationNumber, setPaginationNumber] = useState(0);

  const { publicHighSchoolsHistory, nonPublicHighSchoolsHistory, totalscoreselections, isLoading
  } = useSelector(
    (state: any) => state.getHistorySearchSlice,
  );
  const {
    isOverLoadServer
  } = useSelector((state: RootState) => state.searchSlice);
  const [isSaveResult, setSaveResult] = useState(false);
  //console.log("typeAdmission",typeAdmission)
  const [distanceValue, setDistanceValue] = useState(() => {
    if(typeAdmission == 1) return 5;
    else if(typeAdmission == 2 || typeAdmission == 3){
      return 15;
    }
    return 1;
  });
  useEffect(() => {
    if((typeAdmission == 2 || typeAdmission == 3) && isCL) {
      setDistanceValue(15)
    }
    else {
      setDistanceValue(5);
    }
  },[typeAdmission, isCL])

  // useEffect(() => {
  //   dispatch(doGetHistorySearchSlice({
  //     currentpage: 0,
  //     limit: 0
  //   }));
  // }, []);
 const handleDisableButton = () => {
  if (publicHighSchoolsHistory.length > 0 || nonPublicHighSchoolsHistory.length > 0 || isSaveResult) {
    return false;
  } else return true;
 }
  
  //console.log()
  const findIndex = (arr: any, index: any) => {
    return arr.find((el: any) => el === index);
  };
  const kindSchoolIdList = [
    {
      kindschoolid: 20,
      kindschool: 'Tư thục',
    },
    {
      kindschoolid: 30,
      kindschool: 'GDTX',
    },
    {
      kindschoolid: 40,
      kindschool: 'CĐ - Trung cấp',
    },
    {
      kindschoolid: 50,
      kindschool: 'Quốc tế',
    },
  ];
  const [listKindCheck, setListKindCheck] = useState(kindSchoolIdList);
  const mapIdSchoolToString = (arrayId: any) => {
    return arrayId.map((item: any) => (item.id || "") + '-' + (item.adressId || "") + '_' + (item.distance || "") + ';');
  };
  const validURL = (str: string) => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    ); // fragment locator
    return !!pattern.test(str);
  };
  
  const handleCheck = (id: number, adressId: any, distance: any) => {
    //console.log("123");
    let newArr = [...listChecked];
    let newArrCL = [...listCheckedCL];
    let newArrNotCL = [...listCheckedNotCL];

    let newListChosenSchool = [...listChosenSchool];

    const index = findIndex(newArr, id.toString() + adressId?.toString());
    // let newArrSchool = [...listSchoolChecked];
    if (index) {
      newArr = newArr.filter(item => item !== id.toString() + adressId?.toString());
      newListChosenSchool = newListChosenSchool.filter(item => (item.highschoolid.toString() + item.highschooladdresses[0].highschooladdressid.toString()) !== id.toString() + adressId.toString());
      if (isCL) {
        newArrCL = newArrCL.filter(item => item !== id);
      } else {
        newArrNotCL = newArrNotCL.filter(item => (item.id.toString() + item.adressId.toString())  !== (id.toString() + adressId.toString()));
      }
      // newArrSchool = newArrSchool.filter(item => item.id !== id);
    } else {
      //if (newArr.length > 4) return;
      newArr.push(id.toString() + adressId?.toString());
      const schoolobj = listSchoolNew.find(item => (item.highschoolid.toString() + item.highschooladdresses[0].highschooladdressid.toString()) === id.toString() + adressId.toString());
      newListChosenSchool.push(schoolobj);
      if (isCL) {
        newArrCL.push(id);
      } else {
        newArrNotCL.push({ id: id, adressId: adressId, distance: distance });
      }
      // newArrSchool.push(schoolobj);
    }
    setListChecked(newArr);
    setListChosenSchool(newListChosenSchool);
    setListCheckedCL(newArrCL);
    setListCheckedNotCL(newArrNotCL);
    // setListSchoolChecked(newArrSchool);
  };

  const handleDistanceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDistanceValue(parseInt(e.target.value));
  };
  const handleClearListIdSchool = () => {
    setListChecked([]);
    setListChosenSchool([]);
    setListCheckedCL([]);
    setListCheckedNotCL([]);
  };
  const getBackgroundSize = () => {
    return {
      backgroundSize: `${((distanceValue - 1) * 100) / 14}% 100%`,
    };
  };

  const renderTypeAddmission = (type: number) => {
    if (type === 1) return 'TS10 thường';
    else if (type === 2) return 'TS10 chuyên';
    else if (type === 3) return 'TS10 tích hợp';
  };

  const renderListChosenSchool = () => {
    if(isCL) return listChosenSchool;
    return [];
  }

  const handleFilterByPagiNumber = (listSchool:any, pagiNumber:any) => {
    return [...listSchool].slice(pagiNumber*8, pagiNumber*8+8);
  }

  const filterSchoolByDistance = (distance: number, listSchool: any, typeAdmission:any) => {
    let listSchoolBk = [...listSchool];
    listSchoolBk = listSchoolBk.filter(school => {
      return (parseInt(school.highschooladdresses[0]?.distance) < distance) || (!school.highschooladdresses[0]?.distance) || ((typeAdmission == 2 || typeAdmission == 3) && (distance == 15) && isCL);
    });

    return listSchoolBk;
  };

  const handleKindSchoolCheck = (kindSchoolid: number) => {
    setListKindId([...listKindId, kindSchoolid]);
    //setListKindId([...listKindId, ...listKindId.filter((id: any) => kindSchoolid == id)]);
  };

  const handleKindSchoolUnCheck = (kindSchoolid: number) => {
    return setListKindId(listKindId.filter((id: any) => id != kindSchoolid));
  };

  const handleFilterByChexBox = () => {
    let listSchookBk = [...listSchool];
    if (isCL) return listSchookBk;
    return listSchookBk.filter((school: any) => {
      return listKindId.indexOf(school.kindschoolid) != -1;
    });
  };

  const handleFilterSchool = () => {
    const listSchoolByCheck = handleFilterByChexBox();
    const listSchoolFilter = filterSchoolByDistance(distanceValue, listSchoolByCheck, typeAdmission);
    setListSchoolNew(listSchoolFilter);
  };
  
  const handlePrevPagnum = (isPrev:boolean) => {
    if(isPrev) {
      if(paginationNumber == 0) return;
      setPaginationNumber((prev:any) => prev - 1);
    }
    else {
      if(paginationNumber == Math.ceil(listSchoolNew.length/8) - 1) return;
      setPaginationNumber((prev:any) => prev + 1);
    }
}
  // const
  // range = document.getElementById('range'),
  // rangeV = document.getElementById('rangeV'),
  // setValue = ()=>{
  // 	const
  // 		newValue = Number( (range.value - range.min) * 100 / (range.max - range.min) ),
  // 		newPosition = 10 - (newValue * 0.2);
  // 	rangeV.innerHTML = `<span>${range.value}</span>`;
  // 	rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`;
  // };
  // document.addEventListener("DOMContentLoaded", setValue);
  // range.addEventListener('input', setValue);

  const range = document.getElementById('distanceRange') as HTMLInputElement;
  const rangeV = document.getElementById('rangeV') as HTMLInputElement;
  const rangeW = document.getElementById('rangeW') as HTMLInputElement;

  const updateRangeV = async () => {
    const newValue =
        ((await (Number(range?.value) - Number(range?.min))) * 100) / (Number(range?.max) - Number(range?.min)),
      newPosition = (await 10) - newValue * 0.17;
    if(rangeV) {
      rangeV.innerHTML = `<span>${range?.value}</span>`;
      rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`;
      
    }
    if(rangeW){
      
       
        rangeW.innerHTML =  parseInt(range?.value) > 5 ? `<div><div className="range-warning" >
                              <p>Nên chọn trường dưới 5km để thuận tiện di chuyển!</p></div><div/>` : ``
    }
    
  };

  const formatSchoolYear = (schoolYear: string) => {
    return schoolYear.slice(0, 4);
  };

  useEffect(() => {
    updateRangeV();
  }, [range?.value]);

  function _ComponentListSchool() {
    if(isOverLoadServer) return <NotiOverload />;
    if (listSchoolNew?.length === 0 || listSchoolNew[0]?.highschoolid === -1)
      return (
        <div className="list-school-blank">
          <p>Không tìm thấy kết quả phù hợp</p>
          <p>Vui lòng thử lại</p>
          {/* <button className="btn-primary">Thử lại</button> */}
        </div>
      );

    return (
      <table className='list-school-content'>
        <tbody>
          {handleFilterByPagiNumber([...listSchoolNew], paginationNumber)?.map((school: any, index: any) => {
            return (
              <tr key={`table-${index}`} className={isCL ? '' : 'sc-no-cl'}>
                <td>{paginationNumber*8 + index + 1}</td>
                <td onClick={() => {
                      school.linkhighschool && window.open(school.linkhighschool, '_blank');
                    }
                    }  className={
                      !school.linkhighschool ? '' : 'school-name-td__hover'
                    }    >
                  <p
                    className={
                      !school.linkhighschool ? 'school-name school-info' : 'school-name school-name__hover school-info'
                    }         
                  >
                    {school.highschoolname}
                  </p>
                  {school.highschooladdresses && school.highschooladdresses?.length > 0 && (
                    <p className="school-info school-distance">
                      {school.highschooladdresses[0]?.distance != '0'
                        ? `(${school.highschooladdresses[0]?.distance} km)`
                        : ''}
                    </p>
                  )}
                </td>
                <td>
                  {school.highschooladdresses && school.highschooladdresses.length > 0 && (
                    <p className="school-info">{`${school.highschooladdresses[0]?.street}, ${school.highschooladdresses[0]?.wardtext}, ${school.highschooladdresses[0]?.districttext}`}</p>
                  )}
                </td>
                {isCL && (
                  <td id="mytable" className="mini-table">
                    <table>
                      <tbody>
                        {school.highschoolseasons?.length > 0 &&
                          school.highschoolseasons?.map((season: any, index: any) => (
                            <tr key={index}>
                              <td>{season.yeartext ? formatSchoolYear(season.yeartext) : ''}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </td>
                )}
                {isCL && (
                  <td className="mini-table">
                    <table>
                      <tbody>
                        {school.highschoolseasons?.length > 0 &&
                          school.highschoolseasons?.map((season: any, index: any) => (
                            <tr key={index}>
                              <td>{season.target > 0 ? season.target : ''}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </td>
                )}

                {isCL ? (
                  <td
                    className="mini-table"
                    style={typeAdmission === 2 ? { width: '200px' } : typeAdmission === 3 ? { width: '100px' } : {}}
                  >
                    <table>
                      <tbody>
                        {school.highschoolseasons?.map((sesson: any, index: any) => (
                          <tr key={index}>
                            {sesson.referencepoint?.length > 0 &&
                              sesson.referencepoint?.map(
                                (point: any, index: number) =>
                                  index < 4 && <td key={point.wishtimesid}>{point.point !== 0 ? point.point : ''}</td>,
                              )}
                            {!sesson.referencepoint[0] && <td></td>}
                            {!sesson.referencepoint[1] && <td></td>}
                            {typeAdmission !== 3 && !sesson.referencepoint[2] && <td></td>}
                            {typeAdmission === 2 && !sesson.referencepoint[3] && <td></td>}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                ) : (
                  <td className="td-not-pl">
                    <InfoDetail
                      school={school}
                      info={school.admissioncondition}
                      id="condition"
                      title="Điều kiện tuyển sinh"
                    />
                  </td>
                )}
                <td>
                  {isCL ? (
                    <p className="school-info">{renderAdmissionPercentage(school.ratepercent)} </p>
                  ) : (
                    <InfoDetail school={school} info={school.highschoolfee} id="fee" title="Học phí" />
                  )}
                </td>
                <td>
                  <p
                    data-tip=""
                    data-for={
                      school.highschooladdresses && (school.highschooladdresses && (school.highschooladdresses && school.highschoolid.toString() + school.highschooladdresses[0]?.highschooladdressid.toString()))
                    }
                    className="school-info school-highlight"
                  >
                    Xem ngay
                  </p>
                  <ReactTooltip
                    id={school.highschooladdresses && (school.highschoolid.toString() + school.highschooladdresses[0]?.highschooladdressid.toString())}
                    place="bottom"
                    type="dark"
                    effect="float"
                    multiline={true}
                  >
                    <div className={`table-school-desk__error-modal`}>
                      <h3 className="table-school-desk__error-modal__title">{school.titletag}</h3>
                      {school.highlightpoint.split(/\r?\n/).map((value: string, index: any) => (
                        <>
                          {validURL(value) ? (
                            <div key={index} className="table-school-desk__error-modal__detail">
                              <img
                                style={{ opacity: '0' }}
                                src={ListStar}
                                className="table-school-desk__error-modal__list-item"
                              ></img>
                              <p>Xem thêm tại: {value}</p>
                            </div>
                          ) : (
                            <div key={index} className="table-school-desk__error-modal__detail">
                              <img src={ListStar} className="table-school-desk__error-modal__list-item"></img>
                              <p>{value}</p>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </ReactTooltip>
                </td>
                <td className='full-box-check' onClick={!findIndex(
                    listChecked,
                    school.highschooladdresses && (school.highschoolid.toString() + school.highschooladdresses[0]?.highschooladdressid.toString()),
                  )? ()=> {
                  
                    handleCheck(
                      school.highschoolid,
                      school.highschooladdresses[0]?.highschooladdressid,
                      school.highschooladdresses[0]?.distance,
                    )
                  } :()=>  {
                   
                    handleCheck(
                      school.highschoolid,
                      school.highschooladdresses[0]?.highschooladdressid,
                      school.highschooladdresses[0]?.distance,
                    )
                  }}>
                  
                  {findIndex(
                    listChecked,
                    school.highschooladdresses && (school.highschoolid.toString() + school.highschooladdresses[0]?.highschooladdressid.toString()),
                  ) ? (
                    <div
                    className="box-checked"
                  >
                    <FiCheck size={20} color="white"></FiCheck>
                  </div>
                  ) : (
                     <div
                      className="box-uncheck"
                    />
                  )}
                </td>
                <td>
                  <button
                    className="btn-advising"
                    onClick={(e:any) => {
                      e.stopPropagation()
                      setIsShowSupport(true);
                      setHighschoolid(school.highschoolid);
                    }}
                  >
                    TƯ VẤN
                  </button>
                </td>
              </tr>
            );
          })} 
           {(listSchoolNew.length<8 || listSchoolNew.length == 8)&&<tr style={{height: "20px"}}></tr>}
        </tbody>
      </table>
    );
    //return <NotiOverload />
  }

  
  useEffect(() => {
    handleFilterSchool();
    setPaginationNumber(0);
  }, [distanceValue, listSchool, listKindId.length]);

  useEffect(() => {
    handleClearListIdSchool();
  },[isLoadingListSchool])
    
  return (
    <div id="table-school" className={`table-school-desk`}>
      <div className="header-wrapper">
        <div className="type-school">
          <div
            className={isCL ? 'type-school__option type-school__option-selected' : 'type-school__option'}
            onClick={() => {
              setIsCL(true);
              setIsPuclicSchool(true);
              handleClearListIdSchool();
            }}
          >
            TRƯỜNG CÔNG LẬP
          </div>
          <div
            className={!isCL ? 'type-school__option type-school__option-selected' : 'type-school__option'}
            onClick={() => {
              setIsCL(false);
              setIsPuclicSchool(false);
              handleClearListIdSchool();
            }}
          >
            TRƯỜNG NGOÀI CÔNG LẬP
          </div>
        </div>
        <div className="header-title">
          <h4>Cơ hội trúng tuyển của bạn</h4>
          <div className="header-title-note"><div><p>*Thông tin này chỉ mang tính tham khảo, không mang tính quyết định.</p></div></div>
          <table>
            <thead>
              <tr>
                {isCL && (
                  <th
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '18px',
                    }}
                  >
                    {renderTypeAddmission(typeAdmission)}
                  </th>
                )}
                {isCL && (
                  <th
                    style={{
                      fontSize: '18px',
                    }}
                  >
                    Tổng điểm xét tuyển của bạn là {mytotalscore !== -1 && mytotalscore} điểm
                  </th>
                )}
                {!isCL && (
                  <th className="th-not-cl-check">
                    <div className="school-filter">
                      <p style={{ fontSize: '16px' }}>*Loại trường</p>

                      <div style={{ display: 'flex', gap: '20px', marginTop: '5px' }}>
                        {kindSchoolIdList.map((kindschool: any) => (
                          <div key={kindschool.kindschoolid} style={{ display: 'flex' }}>
                            <TableSchoolCheckBox
                              kindschool={kindschool.kindschool}
                              kindschoolid={kindschool.kindschoolid}
                              onCheck={() => handleKindSchoolCheck(kindschool.kindschoolid)}
                              onUnCheck={() => handleKindSchoolUnCheck(kindschool.kindschoolid)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </th>
                )}
                <th
                  style={{ width: typeAdmission === 2 ? '599px' : typeAdmission === 3 ? '499px' : '549px' }}
                  className={`${isCL ? '' : 'th-not-cl'}`}
                >
                  <div className="distance-filter">
                    LỌC KHOẢNG CÁCH:
                    <div className="distance-option">
                      <div className="range-wrap">
                        <div className="range-value" id="rangeV"></div>
                        <input
                          type="range"
                          min="1"
                          max="15"
                          value={distanceValue}
                          className="slider"
                          id="distanceRange"
                          onChange={handleDistanceChange}
                          style={getBackgroundSize()}
                        />
                        <div className="range-warningg" id="rangeW">
                          
                        </div>
                        
                        
                      </div>
                      <div className="landmark">
                        <p>1 km</p>
                        <p>5 km</p>
                        <p>10 km</p>
                        <p>15 km</p>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div className="list-school-header">
        <table style={{ height: '71.2px' }}>
          <thead>
            <tr className={isCL ? '' : 'sc-no-cl'}>
              <th>STT</th>
              <th>Tên trường</th>
              <th>Địa chỉ</th>
              {isCL ? (
                <th>Kỳ thi TS10</th>
              ) : (
                <th>
                  Điều kiện
                  <br />
                  tuyển sinh
                </th>
              )}
              {isCL ? <th>Chỉ tiêu</th> : <th>Học phí</th>}
              {isCL && (
                <th
                  className="ref-point-head"
                  style={typeAdmission === 2 ? { width: '200px' } : typeAdmission === 3 ? { width: '100px' } : {}}
                >
                  Điểm chuẩn
                  <br />
                  Tham khảo
                  <table className="ref-point-head__title">
                    <tbody>
                      <tr>
                        <td>NV1</td>
                        <td>NV2</td>
                        {typeAdmission !== 3 && <td>NV3</td>}
                        {typeAdmission === 2 && <td>NV4</td>}
                      </tr>
                    </tbody>
                  </table>
                </th>
              )}
              {isCL && <th>Khả năng trúng tuyển</th>}
              <th>Điểm nổi bật</th>
              <th>Chọn trường</th>
              <th>Tư vấn</th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="list-school">
        {isLoadingListSchool? <Loader />: <_ComponentListSchool />}
      </div>
      {!preventSellect && (
        
          <div className={`sticky-container ${listSchoolNew.length> 8 ? "sticky-container__bg":""}`}>
          {listSchoolNew.length> 8 ?<Pagination numbers = {Math.ceil(listSchoolNew.length/8)} onClick = {(number:any) => {
          setPaginationNumber(number)
      }}  paginationNumber = { paginationNumber} handlePrevPagnum = {handlePrevPagnum }/>:""}
          <div className={listChecked.length === 0 ? 'not-allow footer-submit' : 'footer-submit'}>
            <div className="options">
              <button className={`btn-more ${handleDisableButton()?"not-allowed":""}` } disabled={handleDisableButton()} onClick={() => navigate('/selected-result')}>
                XEM LẠI KẾT QUẢ
              </button>
              <button
                className="btn-submit"
                disabled={listChecked.length === 0}
                onClick={async (e:any) => {
                  e.stopPropagation();
                  dispatch(doStoreListChosen(listChosenSchool));
                  // dispatch(
                  //   doCreateHistorySearchAdmission({
                  //     searchadmissionid: searchadmissionid,
                  //     totalscore: mytotalscore,
                  //     publichighschools: renderListChosenSchool(),
                  //   }),
                  // );
                  const res = await apiAdmission.CreateHistorySearchAdmission({
                    searchadmissionid: searchadmissionid,
                    totalscore: mytotalscore,
                    publichighschools: renderListChosenSchool(),
                    searchsubjectid: subjectSpecialId,
                  });

                  if (res) {
                    dispatch(
                      doUpdateIsChooseHighSchool({
                        searchadmissionid: searchadmissionid,
                        publichighschoolids: listCheckedCL,
                        nonpublichighschoolids: mapIdSchoolToString(listCheckedNotCL),
                        searchaddressid: searchaddressid,
                      }),
                    );
                  }
                  setSaveResult(true);
                  setIsShowResult(!isShowResult);
                }}
              >
                LƯU KẾT QUẢ
              </button>
            </div>
          </div>
        </div>
      )}

      <ModalAspiration
        isOpen={isShowResult}
        setIsShow = {setIsShowResult}
        onClose={() => setIsShowResult(false)}
        publichighschoolids={listCheckedCL}
        nonpublichighschoolids={listCheckedNotCL}
        searchadmissionid={searchadmissionid}
        isCl={isCL}
        onClear = {handleClearListIdSchool}
      />

      <ModalRegisterSupport
        isOpen={isShowSupport}
        setIsShow = {setIsShowSupport}
        onClickHeader={() => {
          setIsShowSupport(false);
        }}
        schoolId={highschoolid}
        setIsOpen={setIsShowSupport}
      />
    </div>
  );
};
