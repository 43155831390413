import { combineReducers } from '@reduxjs/toolkit';

import modalSlice from '../slice/appSlice/modalSlice';
import persistSearchSlice from '../slice/appSlice/persistSearchSlice';
import historySearchSlice from '../slice/appSlice/historySearchSlice';
import verifySlice from '../slice/apiSlice/verifySlice';
import listSchoolSlice from '../slice/appSlice/submitSchoolSlice';
import currentFormSlice from '../slice/appSlice/userFormSlice';
import searchSlice from '../slice/apiSlice/searchSlice';
import verifingSlice from '../slice/appSlice/verifingSlice';
import stateScreenFileSlice from '../slice/appSlice/stateScreenFile';
import recommendationSlice from '../slice/appSlice/recommendationSlice';
import storeTokenReducer from '../slice/appSlice/storeTokenTemUser';
import history from '../slice/apiSlice/historySlice';
import storeListChosenChool from '../slice/appSlice/listChosenSchoolSlice';
import storeUserInfo from '../slice/appSlice/storeInfoUserSlice';
import getGoogleMapReducer from '../slice/apiSlice/getGoogleMap';
import getHistorySearchSlice from '../slice/appSlice/getHistorySearchSlice';
import getFillSearchReducer from '../slice/apiSlice/getFillSearch';
import getReportReducer from '../slice/apiSlice/getReport';
import getNumUserSearchReducer from '../slice/apiSlice/getNumUserSearchSlice';
import getListNewsReducer from '../slice/apiSlice/getListNews';
import getOneNewsReducer from '../slice/apiSlice/getOneNews';
import getBannerSliceReducer from '../slice/appSlice/getBannerSlice';

export const rootReducer = combineReducers({
  modalSlice,
  persistSearchSlice,
  verifySlice,
  listSchoolSlice,
  currentFormSlice,
  searchSlice,
  historySearchSlice,
  verifingSlice,
  stateScreenFileSlice,
  recommendationSlice,
  storeTokenReducer,
  history,
  storeListChosenChool,
  storeUserInfo,
  getGoogleMapReducer,
  getHistorySearchSlice,
  getFillSearchReducer,
  getReportReducer,
  getNumUserSearchReducer,
  getListNewsReducer,
  getOneNewsReducer,
  getBannerSliceReducer
});
