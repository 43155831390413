import { AxiosError, AxiosResponse } from 'axios';

//https://lightrains.com/blogs/axios-intercepetors-react/#:~:text=Axios%20interceptors%20are%20the%20default,or%20after%20the%20main%20method.
import { apiCreateError } from './apiCreateLogError';

export const createHeaderAPI = (baseurl: string, token: string) => ({
  baseURL: baseurl + 'api/',
  headers: {
    Accept: 'application/json',
    'content-type': 'application/json',
    Authorization: token,
    channel: 'WEB',
    component: 'TS10',
  },
  // httpsAgent: new https.Agent({
  //   rejectUnauthorized: false,
  // }),
});

export const createHeaderAPINonToken = (baseurl: string) => ({
  baseURL: baseurl + 'api/',
  headers: {
    Accept: 'application/json',
    'content-type': 'application/json',
    channel: 'WEB',
    component: 'TS10',
  },
  // httpsAgent: new https.Agent({
  //   rejectUnauthorized: false,
  // }),
});

//: AxiosResponse<{ content: any; message: string; result: number }>
export function responseAPI(res: AxiosResponse) {
  // if (res.data.result === 0) {
  //   // logout();
  // }
  return res;
}

export function responseErrorAPI(error: AxiosError) {
  
  // if (error.response?.status === 401) {
  //   // logout();
  // }
  // return Promise.reject(error);
  apiCreateError
    .createError({
      title: error.code || 'Error',
      content: error.message || 'Error',
      component: 'YOOTWAYV2',
    })
    .then(res => {
      throw error;
    });
  // throw new Error(error.response?.data.message);
}
