import React, { useEffect, useRef, useState } from 'react';
import './HeaderSearch.scss';
import LogoYoot from '../../../assets/img/draft/logo-yoot.png';
import ImageTitle from '../../../assets/img/draft/image-title.png';
import YoungMan from '../../../assets/img/draft/youngman.png';
import { DropdownTickSelect } from '../../RegisterUniversity/DropdownTickSelect/DropdownTickSelect';
import { doSetAddressId, doSetInforAddress } from '../../../redux/slice/apiSlice/getGoogleMap';
import { ModalRemind } from '../../common/ModalRemind/ModalRemind';
import { formatNumber } from '../../../helpers/app';
import { BannerAds } from '../../common/BannerAds/BannerAds';


import {
  doCreateSearchAdmission,
  doGetListPriorityObject,
  doGetListProvince,
  doGetListSecondarySchool,
  doGetListSubject,
  doGetResultSearch,
  doGetTypeAdmission,
  useAppDispatch,
  useAppSelector,
  RootState,
  doUnCheckPriorityObject,
  doSetPriorityObjects,
} from '../../../redux';

import { doStoreUserInfo } from '../../../redux/slice/appSlice/storeInfoUserSlice';
import { apiLogin } from '../../../services/aixos/apiLogin';
import { Input, Loader } from '../../common';
import { useDebounce } from '../../../hooks';
import axios from 'axios';
import { apiAdmission } from '../../../services/aixos/apiAdmission';
import { login } from '../../../utils';
import { FiCheck } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { DropdownTableSelect } from '../../common/DropdownTableSelect/DropdownTableSelect';
import { ObjecTag } from '../../common/ObjecTag/ObjectTag';
import { apiGoogleMap } from '../../../services/aixos/apiGoogleMap';
import { doGetHistorySearchSlice } from '../../../redux/slice/appSlice/getHistorySearchSlice';
import { doNumUserSearch } from '../../../redux/slice/apiSlice/getNumUserSearchSlice';

export default function HeaderSearch({ setIsShowTable }: any) {
  const dispatch: any = useAppDispatch();
  const {
    listTypeAdmission,
    listSubject,
    listPriorityObject,
    listWard,
    listDistrict,
    listProvinces,
    listSecondarySchool,
    isLoadingSearchadmissionid,
    listChoosePriorityObject,
    priorityScore
  } = useAppSelector(state => state.searchSlice);

  const { searchaddressid, street: streetReducer, ward: wardReducer, district: districtReducer, province: provinceReducer, lat: latReducer, lng: lngReducer } = useAppSelector(state => state.getGoogleMapReducer);
  const { numUserSearch } = useAppSelector(state => state.getNumUserSearchReducer);

  const [searchaddressidNew, setSearchaddressidNew] = useState(searchaddressid);
  const { phone: phoneReducer } = useSelector((state: RootState) => state.storeUserInfo);

  const { data } = useAppSelector(state => state.getFillSearchReducer);
  const [idActive, setIdActive] = useState('default');
  const [name, setName] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [phoneParent, setPhoneParent] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [address, setAddress] = useState<any>();
  const [listAddress, setListAddress] = useState<any[]>([]);
  const [isChecking, setIsChecking] = useState(false);
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const [isBoxChecked, setIsBoxChecked] = useState<any>(0);
  const [secondarySchool, setSecondarySchool] = useState<any>();
  const [listSecondarySchoolName, setListSecondarySchoolName] = useState<any[]>([]);
  const [grade, setGrade] = useState<string>('');

  const [mathScore, setMathScore] = useState<any>('');
  const [literScore, setLiterScore] = useState<any>('');
  const [englishScore, setEnglishScore] = useState<any>('');
  const [specialScore, setSpecialScore] = useState<any>('');

  const [pointPriority, setPointPriority] = useState(0);

  //Error
  const [typeAdmissionError, setTypeAdmissionError] = useState<string>('');
  const [subjectError, setSubjectError] = useState<string>('');
  // const [priorityObjectError, setPriorityObjectError] = useState<string>('');
  const [addressError, setAddressError] = useState<string>('');
  const [wardError, setWardError] = useState<string>('');
  const [districtError, setDistrictError] = useState<string>('');
  const [provinceError, setProvinceError] = useState<string>('');
  const [secondarySchoolError, setSecondarySchoolError] = useState<string>('');

  const [nameError, setNameError] = useState<string>('');
  const [mathScoreError, setMathScoreError] = useState<string>('');
  const [literScoreError, setLiterScoreError] = useState<string>('');
  const [speclialScoreError, setSpeclialScoreError] = useState<string>('');

  const [englishScoreError, setEnglishScoreError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');
  const [phoneParentError, setPhoneParentError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [gradeError, setGradeError] = useState<string>('');
  // isValid
  const [nameValid, setNameValid] = useState<boolean>(false);
  const [mathScoreValid, setMathScoreValid] = useState<boolean>(false);
  const [specialScoreValid, setSpecialScoreValid] = useState<boolean>(false);

  const [literScoreValid, setLiterScoreValid] = useState<boolean>(false);
  const [englishScoreValid, setEnglishScoreValid] = useState<boolean>(false);
  const [phoneValid, setPhoneValid] = useState<boolean>(false);
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [phoneParentValid, setPhoneParentValid] = useState<boolean>(false);

  //info search
  const [typeAdmission, setTypeAdmission] = useState(0);
  // const [priorityObject, setPriorityObject] = useState(0);
  const [subject, setSubject] = useState(0);
  const [subjectSpecialId, setSubjectSpecialId] = useState(0);
  const [subjectIntegrateId, setIntegratelId] = useState(0);

  const [ward, setWard] = useState(0);
  const [district, setDistrict] = useState(0);
  const [province, setProvince] = useState(0);

  // OTP business
  const [OTP, setOTP] = useState('');
  const [OTPError, setOTPError] = useState('');
  const [OTPSuccess, setOTPSuccess] = useState('');
  const dbPhone = useDebounce(phone, 300);
  const dbEmail = useDebounce(email, 500);

  const [isCheckingOTP, setIsCheckingOTP] = useState(false);
  const [isVerifiedPhone, setIsVerifiedPhone] = useState(false);
  const [isShowOTP, setIsShowOTP] = useState(false);

  // priority obj
  const priorityObjectIds = useRef<number[]>([]);
  //console.log("subject",subject)

  const [isShownModal, setIsShownModal] = useState(false);
  const mounted = useRef<any>(false);
  const clearScore = useRef<any>(false);


  useEffect(() => {
    setSearchaddressidNew(searchaddressid);
  }, [searchaddressid]);

  useEffect(() => {
    //console.log("mounted.current", mounted.current)
    if (mounted.current) {
      if (clearScore.current) {
        setMathScore('');
        setLiterScore('');
        setEnglishScore('');
        setSpecialScore('');
        setSpeclialScoreError('');
      }
      //setSpeclialScoreError('');
      clearScore.current = true;
    }
    else {
      setMathScore('');
      setLiterScore('');
      setEnglishScore('');
      setSpecialScore('');
      //setSpeclialScoreError('');
    }
    //setSpeclialScoreError('');

  }, [typeAdmission])

  function handleAutofill(data: any) {
    if (!typeAdmission) setTypeAdmission(data.content.search.typeadmissionid);

    if (mathScore == '') setMathScore(data.content.search.scoremath);
    if (literScore == '') setLiterScore(data.content.search.scoreliterature);
    if (englishScore == '') setEnglishScore(data.content.search.scorelanguage);

    if (subject == 0) setSubject(data.content.search.subjectid);
    if (specialScore == '') setSpecialScore(data.content.search.scoresubject);

    if (priorityObjectIds.current.length == 0) dispatch(doSetPriorityObjects({
      priorityObjectIds: data.content.search.priorityobjectids
    }))


    if (!address) setAddress({
      value: data.content.search.searchaddressid,
      label: data.content.search.address,
    });
    if (!address) dispatch(doSetAddressId(data.content.search.searchaddressid));
    let tpmList = [];
    const props = data.content.search;
    const item = {
      value: props.searchaddressid,
      label: props.address,
    };
    tpmList.push(item);
    if (!address) setListAddress(tpmList);
    if (!address) apiGoogleMap.GetGoogleMap({
      street: data.content.search.street,
      ward: data.content.search.ward,
      district: data.content.search.district,
      province: data.content.search.province,
      lat: data.content.search.lat,
      lng: data.content.search.lng
    })

    if (!address) {
      dispatch(doSetInforAddress({
        street: data.content.search.street,
        ward: data.content.search.ward,
        district: data.content.search.district,
        province: data.content.search.province,
        lat: data.content.search.lat,
        lng: data.content.search.lng
      }))
    }
    if (!secondarySchool) setSecondarySchool(data.content.search.secondaryschoolid);

    if (!grade) setGrade(data.content.search.classtext);

    if (!name) setName(data.content.search.fullname);
    if (!phone) setPhone(data.content.search.phone);
    if (!email) setEmail(data.content.search.email);
    if (!phoneParent) setPhoneParent(data.content.search.phoneparent);

    if (data.content.search.typeadmissionid == 2 && subjectSpecialId == 0) {
      setSubjectSpecialId(data.content.search.subjectid)
    }


    setPhoneValid(true);
    setMathScoreValid(true);
    setLiterScoreValid(true);
    setEnglishScoreValid(true);
    setPhoneValid(true);
    setPhoneParentValid(true);
    setEmailValid(true);
    setSpecialScoreValid(true);

    setPointPriority(data.content.search.priorityobjectpoint);
    setSearchaddressidNew(data.content.search.searchaddressid);

    //

    setTypeAdmissionError('');
    setSubjectError('');
    setSecondarySchoolError('');
    setAddressError('');
    setNameError('');
    setMathScoreError('');
    setLiterScoreError('');
    setEnglishScoreError('');
    setGradeError('');
    setPhoneError('');
    setOTPError('');
    setEmailError('');
    setPhoneParentError('');
    setSpeclialScoreError('');

    // list address
  }

  useEffect(() => {
    dispatch(doNumUserSearch())
  }, [])

  useEffect(() => {
    if (phoneReducer && !email) {
      apiAdmission
        .GetFillSearch({
          phone: phoneReducer,
          email: '',
        })
        .then(({ data }) => {
          handleAutofill(data);
          mounted.current = true;
          // setPhone(phoneReducer);
          // setName(data.content.search.fullname);
          // setGrade(data.content.search.classtext);
          // setTypeAdmission(data.content.search.typeadmissionid);
          // setEmail(data.content.search.email);
          // setMathScore(data.content.search.scoremath);
          // setLiterScore(data.content.search.scoreliterature);
          // setEnglishScore(data.content.search.scorelanguage);
          // setSecondarySchool(data.content.search.secondaryschoolid);
          // setPhoneParent(data.content.search.phoneparent);
          // setSubject(data.content.search.subjectid);
          // if(data.content.search.typeadmissionid ==  2) {
          //   setSubjectSpecialId(data.content.search.subjectid)
          // }
          // setPhoneValid(true);
          // setMathScoreValid(true);
          // setLiterScoreValid(true);
          // setEnglishScoreValid(true);
          // setPhoneParentValid(true);
          // setEmailValid(true);
          // setAddress({
          //   value: data.content.search.searchaddressid,
          //   label: data.content.search.address,
          // });
          // setSpecialScoreValid(true);
          // setSpecialScore(data.content.search.scoresubject);
          // setPointPriority(data.content.search.priorityobjectpoint);
          // setSearchaddressidNew(data.content.search.searchaddressid);
          // dispatch(doSetAddressId(data.content.search.searchaddressid));
          // //

          // setTypeAdmissionError('');
          // setSubjectError('');
          // // setPriorityObjectError('');
          // setSecondarySchoolError('');
          // setAddressError('');
          // setNameError('');
          // setMathScoreError('');
          // setLiterScoreError('');
          // setEnglishScoreError('');
          // setGradeError('');
          // setPhoneError('');
          // setOTPError('');
          // setEmailError('');
          // setPhoneParentError('');
          // setSpeclialScoreError('');

          // // list address
          // let tpmList = [];
          // const props = data.content.search;
          // const item = {
          //   value: props.searchaddressid,
          //   label: props.address,
          // };
          // tpmList.push(item);
          // setListAddress(tpmList);

          // // priority objects
          // dispatch(doSetPriorityObjects({priorityObjectIds: data.content.search.priorityobjectids}))

          // // gg map
          // apiGoogleMap.GetGoogleMap({
          //   street: data.content.search.street,
          //   ward: data.content.search.ward,
          //   district: data.content.search.district,
          //   province: data.content.search.province,
          //   lat: data.content.search.lat,
          //   lng: data.content.search.lng
          // })
        });
    }
  }, [phoneReducer]);

  useEffect(() => {
    if (phone && /(84|0[3|5|7|8|9])+([0-9]{8})\b/.test(phone)) {
      // call check phone OTPed API, if not verified yet && setShowOPT(true)
      apiAdmission.CheckUserWithPhone({ phone: phone }).then(res => {
        if (!res.data.content.token) {
          // not verified yet --> send OTP
          // setIsShowOTP(true);
          // apiLogin.sendOTP({
          //   phone: phone,
          //   type: 'PHONE',
          //   action: 'VERIFIERADMISSIONHS',
          // });
          apiLogin
            .RegisterAfterOTPAndLoginAHS({
              otp: '',
              fullname: name,
              email: email,
              phone: phone,
              action: 'VERIFIERADMISSIONHS',
            })
            .then(res => {
              setIsCheckingOTP(false);
              const resOTP = res.data.content;
              if (resOTP) {
                // setCookie myToken
                setOTPError('');
                login({ myToken: resOTP?.myToken }, '', true);
              } else {
                setOTPError('api');
                setOTPSuccess('');
              }
            })
            .catch(err => {
              console.log(err);
              setIsCheckingOTP(false);
            });
        } else {
          setIsVerifiedPhone(true);
          setIsShowOTP(false);
          setOTPError('');
          login({ myToken: res.data.content.token }, '', true);
          if (!email) {

            apiAdmission
              .GetFillSearch({
                phone: phone,
                email: "",
              })
              .then(({ data }) => {
                handleAutofill(data);
                mounted.current = true;
              });
          }

        }
      });
    }
  }, [dbPhone]);

  useEffect(() => {
    if (email && !phone) {
      apiAdmission
        .GetFillSearch({
          phone: "",
          email: email,
        })
        .then(({ data }) => {
          handleAutofill(data);
          mounted.current = true;
          // setName(data.content.search.fullname);
          // setGrade(data.content.search.classtext);
          // setTypeAdmission(data.content.search.typeadmissionid);
          // setEmail(data.content.search.email);
          // setMathScore(data.content.search.scoremath);
          // setLiterScore(data.content.search.scoreliterature);
          // setEnglishScore(data.content.search.scorelanguage);
          // setSecondarySchool(data.content.search.secondaryschoolid);
          // setPhoneParent(data.content.search.phoneparent);
          // setPhone(data.content.search.phone);
          // setSubject(data.content.search.subjectid);
          // if(data.content.search.typeadmissionid ==  2) {
          //   setSubjectSpecialId(data.content.search.subjectid)
          // }
          // // else if(data.content.search.typeadmissionid ==  3) {

          // // }
          // // setPriorityObject(data.content.search.priorityobjectid);
          // setPhoneValid(true);
          // setMathScoreValid(true);
          // setLiterScoreValid(true);
          // setEnglishScoreValid(true);
          // setPhoneValid(true);
          // setPhoneParentValid(true);
          // setEmailValid(true);
          // setAddress({
          //   value: data.content.search.searchaddressid,
          //   label: data.content.search.address,
          // });
          // setSpecialScoreValid(true);
          // setSpecialScore(data.content.search.scoresubject);
          // setPointPriority(data.content.search.priorityobjectpoint);
          // setSearchaddressidNew(data.content.search.searchaddressid);
          // dispatch(doSetAddressId(data.content.search.searchaddressid));
          // //

          // setTypeAdmissionError('');
          // setSubjectError('');
          // // setPriorityObjectError('');
          // setSecondarySchoolError('');
          // setAddressError('');
          // setNameError('');
          // setMathScoreError('');
          // setLiterScoreError('');
          // setEnglishScoreError('');
          // setGradeError('');
          // setPhoneError('');
          // setOTPError('');
          // setEmailError('');
          // setPhoneParentError('');
          // setSpeclialScoreError('');

          // // list address
          // let tpmList = [];
          // const props = data.content.search;
          // const item = {
          //   value: props.searchaddressid,
          //   label: props.address,
          // };
          // tpmList.push(item);
          // setListAddress(tpmList);

          // // priority objects
          // dispatch(doSetPriorityObjects({
          //   priorityObjectIds: data.content.search.priorityobjectids
          // }))

          // // gg map
          // apiGoogleMap.GetGoogleMap({
          //   street: data.content.search.street,
          //   ward: data.content.search.ward,
          //   district: data.content.search.district,
          //   province: data.content.search.province,
          //   lat: data.content.search.lat,
          //   lng: data.content.search.lng
          // })
        });
    }
  }, [dbEmail])

  useEffect(() => {
    if (OTP.length === 6) {
      setIsCheckingOTP(true);
      apiLogin
        .RegisterAfterOTPAndLoginAHS({
          otp: OTP,
          fullname: name,
          email: email,
          phone: phone,
          action: 'VERIFIERADMISSIONHS',
        })
        .then(res => {
          setIsCheckingOTP(false);
          const resOTP = res.data.content;
          if (resOTP) {
            // setCookie myToken
            setOTPSuccess('*Xác thực thành công!');
            setOTPError('');
            login({ myToken: resOTP?.myToken }, '', true);
          } else {
            setOTPError('*Vui lòng nhập đúng mã OTP!');
            setOTPSuccess('');
          }
        })
        .catch(err => {
          console.log(err);
          setIsCheckingOTP(false);
        });
    }
  }, [OTP]);

  // useEffect(() => {
  //   axios.post('http://180.93.172.49:8999/api/Login/Processdownload');
  // }, []);
  // useEffect(() => {
  //   if(isComfirm) handleSumit();
  // },[isComfirm])
  const validatePhone = () => {
    // if (currentUser) return true;
    if (!/(84|0[3|5|7|8|9])+([0-9]{8})\b/.test(phone) && phone) {
      setPhoneError('*Số điện thoại không chính xác!');
      setPhoneValid(false);
    } else {
      setPhoneError('');
      setPhoneValid(true);
    }
  };

  const validateEmail = () => {
    // if (currentUser) return true;
    if (!/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(email) && email) {
      setEmailError('*Email không chính xác!');
      setEmailValid(false);
    } else {
      setEmailValid(true);
      setEmailError('');
    }
  };

  const validatePhoneParent = () => {
    if (!/(84|0[3|5|7|8|9])+([0-9]{8})\b/.test(phoneParent) && phoneParent) {
      setPhoneParentValid(false);
      setPhoneParentError('*Số điện thoại không chính xác!');
    } else {
      setPhoneParentError('');
      setPhoneParentValid(true);
    }
  };

  const validateName = () => {
    if (!/([a-zA-Z ])\w*/.test(name) && name) {
      setNameValid(false);
      setNameError('*Tên không hợp lệ!');
    } else {
      setNameError('');
      setNameValid(true);
    }
  };

  const handleBlurName = () => {
    if (name == '') return;
    validateName();
  };

  const handleBlurPhoneParent = () => {
    if (phoneParent == '') return;
    validatePhoneParent();
  };

  const handleBlurPhone = () => {
    if (phone === '') return;
    validatePhone();
  };

  const handleBlurEmail = () => {
    if (email === '') return;
    validateEmail();
  };

  const handleCheckValidSubject = () => {
    if (typeAdmission == 3 || typeAdmission == 2) {
      if (!subject) return false;
    }
    return true;
  };

  const handleCheckValidTypeAdmissionWithSbjId = () => {
    if (typeAdmission == 2 && subject == 13) return false
    else return true
  }

  const handleCheckSpecialScoreExist = () => {
    if (typeAdmission == 2 || typeAdmission == 3) {
      if (specialScore) return true;
      else return false;
    }
    return true;
  }

  const checkLengthOTP = () => {
    if (OTP.length < 6 && phone && isShowOTP) {
      setOTPError("*Vui lòng nhập đúng mã OTP");
      return false;
    }
    return true;
  }

  const handleCheckCompleteInfor = () => {
    if (
      // checkLengthOTP() &&
      handleCheckValidMinimumScore() &&
      name &&
      mathScoreValid &&
      literScoreValid &&
      englishScoreValid &&
      mathScore &&
      englishScore &&
      literScore &&
      handleCheckSpecialScoreExist() &&
      (specialScoreValid || typeAdmission == 1) &&
      phoneValid &&
      emailValid &&
      phoneParentValid &&
      phone &&
      // ((isShowOTP && OTP) || !isShowOTP) &&
      phoneParent &&
      email &&
      address &&
      searchaddressid &&
      typeAdmission &&
      secondarySchool &&
      grade &&
      !OTPError &&
      handleCheckValidTypeAdmissionWithSbjId() &&
      handleCheckValidSubject() &&
      handleCheckValidMinimumScore()
    ) {
      return true;
    }
    return false;
  };

  const handleCheckValidAll = () => {
    if (isCheckingOTP) return;
    if (!handleCheckCompleteInfor()) {
      if (typeAdmission === 0) {
        setTypeAdmissionError('*Vui lòng chọn loại hình!');
      } else {
        setTypeAdmissionError('');
      }

      if (subject === 0 || (typeAdmission == 2 && subject == 13)) {
        setSubjectError('*Vui lòng chọn môn học!');
      } else {
        setSubjectError('');
      }

      // if (priorityObject === 0) {
      //   setPriorityObjectError('*Vui lòng chọn đối tượng!');
      // } else {
      //   setPriorityObjectError('');
      // }

      if (!secondarySchool) {
        setSecondarySchoolError('*Vui lòng chọn trường!');
      }

      if (!address) {
        setAddressError('*Vui lòng nhập địa chỉ!');
      }
      // if(ward==0) {
      //   setWardError('Vui lòng chọn phường')
      // }
      // if(district==0) {
      //   setDistrictError('Vui lòng chọn quận')
      // }
      // if(province==0) {
      //   setProvinceError('Vui lòng chọn thành phố')
      // }
      if (!name) {
        setNameError('*Vui lòng nhập Họ và tên!');
      }
      if (!mathScore) {
        setMathScoreError('*Vui lòng nhập điểm!');
      }
      if (!literScore) {
        setLiterScoreError('*Vui lòng nhập điểm!');
      }
      if (!englishScore) {
        setEnglishScoreError('*Vui lòng nhập điểm!');
      }
      if (!grade) {
        setGradeError('*Vui lòng nhập lớp!');
      }
      if (!phone) {
        setPhoneError('*Vui lòng nhập số điện thoại!');
      }
      // if (isShowOTP && !OTP) {
      //   setOTPError('*Vui lòng nhập OTP!!');
      // }
      if (!email) {
        setEmailError('*Vui lòng nhập email!');
      }
      if (!phoneParent) {
        setPhoneParentError('*Vui lòng nhập số điện thoại!');
      }
      if (!specialScore) {
        setSpeclialScoreError('*Vui lòng nhập điểm!');
      }
      // alert('Vui lòng nhập đầy đủ thông tin');
      return false;
    }

    return true;
  }

  const handleSumit = () => {
    // if (isCheckingOTP) return;
    if (handleCheckCompleteInfor()) {
      //call api
      dispatch(
        doStoreUserInfo({
          totalScore: parseInt(mathScore) + parseInt(literScore) + parseInt(englishScore),
          name: name,
          email: email,
          phone: phone,
          typeAdmission: typeAdmission,
          mathScore: mathScore,
          literScore: literScore,
          englishScore: englishScore,
          classtext: grade,
          phoneParent: phoneParent,
          secondarySchool: secondarySchool,
          subject: subject,
          priorityObjects: priorityObjectIds.current,
          address: address,
          specialScore: specialScore,
          pointPriority: pointPriority,
          searchaddressid: address.value,
          subjectSpecialId: (typeAdmission !== 1 ? subject : 0) || 0,
        }),
      );
      dispatch(
        doCreateSearchAdmission({
          typeadmissionid: typeAdmission,
          scoremath: mathScore || 0,
          scoreliterature: literScore || 0,
          scorelanguage: englishScore || 0,
          priorityobjectids: (typeAdmission === 1 ? priorityObjectIds.current : []) || [],
          priorityobjectpoint: (typeAdmission === 1 ? priorityScore : 0) || 0,
          subjectid: (typeAdmission !== 1 ? subject : 0) || 0,
          scoresubject: (typeAdmission !== 1 ? specialScore : 0) || 0,
          searchaddressid: address.value,
          secondaryschoolid: secondarySchool || 0,
          classtext: grade,
          fullname: name,
          phone: phone,
          email: email,
          phoneparent: phoneParent,
          isstudyabroad: isBoxChecked
        }),
      );
      dispatch(
        doGetResultSearch({
          typeadmissionid: typeAdmission || 0,
          scoremath: mathScore || 0,
          scoreliterature: literScore || 0,
          scorelanguage: englishScore || 0,
          scorepriorityobject: (typeAdmission == 1 ? priorityScore : 0) || 0,
          subjectid: (typeAdmission !== 1 ? subject : 0) || 0,
          scoresubject: (typeAdmission !== 1 ? specialScore : 0) || 0,
          searchaddressid: address.value,
          street: streetReducer,
          ward: wardReducer,
          district: districtReducer,
          province: provinceReducer,
          lat: latReducer,
          lng: lngReducer,
        }),
      );
      dispatch(doGetHistorySearchSlice({
        currentpage: 0,
        limit: 0
      }));
      setIsShowTable(true);
      document
        .getElementById('table-school')
        ?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    } else {
      if (typeAdmission === 0) {
        setTypeAdmissionError('*Vui lòng chọn loại hình!');
      } else {
        setTypeAdmissionError('');
      }

      if (subject === 0 || (typeAdmission == 2 && subject == 13)) {
        setSubjectError('*Vui lòng chọn môn học!');
      } else {
        setSubjectError('');
      }

      // if (priorityObject === 0) {
      //   setPriorityObjectError('*Vui lòng chọn đối tượng!');
      // } else {
      //   setPriorityObjectError('');
      // }

      if (!secondarySchool) {
        setSecondarySchoolError('*Vui lòng chọn trường!');
      }

      if (!address) {
        setAddressError('*Vui lòng nhập địa chỉ!');
      }
      // if(ward==0) {
      //   setWardError('Vui lòng chọn phường')
      // }
      // if(district==0) {
      //   setDistrictError('Vui lòng chọn quận')
      // }
      // if(province==0) {
      //   setProvinceError('Vui lòng chọn thành phố')
      // }
      if (!name) {
        setNameError('*Vui lòng nhập Họ và tên!');
      }
      if (!mathScore) {
        setMathScoreError('*Vui lòng nhập điểm!');
      }
      if (!literScore) {
        setLiterScoreError('*Vui lòng nhập điểm!');
      }
      if (!englishScore) {
        setEnglishScoreError('*Vui lòng nhập điểm!');
      }
      if (!grade) {
        setGradeError('*Vui lòng nhập lớp!');
      }
      if (!phone) {
        setPhoneError('*Vui lòng nhập số điện thoại!');
      }
      // if (isShowOTP && !OTP) {
      //   setOTPError('*Vui lòng nhập OTP!!');
      // }
      if (!email) {
        setEmailError('*Vui lòng nhập email!');
      }
      if (!phoneParent) {
        setPhoneParentError('*Vui lòng nhập số điện thoại!');
      }
      if (!specialScore) {
        setSpeclialScoreError('*Vui lòng nhập điểm!');
      }
      // alert('Vui lòng nhập đầy đủ thông tin');
    }
  };

  useEffect(() => {
    dispatch(doGetTypeAdmission());
    if (listPriorityObject.length < 1) dispatch(doGetListPriorityObject({}));
    dispatch(
      doGetListSecondarySchool({
        currentpage: 0,
        limit: 0,
        findtext: '',
      }),
    );
  }, []);

  // const doClearAllScore = () => {
  //   setMathScore("");
  //   setLiterScore("");
  //   setEnglishScore("");
  //   setSpecialScore("");
  // }

  useEffect(() => {
    //if(typeAdmission == 3) setSubject(13);
    dispatch(
      doGetListSubject({
        currentpage: 0,
        limit: 0,
        findtext: '',
        typeadmission: typeAdmission,
      }),
    );

  }, [typeAdmission]);

  useEffect(() => {
    if (typeAdmission == 2 && subjectSpecialId) {
      setSubject(subjectSpecialId)
    }
    else if (typeAdmission == 3) {
      setSubject(13);
    }
  }, [typeAdmission])

  const handleCheckMinimumScore = (typeAdmission: any, score: any) => {
    if (typeAdmission == 1 || typeAdmission == 3) {
      return parseFloat(score) > 0
    }
    else if (typeAdmission == 2) {
      return parseFloat(score) > 2
    }
    return true;
  }

  const handleCheckValidMinimumScore = () => {
    let isValid = true;
    if (mathScore !== "") {
      if (!handleCheckMinimumScore(typeAdmission, mathScore)) {
        setMathScoreValid(false);
        setMathScoreError("*Điểm liệt, vui lòng nhập lại")
        isValid = false;
      }
      else {
        setMathScoreValid(true);
        setMathScoreError("");
      }
    }

    if (literScore !== "") {
      if (!handleCheckMinimumScore(typeAdmission, literScore)) {
        //setMathScoreValid(false);
        setLiterScoreValid(false)
        setLiterScoreError("*Điểm liệt, vui lòng nhập lại")
        isValid = false;
      }
      else {
        setLiterScoreValid(true)
        setLiterScoreError("")
      }
    }

    if (englishScore !== "") {
      if (!handleCheckMinimumScore(typeAdmission, englishScore)) {
        //setMathScoreValid(false);
        setEnglishScoreValid(false)
        setEnglishScoreError("*Điểm liệt, vui lòng nhập lại")
        isValid = false;
      }
      else {
        setEnglishScoreValid(true)
        setEnglishScoreError("")
      }
    }


    if (specialScore !== "" && (typeAdmission == 2 || typeAdmission == 3)) {
      if (!handleCheckMinimumScore(typeAdmission, specialScore)) {
        //setMathScoreValid(false);
        setSpecialScoreValid(false);
        setSpeclialScoreError("*Điểm liệt, vui lòng nhập lại")
        isValid = true;
      }
      else {
        setSpecialScoreValid(true);
        setSpeclialScoreError("")
      }
    }

    return isValid;
  }

  // useEffect(() => {
  //   handleCheckValidMinimumScore();
  // },[typeAdmission])

  // old version
  // const renderInputLocation = () => {
  //   return (
  //     <>
  //       <div className="header-search__wrrapper-info-address">
  //         <div className="header-search__input-info-address-item">
  //           <p className="header-search__sub-label-address">TP:</p>
  //           <DropdownTickSelect
  //             idActive={idActive}
  //             setIdActive={setIdActive}
  //             zIndexHeader={94}
  //             zIndexBody={93}
  //             typeSelect={true}
  //             options={listProvinces.map((item: any) => {
  //               return { value: item.id, label: item.title };
  //             })}
  //             handleSelect={setProvince}
  //             onChange={(e: any) => {
  //               setProvinceError('');
  //             }}
  //             cancelButton={true}
  //             indexOption={listProvinces.findIndex((item: { [x: string]: number }) => {
  //               return item['id'] === province;
  //             })}
  //             isConvertIdToIndex
  //             isHaveArrowCursor
  //             propGetValForceUpdate="ind"
  //             notOnChangeWhenForceUpdate
  //             type={0}
  //             id={'city'}
  //           />
  //         </div>

  //         {provinceError !== '' && <p className="header-search__error">{provinceError}</p>}

  //         <div className="header-search__input-info-address-item">
  //           <p className="header-search__sub-label-address">Quận/Huyện:</p>
  //           <DropdownTickSelect
  //             idActive={idActive}
  //             setIdActive={setIdActive}
  //             zIndexHeader={94}
  //             zIndexBody={93}
  //             typeSelect={true}
  //             options={listDistrict.map((item: any) => {
  //               return { value: item.id, label: item.title };
  //             })}
  //             handleSelect={setDistrict}
  //             onChange={(e: any) => {
  //               setDistrictError('');
  //             }}
  //             cancelButton={true}
  //             indexOption={listDistrict.findIndex((item: { [x: string]: number }) => {
  //               return item['id'] === district;
  //             })}
  //             isConvertIdToIndex
  //             isHaveArrowCursor
  //             propGetValForceUpdate="ind"
  //             notOnChangeWhenForceUpdate
  //             type={0}
  //             id={'district'}
  //           />
  //         </div>
  //         {districtError !== '' && <p className="header-search__error">{districtError}</p>}

  //         <div className="header-search__input-info-address-item">
  //           <p className="header-search__sub-label-address">Phường/Xã:</p>
  //           <DropdownTickSelect
  //             idActive={idActive}
  //             setIdActive={setIdActive}
  //             zIndexHeader={94}
  //             zIndexBody={93}
  //             typeSelect={true}
  //             options={listWard.map((item: any) => {
  //               return { value: item.id, label: item.title };
  //             })}
  //             handleSelect={setWard}
  //             onChange={(e: any) => {
  //               setWardError('');
  //             }}
  //             cancelButton={true}
  //             indexOption={listWard.findIndex((item: { [x: string]: number }) => {
  //               return item['id'] === ward;
  //             })}
  //             isConvertIdToIndex
  //             isHaveArrowCursor
  //             propGetValForceUpdate="ind"
  //             notOnChangeWhenForceUpdate
  //             type={0}
  //             id={'ward'}
  //           />
  //         </div>
  //         {wardError !== '' && <p className="header-search__error">{wardError}</p>}
  //       </div>
  //     </>
  //   );
  // };

  const checkChosenNoPriority = (listPriorityObject: any) => {
    const index = listPriorityObject.findIndex((item: any) => {
      return item.checked && item.id == 6;
    })

    if (index !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  const handleOnDeleteObjectTag = (id: number) => {
    dispatch(doUnCheckPriorityObject({ id: id }))
  }

  const renderPriorityObjectTag = () => {
    const tags = listPriorityObject.reduce((prev: any, cur: any) => {
      if (cur.checked && cur.code) {
        prev.listObjectRender.push(
          <ObjecTag
            key={cur.id}
            id={cur.id}
            label={cur.code}
            onDelete={() => handleOnDeleteObjectTag(cur.id)}
          />
        )
        prev.listId.push(cur.id)
      }
      return prev
    }, { listObjectRender: [], listId: [] })

    priorityObjectIds.current = tags.listId;

    if (tags.listObjectRender.length) {
      return (
        <div className='header-search__objec-tag'>
          {tags.listObjectRender}
        </div>
      )
    }
  }

  const renderLabelSubject = (typeAdmission: number) => {
    if (typeAdmission == 2) {
      return (
        <p className="header-search__label">Môn chuyên:</p>
      )
    }
    else {
      return (
        <p className="header-search__label">Môn tích hợp:</p>
      )
    }
  }

  return (
    <div className="header-search" >
      {/* <div className="header-search--logo">
      <img src={LogoYoot} />
    </div> */}

      <div className="header-search--image">
        <div className="header-search--image__content">
          <div className="header-search--image__content--on">
            <img src={ImageTitle} className="header-search__image-title" />
            <img src={YoungMan} className="header-search__youngman" />
          </div>
          <div className="header-search__image-circle-background"></div>
        </div>

      </div>

      <div className="header-search__form">
        <div className="header-search__form-overlay">
          <div className="header-search__form--num-search">
            <div>Số người tra cứu</div>
            <div><p>{formatNumber(numUserSearch)}</p></div>
          </div>
          <p className="header-search__label">Loại hình:</p>
          <DropdownTickSelect
            className="type-select"
            error={typeAdmissionError}
            idActive={idActive}
            setIdActive={setIdActive}
            zIndexHeader={10}
            zIndexBody={9}
            typeSelect={true}
            options={listTypeAdmission.map((item: any) => {
              return { value: item.id, label: item.title };
            })}
            handleSelect={setTypeAdmission}
            onChange={(e: any) => {
              setTypeAdmissionError('');
              setIsShowTable(false);
            }}
            indexOption={listTypeAdmission.findIndex((item: { [x: string]: number }) => {
              return item['id'] === typeAdmission;
            })}
            isConvertIdToIndex={true}
            type={0}
            id={'type'}
            onClear={() => {
              setTypeAdmission(0)
            }}
          />
          {typeAdmissionError !== '' && <p className="header-search__error">{typeAdmissionError}</p>}

          <p className="header-search__label">Nhập điểm thí sinh dự đoán đạt được trong kỳ thi tuyển sinh 10:</p>
          <div className="header-search__wrrapper-score">
            <p className="header-search__sub-label-score">
              Điểm Toán:
              <Input
                className="input-score"
                onChange={(item: any) => {
                  if (item.target.value.length < 5) setMathScore(item.target.value);
                  setIsShowTable(false);
                }}
                type="number"
                validation="avgPoint"
                value={mathScore}
                setValid={(valid: any) => {
                  setMathScoreValid(valid);
                }}
                maxLength={4}
                onFocus={() => setMathScoreError('')}
                error={mathScoreError}
                typeAdmission={typeAdmission}
              />
            </p>

            <p className="header-search__sub-label-score">
              Điểm Ngữ văn:
              <Input
                className="input-score"
                onChange={(item: any) => {
                  if (item.target.value.length < 5) setLiterScore(item.target.value);
                  setIsShowTable(false);
                }}
                type="number"
                validation="avgPoint"
                value={literScore}
                setValid={(valid: any) => {
                  setLiterScoreValid(valid);
                }}
                maxLength={4}
                onFocus={() => setLiterScoreError('')}
                error={literScoreError}
                typeAdmission={typeAdmission}
              />
            </p>

            <p className="header-search__sub-label-score">
              Điểm Ngoại Ngữ:
              <Input
                className="input-score"
                onChange={(item: any) => {
                  if (item.target.value.length < 5) setEnglishScore(item.target.value);
                  setIsShowTable(false);
                }}
                type="number"
                validation="avgPoint"
                value={englishScore}
                setValid={(valid: any) => {
                  setEnglishScoreValid(valid);
                }}
                maxLength={4}
                onFocus={() => setEnglishScoreError('')}
                typeAdmission={typeAdmission}
                error={englishScoreError}
              />
            </p>
          </div>
          {typeAdmission !== 1 && typeAdmission !== 0 && (
            <div className="header-search__wrrapper-score">
              <div className="header-search__item-info" style={{ width: '66%' }}>
                {renderLabelSubject(typeAdmission)}
                <DropdownTickSelect
                  className="subject-select"
                  idActive={idActive}
                  setIdActive={setIdActive}
                  zIndexHeader={8}
                  zIndexBody={7}
                  typeSelect={true}
                  options={listSubject.map((item: any) => {
                    return { value: item.id, label: item.title };
                  })}
                  handleSelect={(id: any) => {
                    setSubject(id);
                    setIsShowTable(false);
                    if (typeAdmission == 2) {
                      setSubjectSpecialId(id)
                    }
                  }
                  }
                  onChange={(e: any) => {
                    setSubjectError('');
                  }}
                  cancelButton={true}
                  indexOption={listSubject.findIndex((item: { [x: string]: number }) => {
                    return item['id'] === subject;
                  })}
                  isConvertIdToIndex={true}
                  type={0}
                  id={'specialized subject'}
                  onClear={() => {
                    setSubject(0);
                  }}
                  isIntegrate={typeAdmission == 3} // neu la tich hop rang khong cho user bo tick chon
                />

                {subjectError !== '' && <p className="header-search__error">{subjectError}</p>}
              </div>
              <div style={{ width: '160px', margin: '15px 0 0 20px' }}>
                <p className="header-search__sub-label-score">
                  Nhập điểm:
                  <Input
                    className="input-score"
                    onChange={(item: any) => {
                      if (item.target.value.length < 5) setSpecialScore(item.target.value);
                      setIsShowTable(false);
                    }}
                    type="number"
                    validation="avgPoint"
                    value={specialScore}
                    setValid={(valid: any) => {
                      setSpecialScoreValid(valid);
                    }}
                    maxLength={4}
                    onFocus={() => setSpeclialScoreError('')}
                    error={speclialScoreError}
                    typeAdmission={typeAdmission}
                  />
                </p>
              </div>
            </div>
          )}
          {typeAdmission == 1 && (
            <>
              <p className="header-search__label">Chọn đối tượng được điểm ưu tiên:</p>
              {/* <DropdownTickSelect
              idActive={idActive}
              setIdActive={setIdActive}
              zIndexHeader={96}
              zIndexBody={95}
              typeSearch={true}
              placeholder="Chọn đối tượng được điểm ưu tiên.."
              options={listPriorityObject.map((item: any) => {
                return { value: item.id, label: item.title, point: item.point };
              })}
              handleSelect={(id: any, point: any) => {
                setPriorityObject(id);
                setPointPriority(point);
              }}
              onChange={(e: any) => {
                setPriorityObjectError('');
              }}
              cancelButton={true}
              indexOption={listPriorityObject.findIndex((item: { [x: string]: number }) => {
                return item['id'] === priorityObject;
              })}
              isConvertIdToIndex={true}
              
              id={'priority object'}
            /> */}

              <DropdownTableSelect
                totalScore={priorityScore}
                idActive={idActive}
                setIdActive={(id: any) => {
                  setIdActive(id);
                  setIsShowTable(false);
                }}
                zIndexHeader={6}
                zIndexBody={5}
                options={listPriorityObject}
                id={'priority object'}
                isChosenNoPriority={listPriorityObject.length ? checkChosenNoPriority(listPriorityObject) : false}
              />
              {renderPriorityObjectTag()}
              {/* {priorityObjectError !== '' && <p className="header-search__error">{priorityObjectError}</p>} */}
            </>
          )}
          <p className="header-search__label">Nơi ở hiện tại:</p>
          <div className="header-search__input-info-address-item">
            <p className="header-search__sub-label-address">Địa chỉ:</p>
            <DropdownTickSelect
              className="address-select"
              idActive={idActive}
              setIdActive={(id: any) => {
                setIdActive(id);
                setIsShowTable(false);
              }}
              placeholder="Nhập địa chỉ tương đối gần nhà."
              zIndexHeader={4}
              zIndexBody={3}
              typeSearch={true}
              options={listAddress}
              handleSelect={setAddress}
              onChange={(e: any) => {
                setAddressError('');
              }}
              cancelButton={true}
              indexOption={!!address && listAddress?.findIndex(item => item.id === address.id)}
              //handleGetGoogleMap={handleGetGoogleMap}
              isConvertIdToIndex
              isHaveArrowCursor
              propGetValForceUpdate="ind"
              notOnChangeWhenForceUpdate
              type={4}
              // relatedSearch={address}
              id={'address'}
              onClear={() => {
                setAddress(0);
                setListAddress([]);
              }}
            />
            {addressError !== '' && <p className="header-search__error">{addressError}</p>}
          </div>

          <p className="header-search__label">Thông tin cá nhân:</p>
          <div className="header-search__wrrapper-info">
            <div className="header-search__item-info info-school">
              <p className="header-search__sub-label-info">Chọn trường THCS:</p>
              <DropdownTickSelect
                idActive={idActive}
                setIdActive={(id: any) => {
                  setIdActive(id)
                  setIsShowTable(false)
                }}
                placeholder="Nhập tên trường..."
                zIndexHeader={2}
                zIndexBody={1}
                typeSearch={true}
                options={listSecondarySchool.map((item: any) => {
                  return { value: item.id, label: item.name };
                })}
                handleSelect={setSecondarySchool}
                onChange={(e: any) => {
                  setSecondarySchoolError('');
                }}
                cancelButton={true}
                indexOption={listSecondarySchool.findIndex((item: { [x: string]: number }) => {
                  return item['id'] === secondarySchool;
                })}
                isConvertIdToIndex
                isHaveArrowCursor
                propGetValForceUpdate="ind"
                notOnChangeWhenForceUpdate
                type={5}
                id={'secondary school'}
                onClear={() => {
                  setSecondarySchool(0);
                  setListSecondarySchoolName([]);
                }}
              />
              {secondarySchoolError !== '' && <p className="header-search__error">{secondarySchoolError}</p>}
            </div>
            <div className="header-search__item-info info-class">
              <p className="header-search__sub-label-info">
                Tên lớp:
                <Input
                  className="header-search__sub-label-info__OTP"
                  onChange={(item: any) => {
                    setIsShowTable(false);
                    setGrade(item.target.value);
                  }}
                  maxLength={5}
                  error={gradeError}
                  value={grade}
                  onFocus={() => {
                    setGradeError('');
                  }}
                />
              </p>
            </div>
          </div>

          <div className="header-search__wrrapper-info">
            <p className="header-search__sub-label-info">
              *Họ và tên:
              <Input
                onChange={(item: any) => {
                  setIsShowTable(false);
                  setName(item.target.value);
                }}
                error={nameError}
                value={name}
                validation="name"
                onFocus={() => {
                  setNameError('');
                }}
                onBlur={e => handleBlurName()}
              />
            </p>

            <p className="header-search__sub-label-info">
              *Số điện thoại:
              <Input
                className="header-search__sub-label-info__phone"
                onChange={(item: any) => {
                  if (item.target.value.length < 11) setPhone(item.target.value.trim());
                  setIsShowTable(false);
                }}
                pattern="[0-9]*"
                type="number"
                error={phoneError || OTPError}
                successFlag={OTPSuccess}
                validation="phone"
                maxLength={10}
                onFocus={() => {
                  setPhoneError('');
                }}
                value={phone}
                onBlur={e => handleBlurPhone()}
              />
            </p>

            {isShowOTP && (
              <p className="header-search__sub-label-info" style={{ marginLeft: '-6px' }}>
                *OTP
                <Input
                  className="header-search__sub-label-info__OTP"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.length < 7) setOTP(e.target.value);
                  }}
                  pattern="[0-9]*"
                  type="number"
                  validation="phone"
                  maxLength={6}
                  value={OTP}
                />
              </p>
            )}
          </div>

          <div className="header-search__wrrapper-info">
            <p className="header-search__sub-label-info">
              *Email:
              <Input
                onChange={(item: any) => {
                  setEmail(item.target.value.trim());
                  setIsShowTable(false);
                }}
                error={emailError}
                validation="email"
                onFocus={() => {
                  setEmailError('');
                }}
                value={email}
                onBlur={e => handleBlurEmail()}
              />
            </p>

            <p className="header-search__sub-label-info">
              *SĐT Phụ huynh:
              <Input
                className="header-search__sub-label-info__phone"
                onChange={(item: any) => {
                  if (item.target.value.length < 11) setPhoneParent(item.target.value.trim());
                  setIsShowTable(false);
                }}
                pattern="[0-9]*"
                type="number"
                error={phoneParentError}
                validation="phone"
                maxLength={10}
                onFocus={() => {
                  setPhoneParentError('');
                }}
                value={phoneParent}
                onBlur={e => handleBlurPhoneParent()}
              />
            </p>
          </div>

          <div className="require-aboard">
            {isBoxChecked ? (
              <div className="box-checked" onClick={() => setIsBoxChecked(0)}>
                <FiCheck size={20} color="white"></FiCheck>
              </div>
            ) : (
              <div className="uncheck-box" onClick={() => setIsBoxChecked(1)}></div>
            )}

            <p>Bạn có nhu cầu du học? Click chọn để được tư vấn thông tin mới nhất!</p>
          </div>

          <button className="header-search-action" onClick={() => {
            if (handleCheckValidAll()) {
              setIsShownModal(true);
            }
            //if(isShownModal) handleSumit()
          }}>
            Xem Ngay
          </button>
        </div>
      </div>
      <ModalRemind
        isOpen={isShownModal}
        //setIsOpen = {setIsShownModal}
        onClose={() => setIsShownModal(false)}
        setComfirm={() => {
          handleSumit();
        }}
      />
    </div>
  );
}
