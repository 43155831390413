export const dataGender = [
  {
    id: 0,
    title: 'Nam',
  },
  {
    id: 1,
    title: 'Nữ',
  },
];

export const dataCharacters = [
  {
    title: 'R: Realistic - Người thực tế',
    heading: 'R: Realistic - Kiểu người thực tế',
    content: `Người thực tế: (tổng điểm số của bảng A là cao nhất so với các bảng khác)
     Người thuộc nhóm sở thích nghề nghiệp này thường có khả năng về kỹ thuật, công nghệ, hệ thống; ưa thích làm việc với đồ vật, máy móc, động thực vật; thích làm các công việc ngoài trời.
     Ngành nghề phù hợp với nhóm này bao gồm những nghề về kiến trúc, xây dựng và dân dụng công nghiệp, nghề mộc, xây dựng, thủy sản, kỹ thuật, máy tàu thủy, lái xe, huấn luyện viên, nông - lâm nghiệp (quản lý trang trại, nhân giống cá, lâm nghiệp...), cơ khí (chế tạo máy, bảo trì và sửa chữa thiết bị, luyện kim, cơ khí ứng dụng, tự động...), điện - điện tử, địa lý - địa chất (đo đạc, vẽ bản đồ địa chính), dầu khí, hải dương học, quản lý công nghiệp...`,
  },
  {
    title: 'I: Investigative - Người nghiên cứu',
    heading: 'I: Investigative - Kiểu Người nghiên cứu',
    content: `Người nghiên cứu: (tổng điểm số của bảng B là cao nhất so với các bảng khác)
     Người thuộc nhóm ngành này có khả năng về quan sát, khám phá, phân tích đánh giá và giải quyết các vấn đề.
     Ngành nghề phù hợp với nhóm này bao gồm: Các ngành thuộc lĩnh vực khoa học tự nhiên (toán, lý, hóa, sinh, địa lý, địa chất, thống kê...); khoa học xã hội (nhân học, tâm lý, pháp luật...); y - dược (bác sĩ, y sĩ, dược sĩ, kỹ thuật lâm sàng,…); khoa học công nghệ (công nghệ thông tin, quản trị mạng máy tính; môi trường, điện, vật lý kỹ thuật, xây dựng...); nông lâm (nông học, thú y...).`,
  },
  {
    title: 'A : Artistic - Người có tính nghệ sĩ',
    heading: 'A : Artistic - Kiểu Người có tính nghệ sĩ',
    content: `Nghệ sỹ: (tổng điểm số của bảng C là cao nhất so với các bảng khác)
     Có khả năng về nghệ thuật, khả năng về trực giác, khả năng tưởng tượng cao, thích làm việc trong các môi trường mang tính ngẫu hứng, không khuôn mẫu.
     Ngành nghề phù hợp với nhóm này bao gồm: Các ngành về văn chương; báo chí (bình luận viên, dẫn chương trình...); điện ảnh, sân khấu, mỹ thuật, ca nhạc, múa, kiến trúc, thiết kế, thời trang, hội họa, giáo viên dạy sử/Anh văn, bảo tàng, bảo tồn...`,
  },
  {
    title: 'S: Social - người có Tính xã hội',
    heading: 'S: Social - Kiểu người có Tính xã hội',
    content: ` Xã hội: (tổng điểm số của bảng D là cao nhất so với các bảng khác)
     Bạn có khả năng về ngôn ngữ, giảng giải, thích làm những việc như giảng giải, cung cấp thông tin, sự chăm sóc, giúp đỡ, hoặc huấn luyện cho người khác.
     Ngành nghề phù hợp với nhóm này bao gồm: sư phạm; giảng viên; hướng dẫn viên du lịch; tư vấn - hướng nghiệp; công tác xã hội, sức khỏe cộng đồng, bác sĩ chuyên khoa, thẩm định giá, tuyển dụng nhân sự, cảnh sát, xã hội học, bà đỡ, điều dưỡng, chuyên gia về X-quang, chuyên gia dinh dưỡng...`,
  },
  {
    title: 'E: Enterprising - Người dám nghĩ dám làm',
    heading: 'E: Enterprising - Kiểu Người dám nghĩ dám làm',
    content: `Thiên phú lãnh đạo: (tổng điểm số của bảng E là cao nhất so với các bảng khác)
     Có khả năng về kinh doanh, mạnh bạo, dám nghĩ dám làm, có thể gây ảnh hưởng, thuyết phục người khác, có khả năng quản lý.
     Ngành nghề phù hợp với nhóm này bao gồm: Các ngành về quản trị kinh doanh (quản lý khách sạn, quản trị nhân sự...), thương mại, marketing, kế toán – tài chính, luật sư, dịch vụ khách hàng, tiếp viên hàng không, thông dịch viên, pha chế rượu, kỹ sư công nghiệp (ngành kỹ thuật hệ thống công nghiệp), bác sĩ cấp cứu, quy hoạch đô thị, bếp trưởng (nấu ăn), báo chí (phóng viên, biên tập viên...)...
     `,
  },
  {
    title: 'C: Conventional - người công chức',
    heading: 'C: Conventional - Kiểu người công chức',
    content: `Mẫu người công chức: (tổng điểm số của bảng F là cao nhất so với các bảng khác)
     Có khả năng về số học, thích thực hiện những công việc chi tiết, thích làm việc với những số liệu, theo chỉ dẫn của người khác hoặc các công việc văn phòng.
     Ngành nghề phù hợp với nhóm này bao gồm: Các ngành nghề về hành chính, thống kê, thanh tra ngành, người giữ trẻ, kế toán, điện thoại viên...`,
  },
];

export const MAX_SCORE_DISC = 20;
export const MIN_SCORE_DISC = 0;

export const LINK_TO_YOOTLINK = 'https://web.yoot.vn/';

export const monthNames = [
  'Tháng 1',
  'Tháng 2',
  'Tháng 3',
  'Tháng 4',
  'Tháng 5',
  'Tháng 6',
  'Tháng 7',
  'Tháng 8',
  'Tháng 9',
  'Tháng 10',
  'Tháng 11',
  'Tháng 12',
];

export let currDate = new Date();

export const SideID = {
  left: 1,
  right: 2,
  bottom: 3,
  top: 4,
  bodyCenter: 5,
};

export const Grade = [
  {'id': 0, 'grade': 'Lớp 10'},
  {'id': 1, 'grade': 'Lớp 11'},
  {'id': 2, 'grade': 'Lớp 12'},
]

export const trainingLevel = [
  {id: 10, name: 'Đại học'},
  {id: 20, name: 'Cao đẳng'},
  {id: 30, name: 'Trung cấp'},
  {id: 40, name: 'Trung tâm đào tạo'},
  {id: 50, name: 'Học viện'},
  {id: 60, name: 'Sơ cấp'},
  {id: 70, name: 'Sau đại học'},
  {id: 80, name: 'Nhạc viện'},
];

export const dataSideBar = [
  {
    name: 'YOOT WAY',
    path: '/',
  },
  {
    name: 'Kết quả hướng nghiệp',
    path: '/account',
  },
  {
    name: 'Cơ hội trúng tuyển',
    path: 'https://cohoitrungtuyen.vn/',
  },
  {
    name: 'Cơ hội du học',
    path: 'https://yootworld.yoot.vn/',
  },
  {
    name: 'Thư viện hướng nghiệp',
    path: 'https://yootway.yoot.vn/',
  },
  {
    name: 'Liên hệ',
    path: '/',
  },
  
];

export const PASS_CORS_KEY = "875c0462-6309-4ddf-9889-5227b1acc82c";