import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiAdmission } from "../../../services/aixos/apiAdmission";


export const doGetListNews = createAsyncThunk(
    'get@GetListNews',
    async (params: any) => {
      const result = await apiAdmission.getListNews(params);
      return result.data;
    }
  )

const initialState = { 
    data: [],
    dataFull: [],
    isLoading: false,
    error: {}
}

const getListNews = createSlice({
    name: 'GetFil@',
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(doGetListNews.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(doGetListNews.fulfilled, (state, action) => {
            if(action.payload.content.news.length > 8) {
                state.data = action.payload.content.news.slice(0,8);
            }
            else  {
                state.data = action.payload.content.news;
            }
            state.dataFull = action.payload.content.news;
            state.isLoading = false;
        })
        builder.addCase(doGetListNews.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        })
    },
})

const {reducer: getListNewsReducer, actions } = getListNews
export default getListNewsReducer;