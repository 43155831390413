import { createSlice } from '@reduxjs/toolkit';

type TSearchPersist = {
  ologyID: number;
  typeID: number;
  subjects: Array<any>;
  pointsArr: Array<any>;
  namePersist: string;
  phonePersist: string;
  emailPersist: string;
  referralCodePersist: string;
  shouldUpdateInHeaderSearch: boolean;
};

const initialState = {
  ologyID: 0,
  typeID: 0,
  pointsArr: [],
  subjects: [],
  namePersist: '',
  phonePersist: '',
  emailPersist: '',
  referralCodePersist: '',
  shouldUpdateInHeaderSearch: true,
} as TSearchPersist;

export const historySearchSlice = createSlice({
  name: 'historySearch',
  initialState: initialState,
  reducers: {
    doStoreHistorySearchParam(state, action) {
      state.ologyID = action.payload.ology;
      state.typeID = action.payload.type;
      state.pointsArr = action.payload.points;
      state.subjects = action.payload.subjectIDs;
      state.namePersist = action.payload.name;
      state.phonePersist = action.payload.phone;
      state.emailPersist = action.payload.email;
      state.referralCodePersist = action.payload.referralCode;
    },

    doClearHistorySearchParam(state) {
      state.ologyID = 0;
      state.typeID = 0;
      state.pointsArr = [];
      state.subjects = [];
      state.namePersist = '';
      state.phonePersist = '';
      state.emailPersist = '';
      state.referralCodePersist = '';
    },
    doSetShouldUpdateHistorySearchParam(state, action) {
      state.shouldUpdateInHeaderSearch = action.payload || !state.shouldUpdateInHeaderSearch;
    },
  },
});

const { actions, reducer } = historySearchSlice;
export const {
  doStoreHistorySearchParam,
  doClearHistorySearchParam,
  doSetShouldUpdateHistorySearchParam,
} = actions;
export default reducer;
