import Youngboiunderthehood from '../../assets/img/draft/youngman2.png';

import './Report.scss';
import { useSelector, useDispatch } from 'react-redux';
import { doGetReport } from '../../redux/slice/apiSlice/getReport';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderLayout } from '../../layouts/HeaderLayout/HeaderLayout';
import { Calendar } from '../../components/common';
import { useState } from 'react';
import { Button } from '../../components/common';
import { DropdownTotalTime } from '../../components/common/DropdownTotalTime/DropdownTotalTime';
import moment from 'moment';
export const Report = ({ }: any) => {
  const { dataReport, errorReport, isLoadingReport } = useSelector((state: any) => state.getReportReducer);
  const dispatch = useDispatch();
  const history = useNavigate();
 
  const [isShowCalendar, setIsShowCalendar] = useState<boolean>(false);

  //========== Hard code start Date - end Date ===========
  const valueStart = new Date();
  valueStart.setDate(1);
  valueStart.setMonth(9);
  valueStart.setFullYear(2022);
  valueStart.setHours(0o0, 0o0, 0o0);

  const valueEnd = new Date();
  valueEnd.setHours(23, 59, 59);

  const [valueCalendarStart, setValueCalendarStart] = useState<Date>(valueStart);
  const [valueCalendarEnd, setValueCalendarEnd] = useState<Date>(valueEnd);
  //============Handle Calendar Filter===========
  console.log("valueStart",valueStart)
  console.log("valueEnd",valueEnd)
  useEffect(() => {
    dispatch(
      doGetReport({
        currentpage: 0,
        limit: 0,
        fromdate: moment(valueStart).format('YYYY-MM-DD') + " " + "00:00:00",
        todate:moment(valueEnd).format('YYYY-MM-DD') + " " + "23:59:59"
      })
    )
  }, [])
  const handleSelectCalendarStart = (time: Date) => {
    time.setHours(0o0, 0o0, 0o0);
    setValueCalendarStart(time);
    // setValueCalendarStartSelected(time);
  };

  const handleSelectCalendarEnd = (time: Date) => {
    time.setHours(23, 59, 59);
    setValueCalendarEnd(time);
    // setValueCalendarEndSelected(time);
  };

  const compare_date = (date1: Date, date2: Date) => {
    if (date1.getTime() <= date2.getTime()) {
      return true;
    } else if (date1.getTime() > date2.getTime()) {
      return false;
    }
  };
  const handleClickShowCalendar = (e: any) => {
    setIsShowCalendar(isShowCalendar => !isShowCalendar);
  };
  let validateTime = compare_date(valueCalendarStart, valueCalendarEnd);
  useEffect(() => {
    const header = document.querySelector('.header-layout') as HTMLElement;
    const table = document.querySelector('.report__table') as HTMLElement;
    const origOffsetY = table.offsetTop;
    setTimeout(() => {
      document.addEventListener('scroll', () => {
        if (header && window.scrollY > origOffsetY + 120) header.style.top = '-80px'
        else header.style.top = '0'
      })
    }, 1000)
  }, [])

  return (
    <>
      <HeaderLayout isHideMenu={true} />
      <div className={`report`}>
        <p className='report__title'>BÁO CÁO SỐ LƯỢNG HỌC SINH VÀO TRANG TUVANNGUYENVONGTS10.VN</p>
        <div style={{ position: 'relative' }}>
          <div onClick={e => handleClickShowCalendar(e)}>
            <DropdownTotalTime
              id="wishtimeOption"
              onChange={(value: any) => {
                // setMessErrorWishTime('');
                // handleClickShowCalendar(value);
              }}
              // onClick = {(e) => handleClickShowCalendar(e)}
              // onClick = {() => {}}
              className="total-information__select"
              classNameHeader="time-select"
              classNameBody="time-body"
              valueCalendarStart={valueCalendarStart}
              valueCalendarEnd={valueCalendarEnd}
              options={[]}
            />
          </div>

          {isShowCalendar ? (
            <div className="container-calendar">
              <div className="calendar-section">
                <Calendar
                  isBeforeNow={true}
                  value={valueCalendarStart}
                  // constraintDateBefore={new Date()}
                  constraintDateAfter={new Date()}
                  isShow={true}
                  onSelect={handleSelectCalendarStart}
                  titleStart={true}
                  validateTime={validateTime}
                />

                <Calendar
                  isBeforeNow={true}
                  value={valueCalendarEnd}
                  // constraintDateBefore={undefined}
                  constraintDateAfter={new Date()}
                  isShow={true}
                  onSelect={handleSelectCalendarEnd}
                  titleStart={false}
                  validateTime={validateTime}
                />
              </div>

              <p className="error-message" style={{ display: validateTime ? 'none' : 'block' }}>
                *Ngày bắt đầu phải trước ngày kết thúc!
              </p>

              <div className="container-calendar__button">
                <Button
                  type="button"
                  onClick={(e: any) => {
                    if (validateTime) {
                      handleClickShowCalendar(e);
                      dispatch(dispatch(
                        doGetReport({
                          currentpage: 0,
                          limit: 0,
                          fromdate: moment(valueCalendarStart).format('YYYY-MM-DD') + " " + "00:00:00",
                          todate:moment(valueCalendarEnd).format('YYYY-MM-DD') + " " + "23:59:59"
                        })
                      ))
                    }
                  }}
                >
                  Xem
                </Button>
              </div>
            </div>
          ) : null}
        </div>
        
        <table className='report__table'>
          <thead>
            <tr style={{ height: '71.2px' }}>
              <th>STT</th>
              <th>Thầy/Cô tư vấn</th>
              <th>Tổng số học sinh</th>
              <th>
                Tổng số học sinh dùng ứng dụng
              </th>
              <th>
                Tỉ lệ (%)
              </th>
            </tr>
          </thead>
          <tbody>
            {
              dataReport?.consultants?.map((item: any, index: any) => {
                return <tr key={index} onClick={() => {
                  history(`/report/${item.id}`)
                }}>
                  <td>{index + 1}</td>
                  <td style={{ cursor: "pointer" }}>{item.name}</td>
                  <td>{item.totalnumstudent}</td>
                  <td>{item.totalusersearch}</td>
                  <td>{item.percentsearchconsultant}</td>
                </tr>
              })
            }
            <tr className="report__sum-row" key='sum'>
              <td colSpan={2}>Tổng cộng</td>
              <td>{dataReport?.sumnumstudent}</td>
              <td>{dataReport?.sumusersearch}</td>
              <td>{dataReport?.sumpercentsearch}%</td>
            </tr>
          </tbody>
        </table>
        {/* <div className={`${(listSchoolNew?.length === 0) ? "" : "no-item"}` + `${(listSchoolNew?.length <= 8 ) ? " noscroll" : ""}` + " list-school" } >{isLoading?<Loader />:<_ComponentListSchool></_ComponentListSchool>}</div> */}
        {/* {true ? (
          <div className="container-calendar">
            <div className="calendar-section">
              <Calendar
                isBeforeNow={true}
                value={valueCalendarStart}
                // constraintDateBefore={new Date()}
                constraintDateAfter={new Date()}
                isShow={true}
                onSelect={handleSelectCalendarStart}
                titleStart={true}
                validateTime={validateTime}
              />

              <Calendar
                isBeforeNow={true}
                value={valueCalendarEnd}
                // constraintDateBefore={undefined}
                constraintDateAfter={new Date()}
                isShow={true}
                onSelect={handleSelectCalendarEnd}
                titleStart={false}
                validateTime={validateTime}
              />
            </div>

            <p className="error-message" style={{ display: validateTime ? 'none' : 'block' }}>
              *Ngày bắt đầu phải trước ngày kết thúc!
            </p>

            <div className="container-calendar__button">
              <Button
                type="button"
                // onClick={(e: any) => {
                //   if (validateTime) {
                //     setPaginationNumber(0);
                //     // setListRender(FilterByDate(listReport));
                //     handleFilterSearch();
                //     handleClickShowCalendar(e);
                //   }
                // }}
              >
                Xem
              </Button>
            </div>
          </div>
        ) : null} */}
      </div>
    </>

  );
};
