import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiAdmission } from "../../../services/aixos/apiAdmission";


export const doNumUserSearch = createAsyncThunk(
    'get@getNumUserSearch',
    async () => {
      const result = await apiAdmission.getNumUserSearch();
      return result.data;
    }
  )

const initialState = { 
    numUserSearch: 0,
    isLoading: false,
    error: {}
}

const getNumUserSearch = createSlice({
    name: 'GetFillSearch@',
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(doNumUserSearch.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(doNumUserSearch.fulfilled, (state, action) => {
            state.numUserSearch = action.payload?.content?.numUserSearch || 0;
            state.isLoading = false;
        })
        builder.addCase(doNumUserSearch.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        })
    },
})

const {reducer: getNumUserSearchReducer, actions } = getNumUserSearch
export default getNumUserSearchReducer;