import { Modal } from '../Modal/Modal';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doGetOneNews } from '../../../redux/slice/apiSlice/getOneNews';
import './ModalPopupNews.scss';
import { BtnClose } from '../../../assets/svg/BtnClose';
import { pauseVideo } from '../../../utils';
export const ModalPopupNews: React.FC<any> = ({
  isOpen,
  setIsShow,
  newsid,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {news} = useSelector((state:any) => {
  return state.getOneNewsReducer
  })
  
  useEffect(() => {
    dispatch(doGetOneNews({
      newsid: newsid,
    }))
  }, [newsid])
  
  return (
    <Modal isShow={isOpen} setIsShow = {setIsShow}>
        <div className='modal-popup-news'>
          <div className='btn-close' onClick={() => {
            onClose()
            pauseVideo();
            }}>
              <BtnClose />
          </div>
          <div className='content'>
           {news.content ? <div className='content__scroll'><div dangerouslySetInnerHTML={{__html: news.content}} /></div>: <div>Chưa có tin tức mới</div>}
          </div>
        </div>
    </Modal>
  );
};