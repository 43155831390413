import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiGeneral } from '../../../services/aixos/apiGeneral';
import { apiAdmission } from '../../../services/aixos/apiAdmission';


//===========================NEW=================================
export const doGetTypeAdmission = createAsyncThunk(
  'search@GetTypeAdmission',
  async () => {
    const result = await apiGeneral.GetTypeAdmission();
    return result.data;
  }
)

export const doGetListPriorityObject = createAsyncThunk(
  'search@GetListPriorityObject',
  async (params: IParamsNormalApi) => {
    const result = await apiGeneral.GetListPriorityObject(params);
    return result.data;
  }
)

export const doGetListSubject = createAsyncThunk(
  'search@GetListSubject',
  async (params: IParamsGetListSubject) => {
    const result = await apiGeneral.GetListSubject(params);
    return result.data;
  }
)

export const doGetListSecondarySchool = createAsyncThunk(
  'search@GetListSecondarySchool',
  async (params: IParamsGetListSecondarySchool) => {
    const result = await apiGeneral.GetListSecondarySchool(params);
    return result.data;
  }
)

export const doGetListProvince = createAsyncThunk(
  'search@GetListProvince',
  async (params: IParamsNormalApi) => {
    const result = await apiGeneral.GetListProvince(params);
    return result.data;
  }
)

export const doGetListDistrict = createAsyncThunk(
  'search@GetListDistrict',
  async (params: IParamsGetListDistrict) => {
    const result = await apiGeneral.GetListDistrict(params);
    return result.data;
  }
)

export const doGetListWard = createAsyncThunk(
  'search@GetListWard',
  async (params: IParamsGetListWard) => {
    const result = await apiGeneral.GetListWard(params);
    return result.data;
  }
)

export const doCreateSearchAdmission = createAsyncThunk(
  'search@CreateSearchAdmission',
  async (params: any) => {
    const result = await apiAdmission.CreateSearchAdmission(params);
    return result.data;
  }
)

export const doCreateHistorySearchAdmission = createAsyncThunk(
  'search@CreateHistorySearchAdmission',
  async (params: IParamsCreateHistorySearchAdmission) => {
    const result = await apiAdmission.CreateHistorySearchAdmission(params);
    return result.data;
  }
)



export const doGetResultSearch = createAsyncThunk(
  'search@GetResultSearch',
  async (params: any) => {
    const result = await apiAdmission.GetResultSearch(params);
    return result.data;
  }
)



export const doCreateConsultion = createAsyncThunk(
  'search@CreateConsultion',
  async (params: IParamsCreateConsultion) => {
    const result = await apiAdmission.CreateConsultion(params);
    return result.data;
  }
)


export const doUpdateIsChooseHighSchool = createAsyncThunk(
  'search@UpdateIsChooseHighSchool',
  async (params: any) => {
    const result = await apiAdmission.UpdateIsChooseHighSchool(params);
    return result.data;
  }
)

//==================================================================


const initialState = {

  //=====================NEW=========================
  listTypeAdmission: [],
  isLoadingTypeAdmission: false,
  errorTypeAdmission: {},

  listPriorityObject: [] as IPriorityObject[],
  isLoadingPriorityObject: false,
  errorPriorityObject: {},
  listChoosePriorityObject: {} as any,
  priorityScore: 0,

  listSubject: [],
  isLoadingSubject: false,
  errorSubject: {},

  listSecondarySchool: [],
  isLoadingSecondarySchool: false,
  errorSecondarySchool: {},

  listProvinces: [],
  isLoadingListProvinces: false,
  errorListProvinces: {},

  listDistrict: [],
  isLoadingDistrict: false,
  errorDistrict: {},

  listWard: [],
  isLoadingWard: false,
  errorWard: {},

  searchadmissionid: -1,
  isLoadingSearchadmissionid: false,
  errorSearchAdmission: {},
  
  //=================================================

  listSchool: [],
  listSchoolNonPublic: [],
  mytotalscore: -1,
  isLoadingListSchool: false,
  numhistorysearch: 0,
  errorListSchool: {},
  isOverLoadServer: false,


  listHistorySearchUser: [],
  isLoadingListHistorySearchUser: false,

  dISCFamousPeopleModel: {} as any,
  isAdvice: false,
};

const slice = createSlice({
  name: 'search@',
  initialState: initialState,
  reducers: {
    // Cờ mở popup Xác nhận đăng ký thành công
    doCheckPriorityObject(state, action) {
      const index = state.listPriorityObject.findIndex((item) => item.id === action.payload.id)
      if (index >= 0) {
        state.listPriorityObject[index].checked = true;
        state.listChoosePriorityObject[action.payload.id] = state.listPriorityObject[index].point;
        if (state.priorityScore < 3) {
          let point = state.priorityScore + state.listPriorityObject[index].point;
          if (point > 3) point = 3;
          state.priorityScore = point;
        }
      }
    },
    doUnCheckPriorityObject(state, action) {
      const index = state.listPriorityObject.findIndex((item) => item.id === action.payload.id)
      if (index >= 0) {
        state.listPriorityObject[index].checked = false;
        delete state.listChoosePriorityObject[action.payload.id]
        let point = 0;
        state.listPriorityObject.forEach((item) => {
          if (state.listChoosePriorityObject[item.id] && item.id !== action.payload.id) {
            point += state.listChoosePriorityObject[item.id]
            if (point >= 3) {
              point = 3
              return
            }
          }
        })
        state.priorityScore = point;
      }
    },
    doSetPriorityObjects(state, action) {
      const listId = action.payload.priorityObjectIds;
      let chosenObjects = {} as any;
      let point = 0;

      listId.forEach((ele: number) => {
        const index = state.listPriorityObject.findIndex(item => item.id === ele);
        if (index >= 0) {
          state.listPriorityObject[index].checked = true
          chosenObjects[ele] = state.listPriorityObject[index].point
          if (point < 3) point += state.listPriorityObject[index].point
          if (point > 3) point = 3
        }
      });
      state.priorityScore = point;
      state.listChoosePriorityObject = chosenObjects;
    },
    doClearWhenChooseNopriority(state, action) {
      for(let i = 0;i<state.listPriorityObject.length;i++) {
        if(state.listPriorityObject[i].id != 6) {
          state.listPriorityObject[i].checked = false;
        }
      }
    }
  },

  extraReducers: builder => {
    
    //=======================NEW=====================//

    // type admission
    builder.addCase(doGetTypeAdmission.pending, state => {
      state.isLoadingTypeAdmission = true;
    });
    builder.addCase(doGetTypeAdmission.fulfilled, (state, action) => {
      state.listTypeAdmission = action.payload.content.typeAdmissions;
      state.isLoadingTypeAdmission = false;
    });
    builder.addCase(doGetTypeAdmission.rejected, (state, action) => {
      state.errorTypeAdmission = action.error;
      state.isLoadingTypeAdmission = false;
    });
    
    // priority object
    builder.addCase(doGetListPriorityObject.pending, state => {
      state.isLoadingPriorityObject = true;
    });
    builder.addCase(doGetListPriorityObject.fulfilled, (state, action) => {
      state.listPriorityObject = action.payload.content.priorityObjects;
      state.isLoadingPriorityObject = false;
    });
    builder.addCase(doGetListPriorityObject.rejected, (state, action) => {
      state.errorPriorityObject = action.error;
      state.isLoadingPriorityObject = false;
    });

    // subjects
    builder.addCase(doGetListSubject.pending, state => {
      state.isLoadingSubject = true;
    });
    builder.addCase(doGetListSubject.fulfilled, (state, action) => {
      state.listSubject = action.payload.content.subjects;
      state.isLoadingSubject = false;
    });
    builder.addCase(doGetListSubject.rejected, (state, action) => {
      state.errorSubject = action.error;
      state.isLoadingSubject = false;
    });

    // secondary school
    builder.addCase(doGetListSecondarySchool.pending, state => {
      state.isLoadingSecondarySchool = true;
    });
    builder.addCase(doGetListSecondarySchool.fulfilled, (state, action) => {
      state.listSecondarySchool = action.payload.content.secondarySchools;
      state.isLoadingSecondarySchool = false;
    });
    builder.addCase(doGetListSecondarySchool.rejected, (state, action) => {
      state.errorSecondarySchool = action.error;
      state.isLoadingSecondarySchool = false;
    });

    // province
    builder.addCase(doGetListProvince.pending, state => {
      state.isLoadingListProvinces = true;
    });
    builder.addCase(doGetListProvince.fulfilled, (state, action) => {
      state.listProvinces = action.payload.content.provinces;
      state.isLoadingListProvinces = false;
    });
    builder.addCase(doGetListProvince.rejected, (state, action) => {
      state.errorListProvinces = action.error;
      state.isLoadingListProvinces = false;
    });

    // district
    builder.addCase(doGetListDistrict.pending, state => {
      state.isLoadingDistrict = true;
    });
    builder.addCase(doGetListDistrict.fulfilled, (state, action) => {
      state.listDistrict = action.payload.content.districts;
      state.isLoadingDistrict = false;
    });
    builder.addCase(doGetListDistrict.rejected, (state, action) => {
      state.errorDistrict = action.error;
      state.isLoadingDistrict = false;
    });

    // ward
    builder.addCase(doGetListWard.pending, state => {
      state.isLoadingWard = true;
    });
    builder.addCase(doGetListWard.fulfilled, (state, action) => {
      state.listWard = action.payload.content.wards;
      state.isLoadingWard = false;
    });
    builder.addCase(doGetListWard.rejected, (state, action) => {
      state.errorDistrict = action.error;
      state.isLoadingWard = false;
    });

    // get search result
    builder.addCase(doGetResultSearch.pending, (state, action) => {
      state.isLoadingListSchool = true;
    });
    builder.addCase(doGetResultSearch.fulfilled, (state, action) => {
      state.listSchool = action.payload.content?.resultsearch.publichighschools || [];
      state.listSchoolNonPublic = action.payload.content?.resultsearch.nonpublichighschools || [];
      state.mytotalscore = action.payload.content?.resultsearch.mytotalscore || "";
      state.numhistorysearch = action.payload.content?.numhistorysearch || 0;
      state.isLoadingListSchool = false;
    });
    builder.addCase(doGetResultSearch.rejected, (state, action) => {
      state.errorListSchool = action.error;
      state.listSchool =  [];
      state.listSchoolNonPublic =  [];
      state.numhistorysearch =  0;
      state.isOverLoadServer = true;
      state.isLoadingListSchool = false;
    });

    //doCreateSearchAdmission searchadmissionid

    builder.addCase(doCreateSearchAdmission.pending, (state, action) => {
      state.isLoadingSearchadmissionid = true;
    });
    builder.addCase(doCreateSearchAdmission.fulfilled, (state, action) => {
      state.searchadmissionid = action.payload.content.searchAdmission.Id;
      state.isLoadingSearchadmissionid = false;
    });
    builder.addCase(doCreateSearchAdmission.rejected, (state, action) => {
      state.errorSearchAdmission = action.error;
      state.isLoadingSearchadmissionid = false;
    });
 
  },
});

const { reducer: searchReducer, actions } = slice;
export const { 
  doCheckPriorityObject, 
  doUnCheckPriorityObject,
  doSetPriorityObjects,
  doClearWhenChooseNopriority
} = actions;
export default searchReducer;
