import { useResponsive } from "../../hooks"
import {HeaderLayoutDesktop} from './HeaderLayoutDesktop'
import {HeaderLayoutMobile} from './HeaderLayoutMobile'




export const HeaderLayout = ({isHideMenu}:any) => {
    const { isFromMobile } = useResponsive();
    if(isFromMobile) return <HeaderLayoutDesktop isHideMenu = {isHideMenu}/>
    else return <HeaderLayoutMobile  isHideMenu = {isHideMenu}/>
}