import { createSlice } from '@reduxjs/toolkit';

type IStateScreen = {
  isScreen2?: boolean;
  isScreen3?: boolean;
};
const initialState = {
  isScreen2: false,
  iScreen3: false,
} as IStateScreen;

export const stateScreenFileSlice = createSlice({
  name: 'stateScreen',
  initialState: initialState,
  reducers: {
    doUpdateStateScreen(state, action) {
      state.isScreen2 = action.payload.isScreen2;
      state.isScreen3 = action.payload.isScreen3;
    },
  },
});
const { actions, reducer } = stateScreenFileSlice;
export const { doUpdateStateScreen } = actions;
export default reducer;
