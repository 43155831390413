import { createSlice } from '@reduxjs/toolkit';

type TTnitialState = {
  isOpen: boolean;
  isOpenInstructionModal: boolean;
  typeModal: number;
  urlIframe: string;
  data: any;
};

const initialState = {
  isOpen: false,
  isOpenInstructionModal: false,
  typeModal: -1,
  urlIframe: '',
  data: null,
} as TTnitialState;

export const modalSlice = createSlice({
  name: 'modal',
  initialState: initialState,
  reducers: {
    doOpenModal(state) {
      state.isOpen = true;
    },
    doCloseModal(state) {
      state.isOpen = false;
    },
    doOpenInstruction(state) {
      state.isOpenInstructionModal = true;
    },
    doCloseInstruction(state) {
      state.isOpenInstructionModal = false;
    },
    doSetTypeModal(state, action) {
      state.typeModal = action.payload.typeModal;
      state.urlIframe = action.payload.urlIframe;
    },
    doGetListGift(state, action) {
      if(action.payload.data) {
        state.typeModal = action.payload.typeModal;
        state.data = action.payload.data.content.gifts;
      }
      
    },
    doGetListScheduleTraining(state, action) {
      if(action.payload.data) {
        state.typeModal = action.payload.typeModal;
        state.data = action.payload.data.content.scheduleTrainings;
      }
      
    },
    doClearDataModal(state) {
      state.typeModal = -2;
      state.urlIframe = '';
    },
  },
});

const { actions, reducer } = modalSlice;
export const {
  doOpenModal,
  doCloseModal,
  doOpenInstruction,
  doCloseInstruction,
  doSetTypeModal,
  doGetListGift,
  doGetListScheduleTraining,
  doClearDataModal,
} = actions;
export default reducer;
