import { Modal } from '../Modal/Modal';
import IconNoti from '../../../assets/img/draft/IconNoti.png';
import IconClose from '../../../assets/img/draft/icon-close.png';

import './ModalRemind.scss';


export const ModalRemind: React.FC<any> = ({
  isOpen,
  setIsShow,
  onClose,
  setComfirm
}) => {

  return (
    <Modal isShow={isOpen} setIsShow = {setIsShow}>
        <div className='modal-remind'>
           <img src={IconClose} onClick = {onClose}/>
           <img src = {IconNoti}/>
           <div></div>
           <p>LƯU Ý!</p>
           <p>*Thông tin này chỉ mang tính tham khảo không mang tính quyết định</p>
           <button onClick={() => {
             onClose()
             setComfirm();
           }
           }>ĐỒNG Ý</button>
        </div>
    </Modal>
  );
};