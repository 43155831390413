import React, { lazy } from 'react';

import { useResponsive } from '../../hooks';

import { FooterLayout } from '../FooterLayout/FooterLayout';
import './SidebarLayout.scss';
import { HeaderLayout } from '../HeaderLayout/HeaderLayout';

export function SidebarLayout({ ...props }) {
  
  const { isFromMobile } = useResponsive();
  

  function LayoutMobile() {
    return (
      <React.Fragment>
        {/* {!props.isHideHeader && <HeaderLayout isHideMenu = {props.isHideMenu}/>} */}
         {props.children}
        <FooterLayout />
        {/* Iframe DISC */}
      </React.Fragment>
    );
  }

  function LayoutDesktop() {
    return (
      <div className="container">
        {/* {!props.isHideHeader && <HeaderLayout isHideMenu = {props.isHideMenu}/>} */}
         {props.children}
        <FooterLayout />
        {/* Iframe DISC */}
      </div>
    );
  }

  return isFromMobile ? <LayoutDesktop /> : <LayoutMobile />;
}
