import React, { useEffect } from 'react';

// import { ModalCommon } from '../components/common';

export const PublicRouter: React.FC<IPublicRouter> = ({
  component: Component,
  layout: Layout,
  exact,
  path,
  header: Header,
  footer: Footer,
  isHasFooter,
  isHasHeader,
  titleHeader,
  typeHeader,
  isHideHeader,
  isHideMenu
  //isHideMenu
}) => {
  
  return (
    
    <Layout
      header={
        isHasHeader ? (
          <Header
            title={titleHeader}
            // titleDynamic={query}
            type={typeHeader}
            // onClick={props.history.goBack}
          />
        ) : (
          <></>
        )
      }
      // footer={<FooterLayout />}
      // sidebar={Sidebar}
      isHideHeader = {isHideHeader}
      isHideMenu = {isHideMenu}
    >
      {/*{...props}*/}
      <Component />
    </Layout>
  );
};
