import React from 'react';
import { iconChat, underChatIcon } from '../../../constants';
import UnderChatIcon from './UnderChatIcon';
import './ChatIcon.scss';
export const ChatIcon = () => {
  return (
    <div className="chat-icon">
      <div className="chat">
        <img src={iconChat} alt="" />
      </div>
      <div className="under-chat">
        <UnderChatIcon />
      </div>
      {/* <div className="noti">
        <span>0</span>
      </div> */}
    </div>
  );
};
