import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiAdmission } from "../../../services/aixos/apiAdmission";
import { apiGoogleMap } from "../../../services/aixos/apiGoogleMap";
import { apiBanner } from "../../../services/aixos/apiBanner";

export const doGetBannerSchool = createAsyncThunk(
    'System/GetBannerSchool',
    async (param:any) => {
        const result = await apiBanner.getBanner(param);
        return result.data;
    }
)
interface Banners {
    banners: any[];
    isLoadingBanner: boolean;
    error:any
    
}

const initialState = { 
   banners: [],
   isLoadingBanner: false,
   error: {}
} as Banners;

const getBannerSlice = createSlice({
    name: 'banners@',
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(doGetBannerSchool.pending, (state) => {
            state.isLoadingBanner = true;
            state.error = {};
            state.banners = [];
        })
        builder.addCase(doGetBannerSchool.fulfilled, (state, action) => {
            state.isLoadingBanner = true;
            state.error = {};
            state.banners = action.payload?.content?.banners || [];
        })
        builder.addCase(doGetBannerSchool.rejected, (state, action) => {
            state.error = action.error;
            state.isLoadingBanner = false;
        })
    },
})

const {reducer: getBannerSliceReducer, actions } = getBannerSlice
export default getBannerSliceReducer;