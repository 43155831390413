import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const doSaveInFoAfterCheckOTP = createAsyncThunk('verify@post/checkOTP', (param: any) => {
  return param;
});

export const doSaveInfoEmailAfterCheckOTP = createAsyncThunk(
  'verifyEmail@post/checkOTP',
  (param: any) => {
    return param;
  },
);

const initialState = {
  email: '',
  idemail: 0,
  idphone: 0,
  idreferralcode: 0,
  otp: '',
  phone: '',
  referralcode: '',
  otpEmail: '',
  isGoogleAuthen: false
};

const slice = createSlice({
  name: 'verify@',
  initialState: initialState,
  reducers: {
    doVerifyByGoogleAuthen(state, action)  {
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.isGoogleAuthen = true;
    },
    doSetIsGoogleAuthenSuccess(state, action)  {
      state.isGoogleAuthen = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(doSaveInFoAfterCheckOTP.fulfilled, (state, action) => {
      return {
        ...state,
        phone: action.payload.phone,
        idphone: action.payload.idphone,
      };
    });
    builder.addCase(doSaveInfoEmailAfterCheckOTP.fulfilled, (state, action) => {
      return {
        ...state,
        email: action.payload.email,
        idemail: action.payload.idemail,
        otp: action.payload.otp,
      };
    });
  },
});

const { actions, reducer: verifyReducer } = slice;
export const { doVerifyByGoogleAuthen, doSetIsGoogleAuthenSuccess } = actions
export default verifyReducer;
