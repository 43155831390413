import BtnDuhoc from '../../assets/img/draft/btnDuhoc.png';
import LogoYoot from '../../assets/img/draft/logo-yoot.png';
import { SvgLogoYoot } from '../../assets/svg/SvgLogoYoot';

import './HeaderLayout.scss';
export const HeaderLayoutDesktop = ({isHideMenu}:any) => {
  const handleScrollIntoView = (id:any) => {
    document
    .getElementById(id)
    ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    document
    .getElementById(id)
    ?.focus();
  }

  return (
    <div className="header-layout">
      <div className="header-layout__content">
        <div className="header-layout__content-logo">
          <SvgLogoYoot/>
        </div>
        {!isHideMenu && <><div className='header-layout__content-menu'>
            <ul>
                <li className='header-layout__content-menu-item' onClick={()=> handleScrollIntoView('header-search')}>Tra cứu</li>
                <li className='header-layout__content-menu-item'onClick={()=> handleScrollIntoView('news-list')}>Tin tức</li>
                <li className='header-layout__content-menu-item'onClick={()=> handleScrollIntoView('contact')}>Liên hệ</li>
            </ul>
        </div>
        <a className="header-layout__content-btnyootway" href="https://yootway.vn/" target="_blank">
          <img src={BtnDuhoc} alt="" />
        </a>
        </>
}
      </div>
    </div>
  );
};